function CreateSelect(props) {
    const { list } = props
    const { defaultValue } = props
    const { changeDefault } = props

    // const valueSelect = list.find((element, key)=>{
    //     return element === defaultValue
    // })

    const mapedSelectLisy = list.map((element)=>{
        return {
            id: element,
            name: element
        }
    })

    return (
        <>
            {
                defaultValue &&
                
                <select defaultValue={defaultValue} className="form-control text-center" name="new_role"
                    onChange={(e) => {
                        // defaultValue.current = e.target.value
                        changeDefault(e.target.value)
                    }}
                >
                    {list.map((value) => {     
                        return (
                            <option
                                key={value}
                                value={value}
                            >{value + ' minute'}</option>
                        )
                    })}
                </select>
            }
        </>
    )
}

export { CreateSelect }