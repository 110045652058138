import { getDateTimeSlot, checkNowDateSlot } from '../../../hooks/date-time'
import { setUserPermission, getUserPermission } from '../../../hooks/permission'
function getColorBar(dateTimeSlot, slotIfo, nextDateTimeSlot) {
    const something = slotIfo[1].FreeSlot;
    const total = slotIfo[1].CountSlot;
    const percent = something * 100 / total
    const nowDateTime = getDateTimeSlot()
    // const nowDateTime = "2022-05-07, 22:24:29"
    if (dateTimeSlot === '2022-04-24, 14:00') {
        let a = ''
    }

    if (slotIfo[0] === '17:30') {
        let a = ''
    }

    const getFutyreTime = checkNowDateSlot(dateTimeSlot, nextDateTimeSlot)
    switch (true) {
        case (getFutyreTime.backFormat <= nowDateTime && getFutyreTime.futureFormat > nowDateTime):
            return 'bg-info';
        case nowDateTime > dateTimeSlot:
            return 'bg-secondary';
        case (something < 0):
            return 'bg-fatal';
        case (percent < 100 && percent !== 0):
            return 'bg-warning';
        case (slotIfo[1].FreeSlot === 0):
            return 'bg-danger';
        case nowDateTime < dateTimeSlot:
            return 'bg-success';
        default:
    }
}
function getColorAsign(eventDateTime, slotIfo, time) {
    const something = slotIfo.AssignSlot;
    const total = slotIfo.CountEvent;
    const percent = something * 100 / total

    const nowDateTime = getDateTimeSlot()
    const getFutyreTime = checkNowDateSlot(eventDateTime)

    switch (true) {
        case nowDateTime < getFutyreTime.backFormat:
            return 'btn bg-success disabled';
        case (something === total):
            return 'bg-success';
        case (percent < 100 && percent !== 0):
            return 'bg-warning';
        case (something === 0):
            return 'bg-danger';
        default:
    }
}
function getColorCountEvent(dateTimeSlot) {

}

function getColorMeAsign(asssignName, time) {
    if (time == '18:00') {
        let a = ''
    }
    const UserName = getUserPermission().name
    const test = getUserPermission()
    const resutlName = UserName == asssignName
    return true == resutlName ? 'bg-customcolor text-border' : 'bg-dark text-light'
}

export { getColorBar, getColorAsign, getColorCountEvent, getColorMeAsign } 