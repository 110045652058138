import { useState, useEffect, useRef, useMemo, useCallback, createContext } from 'react';
import socket from '../../socket';
import ACTIONS from '../../socket/actions';


export default function App() {
    const [UA, updateUA] = useState()
    const getUserAgent = () => {
        setTimeout(() => { 
            socket.emit(ACTIONS.MESSAGE, {
                type: ACTIONS.GET_USER_AGENT
            });
        }, 2000);
    }

    useEffect(() => {
        getUserAgent()
        socket.on("share-message", (data) => 
        {
            if (data.type === "get-client-device-info") {
                const uaUser = {
                    DeviceCPU: data.payload.DeviceCPU,
                    DeviceRAM: data.payload.DeviceRAM,
                    DeviceOS: data.payload.DeviceOS
                };
            
                console.log(uaUser);
                console.dir(uaUser);
                updateUA(uaUser);
            }
        });
        return () => {
        }
    }, [])
    

    return (
        UA !== undefined &&
        <div className="">
            <div className="">
                <div className="card text-white bg-secondary mb-3 row row-alt ">
                    <div className="card-header">Client Device Info & Settings</div>
                    
                    <div className="card-body p-0">
                        <div id="five_wrapper" className="dataTables_wrapper no-footer"></div>
                        <div id="passwordHelpBlock" className="form-text text-light fs-4" style={{paddingLeft: "12px", paddingBottom: "12px"}}>
                            {`CPU: ${UA.DeviceCPU}`}
                            <br />
                            {`RAM: ${UA.DeviceRAM}`}
                            <br />
                            {`OS: ${UA.DeviceOS}`}
                            <br /><br />
                            <p>Remote Stream Quality</p>
                            <select style={{width: "128px", margin: "0px", marginBottom: "20px", fontSize: "22px"}}>
                                <option>Full</option>
                                <option>Half</option>
                                <option>Quarter</option>
                            </select>
                            <br />
                            <input placeholder='clipboard text will appear here once copied' style={{fontSize: "18px", width: "512px"}}></input>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}