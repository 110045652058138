import { useState, useEffect, useRef, useMemo } from "react";

import { CreateDatePicker } from "../../componenets/datepicker";

import { getNewDateWeekForButton } from "../../../../hooks/date-time";

import { CreateLogs } from "./elements/logs-block";
import { updateTable } from "./functions/create-table";

const TimetableLogs = ()=>{
      // console.log("ClickHouse");
  const [RENDER, setRender] = useState([]);

  const [USER_ID, setUserId] = useState("5152265");
  const [LOGS, setLogs] = useState([]);

  const dateMapRef = useRef();

  const START = useRef();
  const END = useRef();

  const LOGS_BLOCK = useRef();

  const effectStatus = useRef();

  useMemo(() => {
    const dateWeek = getNewDateWeekForButton().lastSevenDay();
    // console.log(dateWeek)
    START.current = dateWeek.start;
    END.current = dateWeek.end;
  }, []);

  const updateLogTable = async () => {
    setRender([])
    const result = await getLogTable();
    // setLogs(result)
    updateTable(result);
  };

  const getLogTable = async () => {
    // const timestampStart = new Date(START.current).getTime() / 1000;
    // const timestampEnd = new Date(END.current).getTime() / 1000;

    const timestampStart = Date.parse(START.current) / 1000;
    const timestampEnd = Date.parse(END.current) / 1000;

    // const logs = await getClickHouseLogs(
    //   USER_ID,
    //   timestampStart,
    //   timestampEnd,
    //   dateMapRef.current
    // );
    // return logs;
  };

  useEffect(() => {
    effectStatus.current = true;
    if (USER_ID) {
      const fetchData = async () => {
        // const result = await updateTempSchedule();
        // if (effectStatus.current) {
        //   LOGS_BLOCK.current = <CreateLogs LOGS={result} />
        //   setLogs(result);
        // }
      };
      fetchData();
    }
  }, []);

  return (
    <>
      <div className="d-flex flex-row justify-content-center">
        <div>Логи расписания уроков</div>
      </div>
      <div className="d-flex flex-row justify-content-center my-4">
        <input
          className="w-200px form-control ms-3 d-flex flex-row justify-content-center"
          placeholder="ID пользователя"
          type="text"
          form="logs"
          name="begin"
          defaultValue={USER_ID}
          onChange={(e) => {
            //   TEXT.current = e.target.value;
            setUserId(e.target.value);
            // console.log(e.target.value);
          }}
        />
      </div>
      <CreateDatePicker
        date={{ START, END }}
        updateCallback={updateLogTable}
      />
      <CreateLogs key={JSON.stringify(LOGS)} LOGS={LOGS} />
    </>
  );
}

export default TimetableLogs