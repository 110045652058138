import { useState, useEffect } from 'react';
import { radioTimeChangeValue } from '../functions/get-change-time'


function CreateChangeTimeValue() {
    // console.log('CreateRadioStatus')
    const [CHANGE_TIME_VALUE, setRadioStatus] = useState([]);
    const RADIO_STATUS = radioTimeChangeValue()
    useEffect(() => {
        RADIO_STATUS.setRadioStatus('null', setRadioStatus)
        // RADIO_STATUS.getRadioStatus(setRadioStatus)
    }, [])
    return (
        <div className="col jumbotron d-flex align-items-center">
        <div className="container-fluid">
          <div className="form-check form-switch">
            <input className="form-check-input " type="radio" name="radioChangeTime" id="null"
              checked={'null' === CHANGE_TIME_VALUE ? true : false}
              onChange={() => {
                RADIO_STATUS.setRadioStatus('null', setRadioStatus)
              }}
            /><label
              className="btn padding-btn-0 text-light form-check-label bg-secondary rounded-pill w-100px text-center"
              htmlFor="null">Не менять
            </label>
          </div>
          <div className="form-check form-switch">
            <input className="form-check-input " type="radio" name="radioChangeTime" id="add"
              checked={'add' === CHANGE_TIME_VALUE ? true : false}
              onChange={() => {
                RADIO_STATUS.setRadioStatus('add', setRadioStatus)
              }}
            /><label
              className="btn padding-btn-0 text-light form-check-label bg-primary rounded-pill w-100px text-center"
              htmlFor="add">Добавить
            </label>
          </div>
          <div className="form-check form-switch">
            <input className="form-check-input " type="radio" name="radioChangeTime" id="change"
              checked={'change' === CHANGE_TIME_VALUE ? true : false}
              onChange={() => {
                RADIO_STATUS.setRadioStatus('change', setRadioStatus)
              }}
            /><label
              className="btn padding-btn-0 text-light form-check-label bg-warning rounded-pill w-100px text-center"
              htmlFor="change">Изменить
            </label>
          </div>
          <div className="form-check form-switch">
            <input className="form-check-input " type="radio" name="radioChangeTime" id="delete"
              checked={'delete' === CHANGE_TIME_VALUE ? true : false}
              onChange={() => {
                RADIO_STATUS.setRadioStatus('delete', setRadioStatus)
              }}
            /><label
              className="btn padding-btn-0 text-light form-check-label bg-danger rounded-pill w-100px text-center"
              htmlFor="delete">Удалить
            </label>
          </div>
        </div>
      </div>
    )
}

export { CreateChangeTimeValue }