import { useState, useEffect, useRef, useCallback } from 'react';
import { CONFIG } from '../config';
import { radioStatus } from '../functions/get-radio-status'

function CreateSchedule(props) {
    // console.log("CreateSchedule")
    const [SCHEDULE, setSchedule] = useState([])

    const { schedule } = props.propSchedule
    const callback = props.propCallback

    const [RADIO_VALUE, setRadioStatus] = useState([]);
    const RADIO_STATUS = radioStatus();

    let prevEl = undefined;

    const handleCheckboxState = (userId, time) => {
        const scheduleUpdate = {};
        scheduleUpdate['schedule'] = props.propSchedule.schedule;
        scheduleUpdate['reservation'] = props.propSchedule.reservation;
        console.log(props.propSchedule);
        const RADIO_STATUS = radioStatus().getRadioStatus();
        // Use the provided userId and time
        scheduleUpdate.schedule[userId]['timeStatus'][time] =
          scheduleUpdate.schedule[userId]['timeStatus'][time] === RADIO_STATUS ? '0' : RADIO_STATUS;
        callback(scheduleUpdate);
      };

    const handleMouseMove = (event) => {
        if (!event.altKey) return;

        const mouseX = event.clientX;
        const mouseY = event.clientY;

        const elementUnderCursor = document.elementFromPoint(mouseX, mouseY);

        if (elementUnderCursor && elementUnderCursor.tagName === 'INPUT' && elementUnderCursor.type === 'checkbox' && prevEl != elementUnderCursor) {
            console.log('Mouse over a checkbox! fuck u');
            // Call your related function here
            RADIO_STATUS.setRadioStatus(RADIO_STATUS.getRadioStatus(), setRadioStatus);
            console.log(RADIO_STATUS.getRadioStatus());

            const userId_state = elementUnderCursor.getAttribute('id');
            const slotTime_state = elementUnderCursor.getAttribute('data-timeslot');
            elementUnderCursor.click();

            prevEl = elementUnderCursor;
        }
    };

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);
    // if(SCHEDULE){
    //     setSchedule(schedule)
    // }

    Object.entries(schedule).forEach(([userId, dateBody], i) => {
        // console.log(timeStatus)

        // console.log(dateBody)
    })
    return (
        // (SCHEDULE.length === 0) ? <></> :
        Object.entries(schedule).map(([userId, dateBody], i) => {


            return (
                // !dateBody.timeStatus
                //     ? <tr>
                //         {
                //             schedule[userId]['user-name']
                //         }
                //        <td colspan="max">ячейка заголовка</td>
                //     </tr>
                //     : 
                <tr
                    key={userId}
                >
                    <td>{
                        schedule[userId]['user-name']
                    }</td>
                    {
                        !dateBody.timeStatus
                            ? null
                            : Object.entries(dateBody.timeStatus).map(([time, stat]) => {
                                let keyCheck = userId + time
                                let status
                                // if (userId === '25') {
                                if (time === '00:30') {
                                    console.log(userId)
                                }
                                // }
                                if (schedule[userId]['timeStatus']) {
                                    status = schedule[userId]['timeStatus'][time]
                                    return (
                                        time < '08:00' ? null :
                                            time > '22:30' ? null :
                                                <td
                                                    className='collum-hov'
                                                    key={keyCheck}
                                                    onClick={() => handleCheckboxState(userId, time)}
                                                ><input
                                                    className={CONFIG.defaultClass + CONFIG.class[status]}
                                                    type="checkbox"
                                                    status={status}
                                                    data-timeslot={time}
                                                    user={userId}
                                                    readOnly
                                                    checked={CONFIG.cheked[status]}
                                                    id={keyCheck}
                                                ></input>
                                                </td>
                                    );
                                }
                                else {
                                    return null
                                }
                            })
                    }
                </tr>
            )
        })

    )
}

export { CreateSchedule }