import { useState, useEffect, useRef, useMemo, useContext, createContext } from 'react';
import { CheckAuth } from './auth-check'
import { useNavigate } from 'react-router';

export default function useProvideAuth() {
  // console.log('render useProvideAuth')
  const [user, setUser] = useState();
  const history = useNavigate();

  const signin = cb => {
    setUser(true);
    cb();
  };

  const signout = cb => {
    setUser(false);
    cb();
  };
  useEffect(() => {
    // console.log('render useMemo')
    const fetchAuthChech = async () => {
      // console.log('render fetchUserInfo')

      CheckAuth((result) => {
        if (result === 200) {
          setUser(true)
        } else {
          setUser(false)
        }
      })
    }
    fetchAuthChech()
  }, [])

  return {
    user,
    signin,
    signout
  };
}