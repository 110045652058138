function DoubleClick(text) {
    let urlOpen
    let urlEvent = text
    console.log(urlEvent)
    const taskId = urlEvent.split('/task/').splice(1, 1).join('').match(/[0-9]+/);
    console.log(taskId)
    const userID = urlEvent.split('/persons/').splice(1, 1).join('').match(/[0-9]+/);
    console.log(urlEvent)
    
    if (taskId !== null && userID !== null ) {
        urlOpen = `https://crm2.skyeng.ru/persons/${userID}/customer-support/task/${taskId}`
        console.log(urlOpen)
    }else if(taskId !== null ) {
        urlOpen = `https://crm2.skyeng.ru/customer-support/task/${taskId}`
        console.log(urlOpen)
    } 
    else {
        urlOpen = `https://crm2.skyeng.ru/persons/${userID}/customer-support/list`
        console.log(urlOpen)
    }
    window.open(urlOpen, '_blank');
}

export { DoubleClick }