import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import CreateSlot from './create-slot'
import CreateEvent from './create-event'
import { getColorBar, getColorAsign, getColorCountEvent } from './get-color'
import { getDateTimeSlot, getDateSlot, getTimeForButton, getDateForamteSlot, checkNowDateSlot } from '../../../hooks/date-time'
import useLoad from '../../../hooks/useLoad'
import { getControlLesson, getAssignAllControlLesson } from '../../../hooks/events'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js';

export default function CreateBlock(props) {
    // console.log(props)
    const [CONTROLLER, setRerender] = useState([])
    const time = props.body[0]
    if (time === '08:00') {
        // console.log('Render Block --------------')
        // console.log(loading)
        // console.log(time)
    }
    const { showBlockOption } = props.showBlockOption
    const eventState = props.body[1]
    const key = props.propskey
    const formateDateTimeSlot = props.date + ', ' + time
    const colorBar = getColorBar(formateDateTimeSlot, props.body, props.nextDateTimeSlot)
    const colorAssign = getColorAsign(formateDateTimeSlot, eventState, time)
    const eventArray = Object.entries(eventState.EventList)
    const SLOT_COUNT_REF = useRef([])
    const nowDate = getDateTimeSlot()
    const getFutyreTime = checkNowDateSlot(formateDateTimeSlot, props.nextDateTimeSlot)
    const checkNowSlot = getFutyreTime.backFormat <= nowDate && getFutyreTime.futureFormat > nowDate

    const isControlStartLesson = getControlLesson(eventArray);
    const isAssignAllControlLesson = getAssignAllControlLesson(eventArray);

    const autoAssignList = eventArray.map((event)=>{
        return event[1].auto_assign
    })

    const assignedEvent = eventArray.filter((event)=>{
        return event[1].assigned_by_id !== "0"
    })

    // console.log(assignedEvent)

    const assignedUserList = assignedEvent.map((event)=>{
        return event[1].assigned_by_id
    }) 

    // console.log(assignedUserList)

    useMemo(() => {
        SLOT_COUNT_REF.current.forEach((element, key) => {
            SLOT_COUNT_REF.current[key].text = ''
        });
    }, [props.date, nowDate])

    useMemo(() => {
        const { length } = SLOT_COUNT_REF.current
        if (length < eventState.CountSlot) {
            if (time === '10:30') {
                // console.log(SLOT_COUNT_REF)
            }

            const element = (key) => {
                return { text: "", key: key, display: true }
            }
            const countAdd = eventState.CountSlot - length
            let count = 0
            while (count < countAdd) {
                SLOT_COUNT_REF.current.push(element(length + count))
                count++
            }
            count = 0
            SLOT_COUNT_REF.current.forEach((element, key) => {
                SLOT_COUNT_REF.current[key].display = true
            });
            while (count < eventState.CountEvent) {
                // SLOT_COUNT_REF.current.push(`${index}`)
                // const text = SLOT_COUNT_REF.current[key]?.text
                if (SLOT_COUNT_REF.current[count]) {
                    SLOT_COUNT_REF.current[count].display = false
                }
                count++
            }
        } else if (length > eventState.CountSlot) {
            // console.log('Слотов стало меньше')
            const countSlice = length - eventState.CountSlot
            const countKey = length - countSlice
            SLOT_COUNT_REF.current.splice(countKey, countSlice)
            let count = 0
            while (count < eventState.CountSlot) {
                // if (SLOT_COUNT_REF.current[key]) {
                SLOT_COUNT_REF.current[count].display = false
                // }
                count++
            }
            count = eventState.FreeSlot
            let keySlot = countKey
            while (count > 0) {
                keySlot--
                SLOT_COUNT_REF.current[keySlot].display = true
                count--
            }
        } else {
            const countSlice = length - eventState.CountSlot
            const countKey = length - countSlice
            let count = 0
            while (count < eventState.CountEvent) {
                if (SLOT_COUNT_REF.current[count]) {
                    SLOT_COUNT_REF.current[count].display = false
                }
                count++
            }
            count = eventState.FreeSlot
            let keySlot = countKey
            while (count > 0) {
                keySlot--
                SLOT_COUNT_REF.current[keySlot].display = true
                count--
            }
        }

    }, [eventState, nowDate])
    const changeTextSlot = useCallback((key, textChange) => {
        console.log(SLOT_COUNT_REF.current)
        SLOT_COUNT_REF.current[key].text = textChange
        console.log(SLOT_COUNT_REF.current[1])
        console.log(SLOT_COUNT_REF.current[2])
        console.log(SLOT_COUNT_REF.current[3])
        console.log(SLOT_COUNT_REF.current[4])
        setRerender({ contol: SLOT_COUNT_REF.current })
    }, [])

    const readSlotValue = (key, status) => {
        console.log(SLOT_COUNT_REF.current)
        SLOT_COUNT_REF.current[key].text = ''

        SLOT_COUNT_REF.current.filter(({ text }, index) => {
            if (text !== '') {
                console.log('test1')
                console.log(index)
                SLOT_COUNT_REF.current[index].display = true
                return text
            }
        })

        SLOT_COUNT_REF.current.find(({ display }, index) => {
            if (display) {
                console.log('test1')
                console.log(index)
                SLOT_COUNT_REF.current[index].text = ''
                SLOT_COUNT_REF.current[index].display = false
                return display
            }
        });
    }

    const addSlot = () => {
        SLOT_COUNT_REF.current.find(({ display }, index) => {
            return !display ? SLOT_COUNT_REF.current[index].display = true : null
        });
        console.log(SLOT_COUNT_REF.current)

    }

    const test = Object.entries(SLOT_COUNT_REF.current)

    if (time === '10:30') {
        // console.log(time)
        // console.log(SLOT_COUNT_REF.current)
    }

    useEffect(() => {
        if (checkNowSlot && showBlockOption.current["show-today"]) {
            var myCollapse = document.querySelector(`#panelsStayOpen-${key}`)
            console.log(myCollapse)
            const checkShow = myCollapse.classList.contains('show')
            if (!checkShow) {
                var bsCollapse = new bootstrap.Collapse(myCollapse, {
                    toggle: true
                })
                showBlockOption.current["show-today"] = false
            }
        }

    }, [nowDate]);

    if (time === '21:00') {
        // console.log(time)
        // console.log(SLOT_COUNT_REF.current)
    }
    const timeCheckBlock = getTimeForButton(formateDateTimeSlot, 5)
    const resultTime = timeCheckBlock.backDateTimeSlot > timeCheckBlock.nowDateTime


    const createEvent = (body, key) => {
        if (body[0] == '96102') {
            console.log(body)
        }
        const result = <CreateEvent 
            tooltip={props.tooltip} 
            date={props.date} 
            dateTime={formateDateTimeSlot} 
            time={time} 
            valueKey={key} 
            eventID={body[0]} 
            value={body[1]} 
            callback={props.callback} 
            addSlot={addSlot} 
            key={body[0]} 
            autoAssignList={autoAssignList}
            assignedUserList={assignedUserList}
            dutyList={eventState.DutyList}
            isControlStartLesson={isControlStartLesson}
            isAssignAllControlLesson={isAssignAllControlLesson}
        />
        // const result = <div key={body[0]}></div>
        return result
    }

    const createSlot = (key, text, display) => {
        if (time === '18:30') {
            // console.log(time)
            // console.log(timeCheckBlock)
            // console.log(SLOT_COUNT_REF)
        }
        if (display && resultTime) {
            const result = <CreateSlot time={time} date={props.date} dateTime={formateDateTimeSlot} valueKey={key} text={text} c
                callback={props.callback} removeSlotValue={readSlotValue} updateText={changeTextSlot} key={key} />
            return result
        }
        return null
    }

    const slotBlock = SLOT_COUNT_REF.current.map(({ key, text, display }) => {
        return createSlot(key, text, display)
    })

    if (time === '10:30') {
        // console.log(time)
        // console.log(SLOT_COUNT_REF.current)
    }
    // console.log(eventArray)
    return (

        <div className="container  mw-100" key={time}>
            <div className="row gap-2">
                <div className="row">
                    <div className="col-1">
                        <button type="button" className="position-relative top-50 start-50 translate-middle btn btn-secondary copyToClipboar"
                            onClick={() => {
                                const dateFormate = getDateForamteSlot(props.date)
                                navigator.clipboard.writeText(`${dateFormate} ${time}`)
                            }}
                        >
                            <i className="fa fa-copy" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div className="col-11">
                        <div className="accordion open " id="accordionPanelsStayOpenExample">
                            <div className="accordion-item bg-dark">

                                <h2 className="accordion-header position-relative" id="panelsStayOpen-headingOne">
                                    <button className={`accordion-button collapsed  ${colorBar}`} time-slot="08:00" style={{
                                        height: '33px',
                                    }} type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#panelsStayOpen-${key}`}
                                        aria-expanded="false"
                                        aria-controls="panelsStayOpen-collapseOne"
                                        >
                                        <div className="col-4">
                                            <span className="text-center text-light px-1" value="08:00">{
                                                time
                                            }</span>
                                            <span className="text-center text-light date-slo" value="02-02-2022">{
                                                props.date
                                            }</span>
                                        </div>
                                        <div className="col-3">
                                            <div className={`${colorAssign} asign-slot-box fs-6 badge rounded-pill col-auto row g-0 badge border border-3 border-border-green`}>
                                                <span className="slot-assign px-1">{eventState.AssignSlot}</span>
                                                <span>/</span>
                                                <span className="slot-count px-1">{eventState.CountEvent}</span>
                                                <strong>взято</strong>
                                            </div>
                                        </div>

                                        <div className="text-center col-4">
                                            <div className="fs-6 badge border border-1 text-center text-light border-border">
                                                <span className="all-exposed px-1">{eventState.CountEvent}</span>
                                                <span>/</span>
                                                <span className="all-slot px-1">{eventState.CountSlot}</span>
                                                <span className="fw-weight-normal">выставлено</span>
                                            </div>
                                        </div>
                                    </button>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12" >
                    <div id={`panelsStayOpen-${key}`} className={`accordion-collapse collapse`} aria-labelledby="panelsStayOpen-headingOne">
                        <div className="accordion-body">
                            <div className="d-grid gap-2 col-12 mx-auto" type="time-slot" id='<?php echo $time ?>'>
                                {
                                    eventArray.map((eventValue, key) => {
                                        return createEvent(eventValue, key)
                                    })
                                }
                                {slotBlock}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}