import { createContext, useState } from "react";
import useProvideLoad from "../hooks/useProvideLoad";

const LoadContext = createContext();

export const LoadProvide = ({ children }) => {
    const load = useProvideLoad();

    return (
        <LoadContext.Provider value={ load }>
            {children}
        </LoadContext.Provider>
    )
}

export default LoadContext;