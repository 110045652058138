const getStatsPeerConnection = async (peer) => {
    let sender = await peer.pc.getSenders();
    let receivers = await peer.pc.getReceivers();
    let stats = await peer.pc.getStats();
    
    stats.forEach((valueIceStats) => {
      // console.log(valueIceStats)
      // console.log(valueIceStats.type)
      let bytes;
      let headerBytes;
      let packets;
      if(valueIceStats.type === "transport"){
        console.log("DTLS state: " + valueIceStats.dtlsState)
        console.log("ICE state: " + valueIceStats.iceState)
      }

      if (valueIceStats.type === "inbound-rtp") {
        if (valueIceStats.isRemote) {
          return;
        }
        const now = valueIceStats.timestamp;
        bytes = valueIceStats.bytesSent;
        headerBytes = valueIceStats.headerBytesSent;

        packets = valueIceStats.packetsSent;

        console.log("inbound packetsLost: " + valueIceStats.packetsLost)
        // console.log("bytes: " + bytes);
        // console.log("headerBytes: " + headerBytes);
        // console.log("packets: " + packets);
        // console.log("bandwidth:  " + report.qualityLimitationDurations.bandwidth);
        // console.log("CPU:  " + report.qualityLimitationDurations.cpu);
        // console.log("none:  " + report.qualityLimitationDurations.none);
        // console.log("other:  " + report.qualityLimitationDurations.other);
        // console.log("qualityLimitationReason:  " + report.qualityLimitationReason);
        // console.log("\n");
      }
      if (valueIceStats.type === "outbound-rtp") {
        if (valueIceStats.isRemote) {
          return;
        }
        const now = valueIceStats.timestamp;
        bytes = valueIceStats.bytesSent;
        headerBytes = valueIceStats.headerBytesSent;

        packets = valueIceStats.packetsSent;

        // console.log(valueIceStats)
        console.log("outbound packetsSent: " + valueIceStats.packetsSent)
        // console.log("bytes: " + bytes);
        // console.log("headerBytes: " + headerBytes);
        // console.log("packets: " + packets);
        // console.log("bandwidth:  " + report.qualityLimitationDurations.bandwidth);
        // console.log("CPU:  " + report.qualityLimitationDurations.cpu);
        // console.log("none:  " + report.qualityLimitationDurations.none);
        // console.log("other:  " + report.qualityLimitationDurations.other);
        // console.log("qualityLimitationReason:  " + report.qualityLimitationReason);
        // console.log("\n");
      }
    });

    sender.forEach((valueIceStats) => {
      if (valueIceStats.track) {
        valueIceStats.getStats().then((res) => {
          // console.log(valueIceStats);
          res.forEach((report) => {
            if (report.type === "candidate-pair"  && report.currentRoundTripTime) {

              console.log("sender currentRoundTripTime: " + report.currentRoundTripTime * 1000)
              console.log("sender state: " + report.state)
              console.log("============================================")
              console.log("\n")
            }
          });
        });
      }
    });

    receivers.forEach((valueIceStats) => {
      if (valueIceStats.track) {
        valueIceStats.getStats(null).then((res) => {
          res.forEach((report) => {
            if (report.type === "candidate-pair"  && report.currentRoundTripTime) {
              console.log("receivers currentRoundTripTime: " + report.currentRoundTripTime * 1000)
              console.log("receivers state: " + report.state)
              console.log("============================================")
              console.log("\n")
            }
          });
        });
      }
    });
};

export { getStatsPeerConnection }