import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
// import './style.scss'
import App from './App';

// export default ReactDOM.render(
//   <React.StrictMode>
//     <App />  
//   </React.StrictMode>,
//   document.getElementById('root')
// );

export default function App1() {
  return (
    <App />
  )
}