import { getNewDateWeekForButton } from '../../../hooks/date-time'

function createDatepickerButton(callBackDateTime) {
    const weekDate = getNewDateWeekForButton()
    return {
        previousWeek: async ({ START, END }) => {
            const currentDate = weekDate.getLessDate(START.current, END.current)
            const { start, end } = currentDate
            START.current = start
            END.current = end
            await callBackDateTime()

        },
        todayWeek: async ({ START, END }) => {
            const currentDate = weekDate.weekButton()
            const { start, end } = currentDate
            START.current = start
            END.current = end
            await callBackDateTime()
        },
        nextWeek: async ({ START, END }) => {
            const currentDate = weekDate.getNextDate(START.current, END.current)
            const { start, end } = currentDate
            START.current = start
            END.current = end
            await callBackDateTime()
        },
        pickWeek: async ({ START, END }) => {
            const wkStart = START.current
            const wkEnd = END.current
            START.current = wkStart
            END.current = wkEnd
            await callBackDateTime()
        }
    }
}
export { createDatepickerButton }