const ACTIONS = {
  JOIN: 'join',
  JOIN_MOBILE: 'join-mobile',
  JOIN_ROOM_USER: 'join-room-user',
  LEAVE: 'leave',
  SHARE_ROOMS: 'share-rooms',
  SHARE_ROOMS_MOBILE: 'share-room-mobile',
  ADD_PEER: 'add-peer',
  REMOVE_PEER: 'remove-peer',
  RELAY_SDP: 'relay-sdp',
  RELAY_ICE: 'relay-ice',
  ICE_CANDIDATE: 'ice-candidate',
  SESSION_DESCRIPTION: 'session-description',
  MESSAGE: "message",
  SHARE_MESSAGE: "share-message",
  GET_ROOM: 'get-room',
  AUTH: 'auth',
  PING_STATS: 'ping-stats',
  SPEED_TEST_VALUE: 'speed-test-value',
  SEND_USER_AGENT: 'send-user-agent',
  GET_USER_AGENT: 'get-user-agent',
  SEND_IMAGE: "send-image",
  MOUSE_EVENT: "mouse-event",
  KEYBOARD_EVENT: "keyboard-event",
  GET_CLIENT_DEVICE_INFO: "get-client-device-info"
};

module.exports = ACTIONS;