import { useState, useEffect, useRef, useCallback } from 'react';
import { AddUserForTable } from './elements/users-table';
import { getUsers } from './function/get-users'
import { Button, Form, FormCheck, Row, Col, InputGroup, Jumbotron, FormGroup, FormControl } from 'react-bootstrap';

export default function App() {
    const [USERS, setUsers] = useState([])
    const effectStatus = useRef()
    const errorCode = useRef()

    const updateUsers = async () => {
        effectStatus.current = true
        const resultReg = await getUsers()
        console.log(resultReg)
        if (effectStatus.current) {
            if (resultReg.error) {
                errorCode.current = resultReg.error
            }
            setUsers(resultReg)
        }
    }

    useEffect(() => {
        updateUsers()
    }, [])
    return (
        errorCode.current === 403
            ?
            // <div dangerouslySetInnerHTML={{__html: USERS.document}}></div>


            <div className="ratio ratio-1x1">
                <iframe className="embed-responsive-item" allowFullScreen title="Это уникальный заголовок" srcDoc={USERS.document}></iframe>
            </div>



            // <div>{USERS.document}</div>

            :
            <>
                <div className="container  mw-100">
                    <div className="row">
                        <div className="">
                            <button id="new_person" type="button submit" className="btn btn-success float-end"
                                onClick={() => {
                                    window.open(`${window.location.origin}/users/add`)
                                }}
                            >Добавить нового юзера</button>
                        </div>
                    </div>
                </div>
                {USERS.length === undefined ? null :
                    <div className="mt-5">
                        {USERS.map((body, key) => {
                            return (
                                // <h2 data-table={body.name} key={key}>{body.name}</h2>
                                <div className="container  mw-100" key={key}>
                                    <div className="row">
                                        <div className="accordion" id="accordionPanelsStayOpenExample">
                                            <div className="container  mw-100">
                                                <div className="row gap-2">
                                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                                        <div className="accordion-item bg-dark">
                                                            <h2 className="accordion-header position-relative" id="panelsStayOpen-headingOne">
                                                                <button className={`bg-success accordion-button collapsed justify-content-center text-center`} type="button" data-bs-toggle="collapse"
                                                                    data-bs-target={`#panelsStayOpen-${key}`} aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                                                                    <h2 className='container  mw-100 text-center text-light' data-table={body.name}>{body.name}</h2>
                                                                </button>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div id={`panelsStayOpen-${key}`} className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                                                            <div className="accordion-body">
                                                                <div className="d-grid gap-2 col-12 mx-auto" type="time-slot" id='<?php echo $time ?>'>
                                                                    <table className="table display hide text-center" id={key}>
                                                                        <thead className="thead-dark tabhead ">
                                                                            <tr>
                                                                                <td>ID</td>
                                                                                <td>Имя</td>
                                                                                <td>Логин</td>
                                                                                <td>Роль</td>
                                                                                <td>Группа</td>
                                                                                <td title="Брать эвент в работу">Assign event</td>
                                                                                <td title="Изменять текст эвентов">Change event</td>
                                                                                <td title="Создание эвента на :00 и :30 слоты">Create normal event</td>
                                                                                <td>Last seen</td>
                                                                                <td>Действия</td>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody id={"tbody" + key}>
                                                                            <AddUserForTable operator={body.operator} name={body.name} callback={updateUsers} />
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                }
                <div className="container  mw-100 mt-5">
                    <div className="row">
                        <div className="">
                            <button id="new_person" type="button submit" className="btn btn-success float-end"
                                onClick={() => {
                                    window.open(`${window.location.origin}/users/add`)
                                }}
                            >Добавить нового юзера</button>
                        </div>
                    </div>
                </div>
            </>
    );
};

// ReactDOM.render(<App />, document.getElementById('root'));