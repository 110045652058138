import { setDateTimeSlot, setDateSlot, getDateTimeSlot, getDateSlot, creatNowDate, getDateWeekForButton } from '../../../hooks/date-time'
function createDatepickerButton(callBackDateTime) {
    const weekDate = getDateWeekForButton()
    return {
        previousWeek: async ({ START, END }) => {
            const currentDate = weekDate.getLessDate(START.current, END.current)
            const { wkStart, wkEnd } = currentDate
            START.current = wkStart
            END.current = wkEnd
            await callBackDateTime()

        },
        todayWeek: async ({ START, END }) => {
            const currentDate = weekDate.weekButton()
            const { wkStart, wkEnd } = currentDate
            START.current = wkStart
            END.current = wkEnd
            await callBackDateTime()
        },
        nextWeek: async ({ START, END }) => {
            const currentDate = weekDate.getNextDate(START.current, END.current)
            const { wkStart, wkEnd } = currentDate
            START.current = wkStart
            END.current = wkEnd
            await callBackDateTime()
        },
        pickWeek: async ({ START, END }) => {
            // const currentDate = weekDate.weekButton()
            const wkStart = START.current
            const wkEnd = END.current
            START.current = wkStart
            END.current = wkEnd
            await callBackDateTime()
        }
    }
}
export { createDatepickerButton }