import { api } from '../../../service/api-url';
const deleteUser = async (userID) => {
    try {
        console.log('getUserInfo')
        const array = {}
        array['idToKill'] = userID
        const url = `${api()}/api/users/delete-user/index.php`
        let setResult = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(array),
            credentials: "include"
        })
        const result = await setResult.json();
        return result
    }
    catch (e) {
        console.error(e.message)
    }

}

export { deleteUser }