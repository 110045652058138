import { useState, useEffect, useRef, useMemo } from 'react';
import { CONFIG } from '../config';
import { getScheduleReg, getScheduleTemp } from '../functions/get-schedule'
import { updateScheduleReg, updateScheduleTemp, deleteTempSchedule } from '../functions/set-schedule'
import { useParams } from 'react-router';
import { getDateWeekForButton, setDateSlot, setDateForamteSlot } from '../../../hooks/date-time'
import { CreateScheduleBody } from './body-schedule-block'
import { SliderRange } from './slider-range-schedule'
import { getDateMap } from '../functions/create-date-map'
import { createTimeList } from '../../../hooks/time-type-parse/create-time-list'
import { createSlider, deleteSlider } from '../functions/create-slider'

import { CreateRadioStatus } from './radio-status-block'
import { CreateChangeTimeValue } from './radio-change-time-block'

import { CreateDatePicker } from './datepicker-block'

function CreateSchedule({ schedule }) {
    const [SCHEDULE_REG, setScheduleReg] = useState([])
    const [SCHEDULE_TEMP, setScheduleTemp] = useState([])
    const dateMapRef = useRef()
    const erorShedule = useRef()
    const { id: userId } = useParams();
    const START = useRef()
    const END = useRef()
    const timeList = useRef()

    const effectStatus = useRef()

    const updateRegSchedule = async () => {
        effectStatus.current = true
        const resultReg = await getScheduleReg(userId)
        if (effectStatus.current) {
            setScheduleReg(resultReg)
            if (resultReg.eror) {
                if (resultReg.eror["key-status"] === 401) {
                    erorShedule.current = resultReg.eror["key-status"]
                }
            }

        }
    }
    const updateTempSchedule = async () => {
        // START.current = "2022-04-25"
        // END.current = "2022-05-01"
        effectStatus.current = true
        dateMapRef.current = getDateMap(START.current, END.current)
        const resultTemp = await getScheduleTemp(userId, START.current, END.current, dateMapRef.current)
        if (effectStatus.current) {
            setScheduleTemp(resultTemp)
            if (resultTemp.eror) {
                if (resultTemp.eror["key-status"] === 401) {
                    erorShedule.current = resultTemp.eror["key-status"]
                }
            }
        }
    }

    useMemo(() => {
        timeList.current = Object.entries(createTimeList())
        const dateWeek = getDateWeekForButton().weekButton()
        START.current = dateWeek.wkStart
        END.current = dateWeek.wkEnd
    }, [])

    useEffect(() => {
        updateRegSchedule()
        updateTempSchedule()
        return () => {
            setDateSlot('')
            setDateForamteSlot('')
            effectStatus.current = false;
        }
    }, [])

    let test = createTimeList()
    // console.log(START)
    // console.log(END)
    return (
        erorShedule.current === 401
            ? <div>
                {SCHEDULE_TEMP.eror.error}
            </div>
            :

            schedule === '0'
                ? <>У пользователя график равен 0</>
                : <div>
                    <div className="row w-62vw mt-4">
                        <CreateRadioStatus />
                        <CreateChangeTimeValue />
                    </div>
                    {(SCHEDULE_REG.length === 0) ? <div>Загрузка...</div> :
                        <div className="mt-3" name="regular_schedule" id="regular_schedule">
                            <table className="container-fluid fs-table-10 min-vw-98 vw-95 table-bordered text-checkbox text-center">
                                <thead>
                                    <tr>
                                        <td className="w-10" >Имя</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <SliderRange
                                        propSchedule={SCHEDULE_REG}
                                        propCallback={setScheduleReg}
                                        weekNameMap={CONFIG.weekName[schedule]}
                                        nameTable="regular_schedule"
                                    />
                                </tbody>
                            </table>
                            <div className="container-fluid mt-5">
                                <div className="">
                                    <button type="button" className="btn btn-success text-light " id="temporary_save"
                                        onClick={(e) => {
                                            updateScheduleReg({ SCHEDULE_REG, userId })
                                        }}
                                    >Сохранить</button>
                                    <button type="button" className="btn btn-danger ms-2" id="temporary_cleare"
                                        onClick={(e) => {
                                            // deleteSlider()
                                            console.log(SCHEDULE_REG.schedule)
                                            const clearScheduleReg = SCHEDULE_REG.schedule.map((value, key) => {
                                                return {
                                                    slider: {
                                                        connect: [
                                                            true,
                                                            true
                                                        ],
                                                        start: [
                                                            1440
                                                        ],
                                                        statusValue: [
                                                            {
                                                                start: 0,
                                                                end: 1440,
                                                                status: "0",
                                                            },
                                                        ],
                                                        tooltip: [
                                                            true,
                                                        ],
                                                    },
                                                    tableName: value.tableName,
                                                    // type: value.type
                                                }
                                            })
                                            console.log(clearScheduleReg)
                                            setScheduleReg({
                                                schedule: clearScheduleReg
                                            })
                                        }}
                                    >Очистить</button>
                                    {/* <button type="button" className="btn btn-success text-light ms-2" id="temporary_save"
                                        onClick={(e) => {
                                            console.log(SCHEDULE_REG.schedule)
                                        }}
                                    >Тест</button> */}
                                    <button type="button" className="float-end btn btn-danger" id="temporary_delete">Удалить временное расписание</button>
                                </div>
                            </div>
                        </div>
                    }
                    {/* <div className="row w-62vw mt-4">
                        <CreateRadioStatus />
                        <CreateChangeTimeValue />
                    </div> */}
                    {(SCHEDULE_TEMP.length === 0) ? <div>Загрузка...</div> :
                        <div className="container  mw-100" id="temporary_schedule">
                            <CreateDatePicker date={{ START, END }} updateTempSchedule={updateTempSchedule} />
                            <table className="container-fluid fs-table-10 min-vw-98 vw-95 table-bordered text-checkbox mt-5 text-center">
                                <thead>
                                    <tr>
                                        <td className="w-10" >Имя</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <SliderRange
                                        propSchedule={SCHEDULE_TEMP}
                                        propCallback={setScheduleTemp}
                                        weekNameMap={dateMapRef.current}
                                        nameTable="temporary_schedule"
                                    />
                                </tbody>
                            </table>
                            <div className="container-fluid mt-5">
                                <div className="">
                                    <button type="button" className="btn btn-success text-light" id="temporary_save"
                                        onClick={(e) => {
                                            updateScheduleTemp({ SCHEDULE_TEMP, userId })
                                        }}
                                    >Сохранить</button>
                                    <button type="button" className="btn btn-danger ms-2" id="temporary_cleare"
                                        onClick={(e) => {
                                            // deleteSlider()
                                            console.log(SCHEDULE_TEMP.schedule)
                                            const clearScheduleTemp = SCHEDULE_TEMP.schedule.map((value, key) => {
                                                return {
                                                    slider: {
                                                        connect: [
                                                            true,
                                                            true
                                                        ],
                                                        start: [
                                                            1440
                                                        ],
                                                        statusValue: [
                                                            {
                                                                start: 0,
                                                                end: 1440,
                                                                status: "0",
                                                            },
                                                        ],
                                                        tooltip: [
                                                            true,
                                                        ],
                                                    },
                                                    tableName: value.tableName,
                                                    type: value.type
                                                }
                                            })
                                            console.log(clearScheduleTemp)
                                            setScheduleTemp({
                                                schedule: clearScheduleTemp
                                            })
                                        }}
                                    >Очистить</button>
                                    {/* <button type="button" className="btn btn-success text-light ms-2" id="temporary_save"
                                        onClick={(e) => {
                                            // deleteSlider()
                                            console.log(SCHEDULE_TEMP.schedule)
                                        }}
                                    >Тест</button> */}
                                    <button type="button" className="float-end btn btn-danger" id="temporary_delete"
                                        onClick={(e) => {
                                            deleteTempSchedule({ START, END, userId })
                                            console.log(SCHEDULE_TEMP.schedule)
                                        }}
                                    >Удалить временное расписание</button>

                                </div>
                            </div>
                        </div>
                    }
                </div>
    )
}

export { CreateSchedule }