import { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router';
import { api } from '../../../service/api-url';
const  getUserInfo = async ()=> {
    try {
        console.log('getUserInfo')
        let getResult = await fetch(`${api()}/api/users/user-create-info.php`, {
            "body": null,
            "method": "GET",
            "mode": "cors",
            "credentials": "include"
        })
        const result = await getResult.json();
        return result
    }
    catch (e) {
        console.error(e.message)
    }

}

export { getUserInfo }