import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import ACTIONS from '../../socket/actions';
import socket from '../../socket';
import useWebRTC, { } from './useWebRTC';

export default function Room() {
  const history = useNavigate();
  const [PING_RESULT, updateRoomsPing] = useState([]);
  const rootNode = useRef();
  let ping = []
  const { id: roomID } = useParams();
  const { clients, provideMediaRef, scaleScreenClients, peerConnections } = useWebRTC(roomID);
  //  console.log(roomID)

  useEffect(() => {
    socket.on(ACTIONS.PING_STATS, ({ payload } = {}) => {
      if (rootNode.current) {

        updateRoomsPing((PING_RESULT) => [...PING_RESULT, payload]);
      }
    });
  }, []);

  function disconnect() {
    socket.off(ACTIONS.MESSAGE);
    socket.emit('leave')
  }

  function returnConnect(){
    // socket.emit('message', { to: roomID, payload: null, type: "init" })
    socket.emit('join-mobile', { to: roomID, payload: null })
  }

  function getclientPing() {
    let serverNumber = document.querySelector('#server-number').value;
    socket.emit('message', { payload: { server: serverNumber }, from: roomID, type: "start-ping" })
  }
  return (
    <div>
      <div ref={rootNode}>
        <div className="remoteStreams">
          <h2 className="ms-2 ">Демонстрация экрана телефона </h2>
          <button className="ms-2 btn btn-success" value="true" name="oneless" onClick={() => {
            disconnect()
            history(`/sharing/`);
          }}>
            <i className="fa fa-arrow-left"></i>
          </button>
          <div className="col-2 float-end w-620px" >
            <div>
              <button id="new_person" type="button submit" className="btn btn-success float-end" onClick={() => {
                returnConnect()
              }}>Повторить подключение</button>

              <button id="new_person" type="button submit" className="btn btn-success float-end" onClick={() => {
                getclientPing()
              }}>Запросить пинг</button>
            </div>
            <div className="mt-5 ">
              <div className="input-group mb-3 w-200px">
                <span className="input-group-text" id="basic-addon">Номер сервера</span>
                <input type="text" className="form-control" defaultValue="1" id="server-number" aria-describedby="basic-addon3" />
              </div>
            </div>
            <table className="table float-end ">
              <thead>
                <tr>
                  <th scope="col">Статистика</th>
                </tr>
              </thead>
              <tbody>
                {
                  PING_RESULT.map((roomID, key) => (
                    <tr key={key}>
                      <th scope="row">{roomID}</th>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <div id="remoteVideosContainer">
          </div>
          {clients.map((clientID) => {
            return (
              <div key={clientID} id={clientID} className=''>
                <video className=""
                id={clientID + 'video'}
                  ref={instance => {
                    provideMediaRef(clientID, instance);
                  }}
                  autoPlay
                  muted={clientID}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}