const getControlLesson = (eventList)=>{

    // const test = eventList.some((eventValue)=>{
    //     return eventValue[1].text.toLowerCase().indexOf("кну") >= 0
    // })

    // console.log(test)

    return eventList.some((eventValue)=>{
        return eventValue[1].text.toLowerCase().indexOf("кну") >= 0
    })
}

const getAssignAllControlLesson = (eventList)=>{
    const filterControlLesson = eventList.filter((eventValue)=>{
        return eventValue[1].text.toLowerCase().indexOf("кну") >= 0
    })

    const test = filterControlLesson.some((eventValue)=>{
        console.log(eventValue[1].assigned_by_id)
        return eventValue[1].assigned_by_id !== "0"
    })

    
    
    return filterControlLesson.some((eventValue)=>{
        console.log('filterControlLesson: ' + test)
        console.log('assig ID: ' + eventValue[1].assigned_by_id)
        return eventValue[1].assigned_by_id !== "0"
    })
}

export { getControlLesson, getAssignAllControlLesson  }