import { useState, useEffect, useRef, useMemo, useCallback, createContext } from 'react';
import { getStatsPeerConnection } from '../../service/get-webrtc-stats'
const WebrtcStats = (props)=>{
    const [STATS_VALUE, setStatsValue] = useState([])


    // console.log(props.peerConnections.current)
    if(props.peerConnections){
        const arrayPeerConnection = Object.entries(props.peerConnections.current);
        // console.log(arrayPeerConnection)
        arrayPeerConnection.forEach((peer) => {
            console.log(peer[1].pc)

            peer[1].pc.onicecandidateerror = ((value)=>{
                console.log(value)
            });

            peer[1].pc.oniceconnectionstatechange = ((value)=>{
                console.log(value)
            });

            peer[1].pc.onsignalingstatechange = ((value)=>{
                console.log(value)
            });

        });
    }
    // getStatsPeerConnection(props.peerConnections.current)

    setInterval(() => {
        if(props.peerConnections){
            const arrayPeerConnection = Object.entries(props.peerConnections.current);
            
            arrayPeerConnection.forEach((peer) => {
                getStatsPeerConnection(peer[1]);
            });
        }
    }, 1000);

    return (
    <div className="">
        <div className="card text-white bg-secondary mb-3">
            <div className="card-header">Статистика подключения</div>
            <div className="card-body p-0">
                <div className="dataTables_wrapper no-footer">
                    <table className="table table-hover dataTable no-footer text-center" role="grid" aria-describedby="third_info">
                        <thead>
                            <tr>
                                <th>Пинг</th>
                                <th>Потери</th>
                                <th>Битрейт</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </div>
    )
}

export default WebrtcStats