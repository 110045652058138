import { getDateSlot, getDateForamteSlot } from '../../../hooks/date-time'
import { setGroup, setRole } from './group-and-role'
import { api } from '../../../service/api-url';
const getUsers = async () => {
    // console.log('getScheduleReg')
    // try {
        const url = `${api()}/api/users/people.php`;
        let setResult = await fetch(url, {
            method: 'GET',
            credentials: "include"
        })
        const responseCode = setResult.status
        if(responseCode === 200){
            const result = await setResult.json();
            const GROUP_AND_USER = []
            const ROLE_USER = []
            result.groups.forEach(element => {
                if(element.role === '1'){
                    console.log(element)
                }
                GROUP_AND_USER[element.id] = {
                    name: element.name,
                    operator: []
                }
            });
            result.roles.forEach(element => {
                ROLE_USER[element.id] = {
                    name: element.name
                }
            });
            
            setRole(ROLE_USER)
            console.log(GROUP_AND_USER)
            result.list.forEach(element => {
                element['last_seen'] = ''
                result.last_seen.find(({ author, date }, index) => {
                    // console.log(author)
                    if(author === element.id){
                        element['last_seen'] = date
                        return author
                    }
                });
                if(GROUP_AND_USER[element.user_group]){
                    // console.log(element)
                    GROUP_AND_USER[element.user_group]['operator'].push(element)
                }else{
                    GROUP_AND_USER[element.user_group] = {
                        name: `Неизвестная группа номер ${element.user_group}`,
                        operator: [element]
                    }
                }
            });
            return GROUP_AND_USER
        }else if(responseCode === 403){
            const result = await setResult.text();
            const resultEror = {
                document : result,
                error: responseCode
            }
            return resultEror;
        }
    // }
    // catch (e) {
    //     console.error(e.message)
    // }
}

export { getUsers }