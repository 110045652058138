const logs = [
  {
    timestamp: 1660809728,
    type: "App Open",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"type":null}',
      "2022-08-18 10:02:08.555+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660662105,
    type: "App Open",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"type":null}',
      "2022-08-16 17:01:45.804+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660813394,
    type: "message_by_user_send",
    source: "notificator",
    field_names: [],
    field_values: [],
    user_field_names: ["userId"],
    user_field_values: ["16496760"],
    context_field_names: [],
    context_field_values: [],
  },
  {
    timestamp: 1660813394,
    type: "message_by_user_sent",
    source: "notificator",
    field_names: [],
    field_values: [],
    user_field_names: ["userId"],
    user_field_values: ["16496760"],
    context_field_names: [],
    context_field_values: [],
  },
  {
    timestamp: 1660671172,
    type: "App Open",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"type":null}',
      "2022-08-16 19:32:52.187+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660752175,
    type: "message_by_user_send",
    source: "notificator",
    field_names: [],
    field_values: [],
    user_field_names: ["userId"],
    user_field_values: ["16496760"],
    context_field_names: [],
    context_field_values: [],
  },
  {
    timestamp: 1660752176,
    type: "message_by_user_sent",
    source: "notificator",
    field_names: [],
    field_values: [],
    user_field_names: ["userId"],
    user_field_values: ["16496760"],
    context_field_names: [],
    context_field_values: [],
  },
  {
    timestamp: 1660815116,
    type: "App Open",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"type":null}',
      "2022-08-18 11:31:56.148+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660815154,
    type: "App Open",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"type":null}',
      "2022-08-18 11:32:34.939+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660815183,
    type: "App Open",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"type":null}',
      "2022-08-18 11:33:03.710+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660752624,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "enter",
      "1660752392346",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660752653,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "leave",
      "1660752432615",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660752664,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "enter",
      "1660752446821",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660815215,
    type: "App Open",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"type":null}',
      "2022-08-18 11:33:35.111+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660752697,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "leave",
      "1660752498952",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660752706,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "enter",
      "1660752516256",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660752734,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "leave",
      "1660752560553",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660752751,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "enter",
      "1660752585295",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660752773,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "leave",
      "1660752615860",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660815465,
    type: "main screen",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"product":"main","feature":"main","action_type":"screen","build":568,"screen":"main","state_id":"open"}',
      "2022-08-18 11:37:45.410+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660815475,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "enter",
      "1660815475503",
      "1660815107",
      "lesson",
      "15336608",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "lozaledube"],
  },
  {
    timestamp: 1660815474,
    type: "lesson clicked enter room",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"build":568}',
      "2022-08-18 11:37:54.229+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660815467,
    type: "main screen",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"product":"main","feature":"main","action_type":"screen","build":568,"screen":"main","state_id":"close"}',
      "2022-08-18 11:37:47.282+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660815474,
    type: "lesson screen",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"mode":"native","build":568}',
      "2022-08-18 11:37:54.711+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660815476,
    type: "homework step loading completed",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"code":0,"step_id":"5da1e223-a7cb-4b3b-9e4e-5fa0d6eb84f1","build":568,"status":"success"}',
      "2022-08-18 11:37:56.883+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660815480,
    type: "lesson step shown",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"step_uuid":"5da1e223-a7cb-4b3b-9e4e-5fa0d6eb84f1","renderer":"native","lesson_id":"30030","build":568}',
      "2022-08-18 11:38:00.744+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660815512,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "leave",
      "1660815512457",
      "1660815107",
      "lesson",
      "15336608",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "lozaledube"],
  },
  {
    timestamp: 1660815508,
    type: "chat push opened",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"build":568,"chat_id":"join_lesson"}',
      "2022-08-18 11:38:28.310+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660815508,
    type: "main screen",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"product":"main","feature":"main","action_type":"screen","build":568,"screen":"main","state_id":"open"}',
      "2022-08-18 11:38:28.125+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660815521,
    type: "main screen",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"product":"main","feature":"main","action_type":"screen","build":568,"screen":"main","state_id":"open"}',
      "2022-08-18 11:38:41.243+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660815522,
    type: "main screen",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"product":"main","feature":"main","action_type":"screen","build":568,"screen":"main","state_id":"close"}',
      "2022-08-18 11:38:42.178+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660815528,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "enter",
      "1660815527843",
      "1660815107",
      "lesson",
      "15336608",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "lozaledube"],
  },
  {
    timestamp: 1660815527,
    type: "lesson screen",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"mode":"native","build":568}',
      "2022-08-18 11:38:47.157+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660815528,
    type: "homework step loading completed",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"code":0,"step_id":"5da1e223-a7cb-4b3b-9e4e-5fa0d6eb84f1","build":568,"status":"success"}',
      "2022-08-18 11:38:48.962+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660815508,
    type: "main screen",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"product":"main","feature":"main","action_type":"screen","build":568,"screen":"main","state_id":"close"}',
      "2022-08-18 11:38:28.695+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660815533,
    type: "lesson step shown",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"step_uuid":"5da1e223-a7cb-4b3b-9e4e-5fa0d6eb84f1","renderer":"native","lesson_id":"30030","build":568}',
      "2022-08-18 11:38:53.962+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660815525,
    type: "lesson clicked enter room",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"build":568}',
      "2022-08-18 11:38:45.979+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660815773,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "leave",
      "1660815773063",
      "1660815107",
      "lesson",
      "15336608",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "lozaledube"],
  },
  {
    timestamp: 1660815876,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "enter",
      "1660815876713",
      "1660815107",
      "lesson",
      "15336608",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "lozaledube"],
  },
  {
    timestamp: 1660753382,
    type: "vimbox.startWidget.ready",
    source: "widgets-host",
    field_names: ["initTimeDeltaMs"],
    field_values: ["235"],
    user_field_names: ["id"],
    user_field_values: ["16496760"],
    context_field_names: [
      "browser_name",
      "browser_version",
      "device_type",
      "environment",
      "mobile",
      "os_name",
      "os_version",
      "pageUrl",
      "pagePath",
      "pageTitle",
      "referrer",
      "userAgent",
    ],
    context_field_values: [
      "Chrome",
      "103.0.0.0",
      "desktop",
      "browser",
      "false",
      "Windows",
      "8.1",
      "https://vimbox.skyeng.ru/video-call/test?utm_source=side_dropdown_menu",
      "/video-call/test",
      "Vimbox",
      "https://student.skyeng.ru/",
      "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/103.0.0.0 Safari/537.36",
    ],
  },
  {
    timestamp: 1660753382,
    type: "vimbox.startWidget.roomOpened",
    source: "widgets-host",
    field_names: ["notificationId"],
    field_values: ["rooms_user-16496760_12275258-16496760"],
    user_field_names: ["id"],
    user_field_values: ["16496760"],
    context_field_names: [
      "browser_name",
      "browser_version",
      "device_type",
      "environment",
      "mobile",
      "os_name",
      "os_version",
      "pageUrl",
      "pagePath",
      "pageTitle",
      "referrer",
      "userAgent",
    ],
    context_field_values: [
      "Chrome",
      "103.0.0.0",
      "desktop",
      "browser",
      "false",
      "Windows",
      "8.1",
      "https://vimbox.skyeng.ru/video-call/test?utm_source=side_dropdown_menu",
      "/video-call/test",
      "Vimbox",
      "https://student.skyeng.ru/",
      "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/103.0.0.0 Safari/537.36",
    ],
  },
  {
    timestamp: 1660753382,
    type: "vimbox.startWidget.joinRoomButtonShow",
    source: "widgets-host",
    field_names: ["notificationId", "lessonId"],
    field_values: ["rooms_user-16496760_12275258-16496760", "buzifobuzu"],
    user_field_names: ["id"],
    user_field_values: ["16496760"],
    context_field_names: [
      "browser_name",
      "browser_version",
      "device_type",
      "environment",
      "mobile",
      "os_name",
      "os_version",
      "pageUrl",
      "pagePath",
      "pageTitle",
      "referrer",
      "userAgent",
    ],
    context_field_values: [
      "Chrome",
      "103.0.0.0",
      "desktop",
      "browser",
      "false",
      "Windows",
      "8.1",
      "https://vimbox.skyeng.ru/video-call/test?utm_source=side_dropdown_menu",
      "/video-call/test",
      "Vimbox",
      "https://student.skyeng.ru/",
      "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/103.0.0.0 Safari/537.36",
    ],
  },
  {
    timestamp: 1660753382,
    type: "vimbox.startWidget.showed",
    source: "widgets-host",
    field_names: ["notificationId", "state"],
    field_values: ["rooms_user-16496760_12275258-16496760", "started"],
    user_field_names: ["id"],
    user_field_values: ["16496760"],
    context_field_names: [
      "browser_name",
      "browser_version",
      "device_type",
      "environment",
      "mobile",
      "os_name",
      "os_version",
      "pageUrl",
      "pagePath",
      "pageTitle",
      "referrer",
      "userAgent",
    ],
    context_field_values: [
      "Chrome",
      "103.0.0.0",
      "desktop",
      "browser",
      "false",
      "Windows",
      "8.1",
      "https://vimbox.skyeng.ru/video-call/test?utm_source=side_dropdown_menu",
      "/video-call/test",
      "Vimbox",
      "https://student.skyeng.ru/",
      "Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/103.0.0.0 Safari/537.36",
    ],
  },
  {
    timestamp: 1660816017,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "leave",
      "1660816017491",
      "1660815107",
      "lesson",
      "15336608",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "lozaledube"],
  },
  {
    timestamp: 1660816021,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "enter",
      "1660816021474",
      "1660815107",
      "lesson",
      "15336608",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "lozaledube"],
  },
  {
    timestamp: 1660816269,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "leave",
      "1660816268741",
      "1660815107",
      "lesson",
      "15336608",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "lozaledube"],
  },
  {
    timestamp: 1660754069,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "enter",
      "1660754069167",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660816813,
    type: "lesson clicked enter room",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"build":568}',
      "2022-08-18 12:00:13.003+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660816814,
    type: "lesson screen",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"mode":"native","build":568}',
      "2022-08-18 12:00:14.104+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660816813,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: ["enter", "1660816774124", "1660813231", "lesson", "5316512"],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "nevepafiti"],
  },
  {
    timestamp: 1660816815,
    type: "homework step loading completed",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"code":0,"step_id":"a0fba628-6c92-4e40-a4d1-9af2fd05c121","build":568,"status":"success"}',
      "2022-08-18 12:00:15.865+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660816818,
    type: "lesson step shown",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"step_uuid":"a0fba628-6c92-4e40-a4d1-9af2fd05c121","renderer":"native","lesson_id":"14750","build":568}',
      "2022-08-18 12:00:18.073+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660754200,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "leave",
      "1660754200050",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660816843,
    type: "chat push opened",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"build":568,"chat_id":"join_lesson"}',
      "2022-08-18 12:00:43.120+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660816843,
    type: "main screen",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"product":"main","feature":"main","action_type":"screen","build":568,"screen":"main","state_id":"open"}',
      "2022-08-18 12:00:43.686+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660816846,
    type: "lesson screen",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"mode":"native","build":568}',
      "2022-08-18 12:00:46.323+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660816848,
    type: "homework step loading completed",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"code":0,"step_id":"a0fba628-6c92-4e40-a4d1-9af2fd05c121","build":568,"status":"success"}',
      "2022-08-18 12:00:48.147+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660816850,
    type: "lesson step shown",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"step_uuid":"a0fba628-6c92-4e40-a4d1-9af2fd05c121","renderer":"native","lesson_id":"14750","build":568}',
      "2022-08-18 12:00:50.893+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660816847,
    type: "main screen",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"product":"main","feature":"main","action_type":"screen","build":568,"screen":"main","state_id":"close"}',
      "2022-08-18 12:00:47.073+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660754885,
    type: "App Open",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"type":null}',
      "2022-08-17 18:48:05.516+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660754902,
    type: "App Open",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"type":null}',
      "2022-08-17 18:48:22.504+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660754918,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "enter",
      "1660754918159",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660755556,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: ["enter", "1660755546732", "1660752081", "lesson", "5316512"],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "vuforenuri"],
  },
  {
    timestamp: 1660755567,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "leave",
      "1660755553655",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660755570,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "enter",
      "1660755555779",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660756063,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "leave",
      "1660755758382",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660756073,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "enter",
      "1660755764634",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660756138,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "leave",
      "1660755818322",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660756151,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "enter",
      "1660755828840",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660756178,
    type: "App Open",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"type":null}',
      "2022-08-17 19:09:38.410+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660756346,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: ["leave", "1660756346136", "1660752081", "lesson", "5316512"],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "vuforenuri"],
  },
  {
    timestamp: 1660756424,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: ["enter", "1660756424539", "1660752081", "lesson", "5316512"],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "vuforenuri"],
  },
  {
    timestamp: 1660756427,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "leave",
      "1660756427522",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660756437,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "enter",
      "1660756437626",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660756439,
    type: "App Open",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"type":null}',
      "2022-08-17 19:13:59.187+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660756906,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "leave",
      "1660756906388",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660756956,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "enter",
      "1660756956273",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660756951,
    type: "App Open",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"type":null}',
      "2022-08-17 19:22:31.507+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660730868,
    type: "message_by_user_send",
    source: "notificator",
    field_names: [],
    field_values: [],
    user_field_names: ["userId"],
    user_field_values: ["16496760"],
    context_field_names: [],
    context_field_values: [],
  },
  {
    timestamp: 1660730868,
    type: "message_by_user_sent",
    source: "notificator",
    field_names: [],
    field_values: [],
    user_field_names: ["userId"],
    user_field_values: ["16496760"],
    context_field_names: [],
    context_field_values: [],
  },
  {
    timestamp: 1660757150,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "leave",
      "1660757150014",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660757153,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "enter",
      "1660757153747",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660820098,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: ["leave", "1660820097953", "1660813231", "lesson", "5316512"],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "nevepafiti"],
  },
  {
    timestamp: 1660757565,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: [
      "leave",
      "1660757565164",
      "1660752293",
      "lesson",
      "12275258",
    ],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "buzifobuzu"],
  },
  {
    timestamp: 1660758656,
    type: "App Open",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"type":null}',
      "2022-08-17 19:50:56.665+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660759013,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: ["leave", "1660759013017", "1660752081", "lesson", "5316512"],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "vuforenuri"],
  },
  {
    timestamp: 1660759313,
    type: "App Open",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"type":null}',
      "2022-08-17 20:01:53.009+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660759337,
    type: "App Open",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"type":null}',
      "2022-08-17 20:02:17.154+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660759875,
    type: "App Open",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"type":null}',
      "2022-08-17 20:11:15.779+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660759890,
    type: "App Open",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"type":null}',
      "2022-08-17 20:11:30.429+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660760332,
    type: "App Open",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"type":null}',
      "2022-08-17 20:18:52.670+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660761815,
    type: "user-room-activity",
    source: "vimbox-rooms",
    field_names: [
      "action",
      "actionTimeMs",
      "roomCreatedAtTs",
      "roomType",
      "teacherId",
    ],
    field_values: ["leave", "1660761815320", "1660761617", "test", "16496760"],
    user_field_names: ["id", "userId"],
    user_field_values: ["16496760", "16496760"],
    context_field_names: ["transport", "roomHash"],
    context_field_values: ["chat-db", "xosuxeretu"],
  },
  {
    timestamp: 1660761954,
    type: "Training Start",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      "{}",
      "2022-08-17 20:45:54.931+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660762192,
    type: "Training Start",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      "{}",
      "2022-08-17 20:49:52.970+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660762348,
    type: "Training Start",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      "{}",
      "2022-08-17 20:52:28.921+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660772468,
    type: "App Open",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"type":null}',
      "2022-08-17 23:41:08.001+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660845126,
    type: "training results screen",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"build":568}',
      "2022-08-18 19:52:06.957+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660845122,
    type: "main screen",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"product":"main","feature":"main","action_type":"screen","build":568,"screen":"main","state_id":"close"}',
      "2022-08-18 19:52:02.243+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660845114,
    type: "main screen",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"product":"main","feature":"main","action_type":"screen","build":568,"screen":"main","state_id":"open"}',
      "2022-08-18 19:51:54.372+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660845129,
    type: "main screen",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"product":"main","feature":"main","action_type":"screen","build":568,"screen":"main","state_id":"open"}',
      "2022-08-18 19:52:09.272+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660845142,
    type: "study videopractice block shown",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"build":568}',
      "2022-08-18 19:52:22.170+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660845158,
    type: "main screen",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"product":"main","feature":"main","action_type":"screen","build":568,"screen":"main","state_id":"close"}',
      "2022-08-18 19:52:38.188+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660581789,
    type: "App Open",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"type":null}',
      "2022-08-15 18:43:09.422+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
  {
    timestamp: 1660845557,
    type: "training results screen",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"build":568}',
      "2022-08-18 19:59:17.413+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660845560,
    type: "main screen",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"product":"main","feature":"main","action_type":"screen","build":568,"screen":"main","state_id":"open"}',
      "2022-08-18 19:59:20.987+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660845569,
    type: "study videopractice block shown",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"build":568}',
      "2022-08-18 19:59:29.712+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660845577,
    type: "main screen",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      '{"product":"main","feature":"main","action_type":"screen","build":568,"screen":"main","state_id":"close"}',
      "2022-08-18 19:59:37.642+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "9.65.0",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "null",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v6.3.2",
    ],
  },
  {
    timestamp: 1660582317,
    type: "Training Start",
    source: "Skyeng Android",
    field_names: [
      "event_source",
      "attributed_touch_time_selected_timezone",
      "attributed_touch_type",
      "attributed_touch_time",
      "appsflyer_id",
      "event_value",
      "event_time_selected_timezone",
    ],
    field_values: [
      "SDK",
      "null",
      "null",
      "null",
      "1660400110126-3274373803803200203",
      "{}",
      "2022-08-15 18:51:57.157+0200",
    ],
    user_field_names: [
      "customer_user_id",
      "city",
      "postal_code",
      "install_time",
      "af_adset_id",
      "country_code",
      "match_type",
      "http_referrer",
      "media_source",
      "campaign",
      "region",
      "original_url",
      "af_adset",
      "af_ad",
      "state",
      "af_channel",
      "af_reengagement_window",
      "language",
      "af_ad_type",
      "af_ad_id",
      "is_primary_attribution",
    ],
    user_field_values: [
      "16496760",
      "Moscow",
      "117105",
      "2022-08-13 14:09:31.285",
      "null",
      "RU",
      "null",
      "null",
      "organic",
      "null",
      "EU",
      "null",
      "null",
      "null",
      "MOW",
      "null",
      "null",
      "русский",
      "null",
      "null",
      "true",
    ],
    context_field_names: [
      "idfv",
      "device_category",
      "app_version",
      "wifi",
      "operator",
      "api_version",
      "is_retargeting",
      "ip",
      "imei",
      "android_id",
      "device_type",
      "retargeting_conversion_type",
      "app_id",
      "carrier",
      "os_version",
      "platform",
      "user_agent",
      "sdk_version",
    ],
    context_field_values: [
      "null",
      "phone",
      "5.3.2",
      "false",
      "MTS RUS",
      "2.0",
      "false",
      "31.173.82.194",
      "null",
      "f518b6e2f44f4a9d",
      "null",
      "null",
      "skyeng.words.prod",
      "MTS RUS",
      "11",
      "android",
      "Dalvik/2.1.0 (Linux; U; Android 11; M2101K7AG Build/RKQ1.201022.002)",
      "v4.8.20",
    ],
  },
];

const getClickHouseLogs = async (userId, firtsDate, lastDate, dateList) => {
  const searchBody = {
    userId: userId,
    startDateSearch: firtsDate,
    endDateSearch: lastDate,
  };

  try {
    const url = `https://bots-setting.ru/api-bot/admin/get-log-clickhouse`;

    // const url = `https://bots.skyeng.tech/api-bot/admin/get-log-clickhouse`;
    let setResult = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(searchBody),
        credentials: "include"
    })
    const result = await setResult.json();
    return result;

    // let setResult = await fetch("https://api.datsy-test.ru/logs/include/getter.php", {
    //   headers: {
    //     accept: "*/*",
    //     "accept-language": "ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7",
    //     "content-type": "text/plain;charset=UTF-8",
    //     "sec-ch-ua":
    //       '"Chromium";v="104", " Not A;Brand";v="99", "Google Chrome";v="104"',
    //     "sec-ch-ua-mobile": "?0",
    //     "sec-ch-ua-platform": '"Windows"',
    //     "sec-fetch-dest": "empty",
    //     "sec-fetch-mode": "cors",
    //     "sec-fetch-site": "same-site",
    //   },
    //   referrer: "https://datsy-test.ru/",
    //   referrerPolicy: "strict-origin-when-cross-origin",
    //   body: '{"start":"2022-08-18","end":"2022-08-19","eventsId":"","eventText":"","eventsAuthor":""}',
    //   method: "POST",
    //   mode: "cors",
    //   credentials: "omit",
    // });
    // const result = await setResult.json();
    // return result;
    // return logs;
  } catch (e) {
    console.error(e.message);
  }
};

export { getClickHouseLogs };
