import { createMoscowTime, createDateTimeMoscow } from '../../../../../hooks/date-time'
// import { setUserPermission } from '../../../hooks/permission'
import $ from "jquery";
import { columns } from "./columns-option";

const tableList = {
  first: {
    name: "week-event-list-one",
    search: true,
  },
};

const mapData = (data) => {
    const mapedData = data.reduce((acc, element) => {
    const contextName = element.context_field_names;
    const contextValue = element.context_field_values;
    const fieldName = element.user_field_names;
    const fieldValue = element.user_field_values;

    console.log(element.source)

    if (element.source === "Skyeng Android" || element.source === "Skyeng iOS") {
      const dateTime = createDateTimeMoscow(element.timestamp * 1000)
      const logName = element.type;
      const control = '';
      const source = element.source;
      const mobilePhone = `${contextValue[1]} ${element.context_field_values[15]} ${element.context_field_values[14]}`;
      const appVersion = `app version ${element.context_field_values[2]}`;
      const wifi = element.context_field_values[3];
      const url = '';
      const operator = element.context_field_values[4];

      return [...acc, [dateTime, logName, control, source, appVersion, mobilePhone, url, wifi, operator]];
    }
    
    if (element.source === "student-cabinet") {
      const dateTime = createDateTimeMoscow(element.timestamp * 1000)
      const logName = element.type;
      
      const control = element.field_names[16] === 'control' ? element.field_values[16] : ''
      const source = element.source;
      const browser = `${contextValue[0]} ${contextValue[1]}`;
      const osDevice = `${contextValue[5]} ${contextValue[6]}`
      const wifi = '';
      const url = contextValue[7];
      const operator = '';

      return [...acc, [dateTime, logName, control, source, browser, osDevice, url, wifi, operator]];
    }

    // if (fieldName.length > 7) {
    //   return [...acc, [element.type, element.source, ...contextValue]];
    // }
    return [...acc];
  }, []);
  return mapedData;
};

const createTable = async (data) => {
  const tableArray = Object.entries(tableList);

  const mapedData = mapData(data);
  // console.log(mapedData);
  tableArray.forEach((element) => {
    const Table = $(`#${element[0]}`);
    // Table.DataTable().destroy();
    // const dataElement = data[element[1].name]
    const CreatTable = Table.DataTable({
      order: [],
      paging: true,
      searching: element[1].search,
      autoWidth: false,
      data: mapedData,
      // "columns": columns[element[0]]
    });
  });
};

const updateTable = (data) => {
  const mapedData = mapData(data);
  const tableArray = Object.entries(tableList);
  tableArray.forEach((element) => {
    const Table = $(`#${element[0]}`);
    // Table.DataTable().destroy();
    const CreatTable = Table.DataTable();
    CreatTable.clear();
    CreatTable.draw();
    CreatTable.rows.add(mapedData).draw();
  });
};

export { createTable, updateTable };
