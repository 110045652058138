import { api, shedulePath } from '../../../service/api-url';

const getRangeTypeSchdedule = async (firtsDate, lastDate) => {
    const searchBody = {
        firtsDate,
        lastDate
    }
    try {
      const url = `${api()}/api/settings/index.php`;
      let setResult = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(searchBody),
          credentials: "include"
      })
      const result = await setResult.json();
      return result;
  
    } catch (e) {
      console.error(e.message);
    }
  };
  
  export { getRangeTypeSchdedule };
  