import { formatTime } from './slider-lib'

const sliderInit = (sliderParam) => {

    const otpion = {
        start: sliderParam.start,
        connect: sliderParam.connect,
        tooltips: sliderParam.tooltip,

        // start: [ null ],
        // connect: [false, false],
        // tooltips: [],

        // start: [119, 500, 1000, 1200],
        // connect: [false, true, true, true, false],
        // tooltips: [true, true, true, true],

        range: {
            'min': [0],
            'max': [1440]
        },
        // limit: 90,
        behaviour: 'steps',
        step: 5,
        pips: {
            mode: 'steps',
            //values: [0, 720, 1439],
            filter: function (value, type) {
                // console.log(type, value, value % 60);
                return (value % (60 * 4) === 0) ? 1 : (value % 60 === 0) ? 2 : 0;
                // return 9;
            },
            //density: 2,
            format: {
                to: function (value) {
                    if (value % (60 * 2) === 0) {
                        return formatTime(value)
                    }
                    return '';
                },
                from: function (value) {
                    return value;
                }
            }
        },
        format: {
            to: function (value) {
                return formatTime(value)
            },
            from: function (value) {
                return value.replace(',-', '');
            }
        }

    };
    return otpion;
}

export { sliderInit }