import { CONFIG } from '../config';

function CreateAllSlot(props) {
    // console.log("CreateAllSlot")
    const { schedule } = props.propSchedule
    const { reservation } = props.propReserved
    let countSumm;
    let reserv;
    return (
        Object.entries(reservation).map(([time, element]) => {

            countSumm = 0
            // eslint-disable-next-line array-callback-return
            Object.keys(schedule).map((k, i) => {
                let body = schedule[k]['timeStatus']
                if (body && body[time] === 'work') {
                    countSumm++
                }
            })
            reserv = reservation ? reservation[time] : 0
            countSumm = countSumm - reserv
            return (
                time < '08:00' ? null :
                    time > '22:30' ? null :
                        <td key={time}>{countSumm}</td>
            )
        })
    )
}

export { CreateAllSlot }