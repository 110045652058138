import { getDateWeekForButton, createDate } from '../../../hooks/date-time'
import { CONFIG } from '../config';

const getDateMap = ({ START, END, DATE_PICK }) => {
    const arrayDateMap = {}
    const dateWeek = getDateWeekForButton().weekButton(DATE_PICK.current)
    DATE_PICK.current = dateWeek.pickDate
    START.current = dateWeek.wkStart
    END.current = dateWeek.wkEnd

    const wkStart = new Date(dateWeek.wkStart)
    const wkEnd = new Date(dateWeek.wkEnd)

    while (wkStart <= wkEnd) {
        const weekNumber = wkStart.getDay()
        const currentDate = createDate(wkStart)
        arrayDateMap[currentDate] = { "valueTable": CONFIG.week[weekNumber].description }
        wkStart.setDate(wkStart.getDate() + 1)
    }
    return arrayDateMap

}

export { getDateMap }