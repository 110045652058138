import { api } from './api-url';

const getAuth = async (url, callback) => {
    // console.log('render getAuth')
    try {
        let setResult = await fetch(url, {
            method: 'GET',
            credentials: "include"
        })
        const result = await setResult.json();
        callback(result)
    }
    catch (e) {
        console.error(e.message)
    }
}



export const CheckAuth = async (cb) => {
    // console.log('render CheckAuth')
    const url = `${api()}/api/auth/check.php`;
    await getAuth(url, (resp) => {
        // if (resp["key-status"] === 200) {
        //     cb(true)
        // }
        cb(resp["key-status"])
    });


    // console.log(result)

}