import { useState, useEffect, useRef } from 'react';
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import 'datatables.net'

import { createTable } from '../functions/create-table'

function CreateLogs(props) {
    const { LOGS } = props
    const dataTableCreate = () => {
        createTable(LOGS)
        const elementTable = $('[aria-controls="first"][type="search"]')
        elementTable.addClass('form-control form-control-sm');
    }

    useEffect(() => {
        dataTableCreate()
    }, [])
    return (
        <>
            <div className="card text-white bg-secondary mb-3 table-hover">

                <table key={''} id='first'
                    className="table table-hover dataTable no-footer text-center" role="grid" aria-describedby="third_info">

                    <thead>

                        <tr>
                            <th>Дата время</th>
                            <th>Экраг\Страница</th>
                            <th>Ивент</th>
                            <th>Источник</th>
                            <th>Браузер/Приложение</th>
                            <th>Девайс</th>
                            <th>Ссылка</th>
                            <th>wifi (приложение)</th>
                            <th>Провайдер</th>
                        </tr>

                    </thead>

                </table>
            </div>
        </>
    )
}

export { CreateLogs }