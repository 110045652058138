import { useState, useEffect, useRef, useMemo } from 'react';
import { getTimeForButton } from '../../../hooks/date-time'
import { findUserForDuty } from '../../../hooks/duty'
import { getColorMeAsign } from './get-color'
import { showEventButton } from './show-button'
import { useEventButton } from '../function/button'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DoubleClick } from '../function/double-clikc'

import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js';

export default function CreateEvent(props) {
    // console.log(props)
    // console.log('Render Event -----------------------------')
    // console.log(props.eventID)
    // console.log(`${props.date} ${props.time}`)
    const [TEXT_SLOT, setTextSlot] = useState([]);
    const [color, setColor] = useState([]);
    const TEXT = useRef()
    const BUTTON_FUNCTION = useEventButton(props.callback);

    const eventId = props.eventID
    const eventBody = props.value;
    const autoAssignList = props.autoAssignList
    const dutyList = props.dutyList
    const timeForButton = getTimeForButton(props.dateTime, 5)
    const showButton = showEventButton(props.time)
    const isControlStartLesson = props.isControlStartLesson
    const isAssignAllControlLesson = props.isAssignAllControlLesson
    const controlStartLesson = eventBody.text.toLowerCase().indexOf("кну") >= 0 ? true : false;
    
    if(eventId === '96102'){
        console.log(isAssignAllControlLesson)
        // console.log(resultAsign)
        // console.log(controlLessonStartCheck)
    }

    const skipEvent = showButton.skipEvent(
        timeForButton.formatSlot,
        eventBody.assigned_by_id,
        eventBody.auto_assign,
        eventBody.skip_event,
        controlStartLesson,
        eventId
    )
    // console.log("eventBody.skip_event: " + eventBody.skip_event)
    // console.log("skipEvent: " + skipEvent)
    const asign = showButton.asign(
        timeForButton.backDateTimeSlot,
        timeForButton.formatSlot,
        timeForButton.nowDateTime,
        eventBody.assigned_by_id,
        eventBody.auto_assign,
        autoAssignList,
        dutyList,
        eventBody.skip_event,
        controlStartLesson,
        isControlStartLesson,
        isAssignAllControlLesson,
        props.assignedUserList,
        eventId
    )
    const unAsign = showButton.unAsign(
        timeForButton.backDateTimeSlot,
        timeForButton.formatSlot,
        timeForButton.nowDateTime,
        eventBody.assigned_by_name
    )
    const save = showButton.save(
        timeForButton.backDateTimeSlot,
        timeForButton.nowDateTime,
        eventBody.created_by_name
    )
    const deleteSot = showButton.deleteSlot(
        timeForButton.backDateTimeSlot,
        timeForButton.nowDateTime,
        eventBody.created_by_name
    )

    const colorEvent = getColorMeAsign(eventBody.created_by_name, props.time)
    // return (
    // eventArray.map((eventValue, key) => {
    // console.log(`${eventId}${eventBody.text}`)
    // console.log(eventId)

    const { text } = eventBody
    const [reason, setReason] = useState('');
    const reasonRef = useRef(null);

    useEffect(() => {
        // console.log('Пришел новый текст')
        setColor(colorEvent)
    }, [text]);

    useEffect(() => {
        // console.log('Нажали кнопку и изменили текст')
        const newColor = TEXT_SLOT !== eventBody.text ? 'bg-warning' : colorEvent
        // console.log(newColor)
        setColor(newColor)
    }, [TEXT_SLOT]);

    useMemo(() => {
        // console.log('Изменился')
        setColor(colorEvent)
        setTextSlot(eventBody.text)
    }, []);

    useMemo(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            var tooltip = bootstrap.Tooltip.getInstance(tooltipTriggerEl)
            if (tooltip !== null) {
                return tooltip.dispose()
            }
        })
    }, [text]);

    useEffect(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            new bootstrap.Tooltip(tooltipTriggerEl)
        })
    }, [text]);

    const operatorNameForAutoAssign = findUserForDuty(dutyList, eventBody.auto_assign)?.userName;

    const stringTooltip = !!operatorNameForAutoAssign ? `Закреплен за ${operatorNameForAutoAssign}` : `Нет дежурных для автоназначения`

    const assignedTime = eventBody.assigned_at === null ? stringTooltip : `${eventBody.assigned_at} ${stringTooltip}`
    const viwBlockButoon = (asign === true || unAsign === true || save === true || deleteSot === true)
    return (
        <div className="btn-group timeslot" role="group" aria-label="Basic mixed styles example" event-id="" key={`${eventId}`}>
            <OverlayTrigger
                placement={'top'}
                overlay={
                    <Tooltip>
                        {assignedTime}
                    </Tooltip>
                }
            >
                <div className="w-25 p-2 form-control text-nowrap timeslot-assign" type="text">{eventBody.assigned_by_name}</div>
            </OverlayTrigger>

            <OverlayTrigger
                placement={'top'}
                overlay={
                    <Tooltip>
                        {`${eventBody.created_by_name} ${eventBody.created}`}
                    </Tooltip>
                }
            >
                <div className="input-group">

                    {
                        eventBody.skip_event === "true" ? 
                        <div className="btn btn-secondary w-50px rounded-start" type="text">
                            <i className="fa-solid fa-forward"></i>
                        </div>
                        : <></>
                    }
                    {
                        controlStartLesson &&
                        <div className="btn btn-danger w-50px" type="text">
                            <i className="fa-solid fa-headset"></i>
                        </div>
                    }
                    <input
                        className={`${color} p-2 form-control  border-secondary comment-slot`} 
                        key={eventBody.text}
                        type="text"
                        defaultValue={eventBody.text}
                        onChange={(e) => {
                            setTextSlot(e.target.value)
                            console.log(TEXT.current)
                        }}
                        onDoubleClick={() => {
                            console.log('onDoubleClick')
                            DoubleClick(eventBody.text)
                        }}
                    >
                    </input>
                </div>
            </OverlayTrigger>

            <div className={`btn-group ${viwBlockButoon === true ? 'w-40' : ''}`}>
            {
                    !!skipEvent &&
                    <>
                        <button className="btn btn-secondary" type="button" defaultValue=""
                            data-bs-toggle="modal" data-bs-target={`#skipModal${eventBody.id}`}
                        >
                            <i className="fa-solid fa-forward"></i>
                        </button>

                        <div className="modal fade" id={`skipModal${eventBody.id}`} tabIndex="-1" aria-labelledby="skipModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered ">
                                <div className="modal-content bg-dark">

                                    <div className="modal-body">
                                        Ивент
                                        <span className="text-primary">`{eventBody.text}`</span>
                                        <br></br>будет пропущен но его все равно можно будет взять, подтвердите действие
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
                                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                                            onClick={async () => {
                                                BUTTON_FUNCTION.skipEvent(eventBody.id)
                                            }}
                                        >Пропустить ивент</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }

                {/* {
                    !!skipEvent &&
                    <button className="btn btn-secondary " type="button" defaultValue=""
                        onClick={() => {
                            BUTTON_FUNCTION.skipEvent(eventBody.id)
                        }}
                    >
                        <i className="fa-solid fa-forward"></i>
                    </button>
                } */}
                {
                    !!asign &&
                    <button className="btn btn-success " type="button" defaultValue=""
                        onClick={() => {
                            BUTTON_FUNCTION.asign(eventBody.id)
                        }}
                    >
                        <i className="fa fa-arrow-down"></i>
                    </button>
                }
                {
                    !!unAsign &&
                    <button className="btn btn-warning " type="button" defaultValue=""
                        onClick={() => {
                            BUTTON_FUNCTION.unAsign(eventBody.id)
                        }}
                    >
                        <i className="fa fa-arrow-up"></i>
                    </button>
                }

                {
                    !!save &&
                    <button className="btn btn-info " type="button" defaultValue=""
                        onClick={() => {
                            BUTTON_FUNCTION.save(TEXT_SLOT, eventBody.id)

                        }}
                    >
                        <i className="fa fa-save"></i>
                    </button>
                }

                {
                    !!deleteSot &&
                    <>
                        <button className="btn btn-danger " type="button" defaultValue=""
                            data-bs-toggle="modal" data-bs-target={`#deleteModal${eventBody.id}`}
                        >
                            <i className="fa fa-times"></i>
                        </button>

                        <div className="modal fade" id={`deleteModal${eventBody.id}`} tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered ">
                                <div className="modal-content bg-dark">

                                    <div className="modal-body">
                                        Ивент
                                        <br></br>
                                        <span className="text-primary">`{eventBody.text}`</span>
                                        <br></br>будет удален, укажи причину удаления:<br></br>
                                        <input placeholder='Причина удаления' type='text' className='eventRemoverInput' value={reason} onChange={(e) => setReason(e.target.value)} ref={reasonRef}></input>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
                                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                                            onClick={async () => {
                                                if (reason.trim() !== '') {
                                                  BUTTON_FUNCTION.deleteSlot(eventBody.id, reason);
                                                  props.addSlot();
                                                  setReason('');
                                                } else {
                                                  alert('Причина удаления не может быть пустой');
                                                }
                                              }}
                                            > Удалить</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>

    )
    //     })

    // )
}