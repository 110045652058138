import { useParams } from 'react-router';
import useWebRTC, { } from '../useWebRTC2-MB';
import { useEffect, useRef, useCallback, useMemo, useState } from 'react';
import SpeedTest from '../../../componenets/spedtest-share'
import WebrtcStats from '../../../componenets/webrtc-stats'
import { sendUA } from '../send-user-agent'
export default function Room() {
  const { id: roomID } = useParams();
  const { clients, provideMediaRef, startCapture, peerConnections } = useWebRTC(roomID);
  const [SPEED_CHECK, updateSpeedCheck] = useState(false)

  useEffect(()=>{
    sendUA(roomID)
  }, [])

  return (
    <div className="container-fluid fs-table-10 min-vw-98 table-bordered text-checkbox text-center">
      <SpeedTest/>
      <WebrtcStats peerConnections={peerConnections}/>
      <div>
        <div >
          <button className="ms-2 btn btn-success container-fluid" onClick={startCapture} id="startCapture">
            Начать трансляцию
          </button>
        </div>
      </div>
      <div id="remoteVideosContainer"></div>
      {clients.map((clientID) => {
        return (
          <div className="d-flex flex-row justify-content-between mt-4" key={clientID} id={clientID}>
            <video
              className='border border-light w-75 p-3 container-fluid fs-table-10'
              ref={instance => {
                provideMediaRef(clientID, instance);
              }}
              autoPlay
              playsInline
            />
          </div>
        );
      })}
    </div>
  );
}