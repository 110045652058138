import { useState, useEffect, useMemo } from 'react';
import { createDatepickerButton } from './button-function-datepicker'

import DatePicker,  { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";


function CreateDatePicker({ updateCallback, date: { START, END } }) {

    const [startDate, setStartDate] = useState(START.current);
    const [endDate, setEndDate] = useState(END.current);

    useEffect(() => { 
        registerLocale("ru", ru);
    }, [])


    const updateDate = ()=>{
        setStartDate(START.current);
        setEndDate(END.current);
    }

    useEffect(() => {
        updateDate()
    }, [JSON.stringify([START, END])])

    console.log(START.current)
    console.log(END.current)

    const BUTTON_FUNCTION = createDatepickerButton(updateCallback);
    return (
        (START.current === '') ? <div>Загрузка...</div> :
            <div className="d-flex flex-row justify-content-center">
                <div className="s" name="temporary_schedule">
                    <div className="text-center">
                        <div className="input-group mb-3 w-520px">
                            <button type="button" className="btn btn-info" id="left_week"
                                onClick={async () => {
                                    await BUTTON_FUNCTION.previousWeek({ START, END })
                                }}
                            >◀</button>
                            <div className="w-150px">
                            <DatePicker
                                className="form-control"
                                selected={startDate}
                                onChange={(date) => {
                                    START.current = date
                                    setStartDate(date)
                                }}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                maxDate={endDate}
                                locale="ru"
                                showTimeSelect
                                timeFormat="p"
                                timeIntervals={15}
                                dateFormat="MM.dd.yyyy hh:mm"
                            />
                            </div>
                     
                            <button type="button" className="ms-2 me-2 btn btn-success" id="regular_cleare"
                                onClick={async () => {
                                    await BUTTON_FUNCTION.todayWeek({ START, END })
                                }}
                            >Эта неделя </button>
                            <div className='w-150px'>
                               <DatePicker
                                    className="form-control"
                                    selected={endDate}
                                    onChange={(date) => {
                                        END.current = date
                                        setEndDate(date)
                                    }}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    locale="ru"
                                    showTimeSelect
                                    timeFormat="p"
                                    timeIntervals={15}
                                    dateFormat="MM.dd.yyyy hh:mm"
                                />
                            </div>
                            <button type="button" className="btn btn-info" id="right_week"
                                onClick={async () => {
                                    await BUTTON_FUNCTION.nextWeek({ START, END })
                                }}
                            >▶</button>
                        </div>
                        <button type="button" className="btn btn-success" id="change_week"
                            onClick={async () => {
                                await BUTTON_FUNCTION.pickWeek({ START, END })
                            }}
                        >✅</button>
                    </div>
                </div>
            </div>
    )
}

export { CreateDatePicker }