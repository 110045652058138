import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import useWebRTC, { LOCAL_VIDEO } from '../useWebRTC2-MB-answer';
import { useNavigate } from 'react-router';
import socket from '../../../socket';
import ACTIONS from '../../../socket/actions';
import SpeedValue from '../../../componenets/speedtest-view'
import WebrtcStats from '../../../componenets/webrtc-stats'
import UserAgent from '../../../componenets/userAgent'
import ClientDeviceInfo from '../../../componenets/client-device-info/index.js'
import ClientDeviceInfoTestSocket from '../../../componenets/client-device-info/testSocket.js'
import { sendUA } from '../../Share/send-user-agent'
import { moveChange, cursorPositionCreat } from '../../../service/mouse-event'

export default function Room() {
  const [OVERFLOW, updateOverflow] = useState('')
  const history = useNavigate();
  const { id: roomID } = useParams();
  const { clients, provideMediaRef, scaleScreenClients, peerConnections } = useWebRTC(roomID);
  // console.log(clients)

  useEffect(() => {
    // sendUA(roomID)
    
    return () => {
      disconnect()
    }
  }, []);

  function createShareUrl(roomID) {
    // console.log(roomID)
    return `https://sharescreen.datsy.info/share/room/${roomID}`
  }

  function disconnect() {
    socket.off(ACTIONS.ADD_PEER);
    socket.off(ACTIONS.SHARE_MESSAGE);
    socket.emit('leave')
  }

  function getclientPing() {
    socket.emit('message', { type: "start-speed" })
  }

  const sendEvent = (element, eventType, idTo)=>{
    const scaleClient = scaleScreenClients.current.find((element)=>{
      return element.client === idTo
    })

    const position = cursorPositionCreat(element, idTo + 'video', scaleClient?.scale || null)
    const messageValue = createMessage({idTo, eventType, position})
    socket.emit(ACTIONS.MOUSE_EVENT, messageValue)
  }

  const createMessage = ({idTo, eventType, position}) =>{
    
    const payload = position ?
    {
      mouseEvent: eventType,
      cursorX: position.cursorX,
      cursorY: position.cursorY
    } 
    :
    {
      mouseEvent: eventType,
    } 

    const messageValue = {
      type: 'mouse-event',
      to: idTo,
      payload
    }

    return messageValue
  }

  

  function launchApplication(eventType, clientID_new, programName_new)
  {
      var array_new =
      {
        type: eventType,
        to: clientID_new,
        programName: "cmd"
      };
      socket.emit(ACTIONS.SHARE_MESSAGE, array_new);

      console.log("test emit: ??? " + array_new);
      console.dir(array_new);
    }

  return (
    <div className={OVERFLOW}>
      <h2 className="ms-2"> Ссылка для демонстрирующего </h2>
      <div className="input-group w-45vw ms-2">
        <button className="btn btn-success" value="true" name="oneless" onClick={() => {
          disconnect()
          history(`/sharing/`);
        }}>
          <i className="fa fa-arrow-left"></i>
        </button>
        <input className='ms-2 form-control container-fluid' defaultValue={createShareUrl(roomID)}></input>

      </div>

      <div>
        <button className="ms-2 btn btn-success mb-3 me-3 mt-3" value="true" name="oneless"
            // onClick={getclientPing}
        >Начать видеосвязь</button>
      </div>

      <div className="mt-3 ">
        <div className="row ">
          <div className="text-center">
            <div className='mb-3 '>
              <div id="remoteVideosContainer " className=''></div>
              {/* <img id="streamImage" className="" alt="" style={{
                maxHeight : "800px",
                width: "90%",
                objectFit: "scale-down"
              }}></img> */}

              {clients.map((clientID) => {
                return (
                  <div key={clientID} id={clientID} className=''>
                    <video className="border border-success w-1600px h-900px"
                    id={clientID + 'video'}
                      ref={instance => {
                        provideMediaRef(clientID, instance);
                      }}
                      autoPlay
                      muted={clientID}
                      // onClick={(e)=>{
                      //   sendEvent(e, 'click', clientID)
                      // }}
                      onMouseDown={(e)=>{
                        if(e.button === 0){
                          sendEvent(e, 'leftDown', clientID)
                        }else if(e.button === 2){
                          sendEvent(e, 'rightDown', clientID)
                        }
                      }}
                      onMouseUp={(e)=>{
                        if(e.button === 0){
                          sendEvent(e, 'leftUp', clientID)
                        }else if(e.button === 2){
                          sendEvent(e, 'rightUp', clientID)
                        }
                      }}
                      onMouseMove ={(event)=>{
                        moveChange(event, 'move', clientID, sendEvent)
                      }}
                      onContextMenu={(e)=> e.preventDefault()}
                      onWheel={(e)=>{
                        // console.log(e)
                        console.log(e.deltaY)
                        if(e.deltaY > 0){
                          const messageValue = createMessage({idTo: clientID, eventType: 'scrollDown'})
                          socket.emit(ACTIONS.MOUSE_EVENT, messageValue)
                        }
                        else if(e.deltaY < 0){
                          const messageValue = createMessage({idTo: clientID, eventType: 'scrollUp'})
                          socket.emit(ACTIONS.MOUSE_EVENT, messageValue)
                        }
                      }}

                      onMouseOver ={(e) => {
                        // document.getElementById( clientID + 'video').addEventListener('DOMMouseScroll', blockWheel, false);
                        // console.log('onMouseOver');
                        document.body.style.overflow = 'hidden'
                      }}
                      onMouseOut  ={(e) => {
                        document.body.style.overflow = null
                        // console.log('onMouseOut');
                      }}
                    />
                  </div>
                );
              })}

              {
                clients.length === 0 && 
                <div className="container-fluid w-900px h-500px">
                  Здесь будет видео ожидаем...
                </div>
              }
            </div>
          </div>

        </div>

        {clients.map((clientID) => 
        {
          return(
            <div className="" style={{marginLeft: "24px"}}>
              <button className="ms-3 btn btn-success mb-3" value="true" name="oneless"
                onClick={()=>
                  {
                    socket.emit(ACTIONS.SHARE_MESSAGE, 
                    {
                      type: 'cmd-event',
                      to: clientID
                    });
                  }}
              >Отправить тестовый ивент</button>
              <div className="card text-white bg-secondary mb-3 row row-alt ">
                <div className="card-header">Remote Control Shortcuts</div>

                <div className="card-body p-0">
                    <div id="five_wrapper" className="dataTables_wrapper no-footer"></div>
                    <div id="passwordHelpBlock" className="form-text text-light fs-4" style={{paddingLeft: "12px", paddingTop: "8px", paddingBottom: "12px"}}>
                        <button onClick={() => launchApplication('cmd-event', clientID, 'cmd')} style={{width: "44px", height: "44px", marginRight: "12px", padding: "0px"}}><img src={require('./../../../imgs/v2/remoteIcons_cmd.png')} alt='cmd / terminal' title='cmd / terminal' style={{width: "40px", height: "40px"}} /></button>
                        <img src={require('./../../../imgs/remoteIcons_taskmgr.png')} alt='taskmgr / system monitor' title='taskmgr / system monitor' style={{width: "40px", height: "40px", marginRight: "12px"}} />
                        <img src={require('./../../../imgs/v2/remoteIcons_aboutPc.png')} alt='msinfo32 / about this mac' title='msinfo32 / about this mac' style={{width: "40px", height: "40px", marginRight: "12px"}} />
                        <img src={require('./../../../imgs/remoteIcons_audio.png')} alt='mmsys.cpl / audio-midi' title='mmsys.cpl / audio-midi' style={{width: "40px", height: "40px", marginRight: "12px"}} />
                        <br />
                    </div>
                </div>
            </div>       
            </div>
          );
        })
        }

        <div className="col-5 ms-4">
          <UserAgent />
            <button className="ms-3 btn btn-success mb-3" value="true" name="oneless"
              onClick={getclientPing}
            >Проверить скорость пользователя</button>
        </div>
        <div className='col-5 ms-4'>
          <ClientDeviceInfo />
          
        </div>
        <div className="row">
          <div className="col-5 ms-4">
            <SpeedValue />
          </div>
          <div className="col-5 ms-4">
            <WebrtcStats peerConnections={peerConnections}/>
          </div>
        </div>
      </div>
    </div>
  );
}