let last_event = new Date();
const moveChange = (element, event, idTo, callback)=>{
    let new_event = new Date(); 
    if ((new_event - last_event) > 100) {
        last_event = new Date();
        callback(element, event, idTo,)
    }

}

const cursorPositionCreat = (element, idElement, scale)=>{

    const videoElement = document.getElementById(idElement)

    const x = element.pageX;
    const y = element.pageY;
    // console.log(`${x - videoElement.offsetLeft}:${y-videoElement.offsetTop}`);
    // console.log(scale)
    const scalingValue = {
      scaleX: (scale?.clientScreenSizeX || 1920) / 1600,
      scaleY: (scale?.clientScreenSizeY || 1080) / 900
    }
    // console.log(scalingValue)
    const cursorRoundX = Math.round((x - videoElement.offsetLeft) * scalingValue.scaleX)
    const cursorRoundY = Math.round((y-videoElement.offsetTop) * scalingValue.scaleY)

    return {
        cursorX: cursorRoundX,
        cursorY:cursorRoundY
    }

}


export {
    moveChange,
    cursorPositionCreat
}
