import { io } from 'socket.io-client';

const options = {
  "force new connection": true,
  reconnectionAttempts: 1, // avoid having user reconnect manually in order to prevent dead clients after a server restart
  timeout: 10000, // before connect_error and connect_timeout are emitted.
  transports: ["websocket"]
}

// const socket = io('wss://datsy.ru', options);
let host;

switch (window.location.hostname) {
  case 'localhost':
    host = 'http://localhost:3004'
    break;

  case 'app.datsy-test.info':  // if (x === 'value2')
    host = 'http://localhost:3004'
    break;
  case 'datsy-test.ru':  // if (x === 'value2')
    // host = 'http://localhost:3004'
    // host = 'https://sharescreen.datsy-test.ru'
    host = 'https://sharescreen.datsy.info'
    // host = 'https://5d6b-90-151-81-65.ngrok.io'
    break;
  case 'sharescreen.datsy-test.info':  // if (x === 'value2')
    host = 'http://localhost:3004'
    break;

  default:
    host = 'https://sharescreen.datsy.info'
    // host = window.location.origin
    break;
}


const socket = io(host, options);
var onevent = socket.onevent;
socket.onevent = function (packet) {
  var args = packet.data || [];
  onevent.call(this, packet);    // original call
  packet.data = ["*"].concat(args);
  onevent.call(this, packet);      // additional call to catch-all
};

// socket.emit('test', 'test')

/* socket.on("*",function(event,data) {
  console.log(event);
  console.log(data);
}); */
export default socket;