import { BrowserRouter, Routes, Route } from 'react-router-dom';


import Share from './pages/Share/Room';
import View from './pages/View/Room';
import ViewMobile from './pages/View/Room'
import Main from './pages/Main';
import NotFound404 from './pages/NotFound404';
import Mobile from './pages/Mobile';
import useMain, { } from './hooks/useMenu';
import Navbar from '../../components/Navbar';

const client = window.location.host === "datsy.info" ? true : false;
export default function App() {
  return (

    <Routes >
      <Route path='/share/room/:id' element={<Share />} />
      <Route path='/view/room/:id' element={<View />} />
      <Route path='/room/:id' element={<Share />} />
      <Route path='/sharing' element={<Main />} />
      <Route path='/mobile-share/room/:id' element={<Mobile />} />
      <Route path="/" element={<Main />}></Route>
      <Route path='*' element={<NotFound404 />} />
    </Routes >

  );
}

