import { useState, useEffect, useMemo } from 'react';
import { radioStatus } from '../functions/get-radio-status'
import { createDatepickerButton } from '../functions/button'
import { creatNowDate, getDateWeekForButton, setDateTimeSlot, getDateSlot, getDateForamte, getDateForamteSlot, setDateSlot, setDateForamteSlot } from '../../../hooks/date-time'
import createDatePicker from '../../../hooks/date-picker/datapicker.general.conf'
import { updateSlider } from '../functions/create-slider'

function CreateDatePicker({ updateTempSchedule, date: { START }, date: { END } }) {
    // console.log('Render Create Date Picker')
    const [START_DATE, setStart] = useState([]);
    const [END_DATE, setEnd] = useState([]);
    // console.log(START)
    // console.log(END)

    useEffect(() => {
        // console.log('useEffect Create Date Picker')
        const frontStart = getDateForamte(START.current)
        const frontEnd = getDateForamte(END.current)
        setStart(frontStart)
        setEnd(frontEnd)
    }, [START.current, END.current])

    useEffect(() => {

        const elementList = [
            {
                element: 'datepStartUser',
                isRTLboolean: false,
                show: 'focus'
            },
            {
                element: 'datepEndUser',
                isRTLboolean: false,
                show: 'focus'
            }
        ]
        const frontStart = getDateForamte(START.current)
        const frontEnd = getDateForamte(END.current)
        setStart(frontStart)
        setEnd(frontEnd)
        createDatePicker({ START, END, elementList })

    }, [])

    const BUTTON_FUNCTION = createDatepickerButton(updateTempSchedule);
    // const frontStart = getDateForamte(START_DATE)
    // const frontEnd = getDateForamte(END_DATE)
    return (
        (START.current === '') ? <div>Загрузка...</div> :
            <div className="d-flex flex-row justify-content-center">
                <div className="s" name="temporary_schedule">
                    <div className="text-center">
                        <div>Временное расписание 2\2 графика</div>
                        <div className="input-group mb-3 w-450px ">
                            <button type="button" className="btn btn-info" id="left_week"
                                onClick={async () => {
                                    await BUTTON_FUNCTION.previousWeek({ START, END })
                                    // updateSlider()
                                    // console.log(START, END)
                                }}
                            >◀</button>
                            <input type="text" form="logs" name="begin" className="form-control"
                                id="datepStartUser"
                                // key={START.current}
                                value={START_DATE}
                                onChange={(e) => {
                                    setStart(e.target.value)
                                    console.log('test')
                                }}
                            />
                            {/* <div className="w-10px"></div> */}
                            
                            <button type="button" className="ms-2 me-2 btn btn-success" id="regular_cleare"
                                onClick={async () => {
                                    await BUTTON_FUNCTION.todayWeek({ START, END })
                                    // updateSlider()
                                }}
                            >Эта неделя </button>

                            <input type="text" form="logs" name="begin" className="form-control"
                                id="datepEndUser"
                                value={END_DATE}
                                onChange={(e) => {
                                    setEnd(e.target.value)
                                    console.log('test')
                                }}
                            />
                            <button type="button" className="btn btn-info" id="right_week"
                                onClick={async () => {
                                    await BUTTON_FUNCTION.nextWeek({ START, END })
                                    // updateSlider()
                                    console.log(START, END)
                                }}
                            >▶</button>
                        </div>
                        <button type="button" className="btn btn-success" id="change_week"
                            onClick={async () => {
                                await BUTTON_FUNCTION.pickWeek({ START, END })
                                // await updateTempSchedule()
                                // updateSlider()
                                console.log(START, END)
                            }}
                        >✅</button>
                    </div>
                </div>
            </div>
    )
}

export { CreateDatePicker }