import { BrowserRouter, Routes, Route } from 'react-router-dom';


import WebAndMobileLog from './pages/web-and-mobile-logs';
import TechSummary from './pages/tech-summary';
import WhoCreateLesson from './pages/who-create-lesson'
import TimetableLogs from './pages/timetable-logs'
import Main from './pages/main';

const client = window.location.host === "datsy.info" ? true : false;
export default function App() {
  return (
    <Routes >
      <Route path='*' element={<div>Страница не найдена</div>} />
      <Route path='/' element={<Main/>} />
      <Route path='/web-and-mobile-logs' element={<WebAndMobileLog />} />
      <Route path='/tech-summary' element={<TechSummary />} />
      <Route path='/who-create-lesson' element={<WhoCreateLesson />} />
      <Route path='/timetable-logs' element={<TimetableLogs />} />
    </Routes >
  );
}
