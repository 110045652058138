import { useState, useEffect } from 'react';
import { radioStatus } from '../functions/get-radio-status'


function CreateRadioStatus() {
  // console.log('CreateRadioStatus')
  const [RADIO_VALUE, setRadioStatus] = useState([]);
  const RADIO_STATUS = radioStatus()
  const [isMousePressed, setIsMousePressed] = useState(false);

  

  useEffect(() => {
    RADIO_STATUS.setRadioStatus('work', setRadioStatus);
    localStorage.setItem('RADIO_STATUS', RADIO_STATUS.getRadioStatus());
  }, []);

  return (
    <div className="col mt-4">
      <div className="container-fluid timetable_column">
        <div className="form-check form-switch" onMouseOver={() => setIsMousePressed(true)} onMouseOut={() => setIsMousePressed(false)}>
          <input
            className="form-check-input "
            type="radio" name="flexRadioDefault" id="work"
            checked={'work' === RADIO_VALUE ? true : false}
            onChange={() => {
              RADIO_STATUS.setRadioStatus('work', setRadioStatus);
              localStorage.setItem('RADIO_STATUS', RADIO_STATUS.getRadioStatus());
            }}
          /><label
            className="btn padding-btn-0 text-light form-check-label bg-primary rounded-pill w-75px text-center"
            htmlFor="work">Смена
          </label>
        </div>
        <div className="form-check form-switch" onMouseOver={() => setIsMousePressed(true)} onMouseOut={() => setIsMousePressed(false)}>
          <input className="form-check-input " type="radio" name="flexRadioDefault" id="lunch"
            checked={'lunch' === RADIO_VALUE ? true : false}
            onChange={() => {
              RADIO_STATUS.setRadioStatus('lunch', setRadioStatus);
              localStorage.setItem('RADIO_STATUS', RADIO_STATUS.getRadioStatus());
            }}
          /><label
            className="btn padding-btn-0 text-light form-check-label bg-success rounded-pill w-75px text-center"
            htmlFor="lunch">Обед
          </label>
        </div>
        <div className="form-check form-switch" onMouseOver={() => setIsMousePressed(true)} onMouseOut={() => setIsMousePressed(false)}>
          <input className="form-check-input " type="radio" name="flexRadioDefault" id="quest"
            checked={'quest' === RADIO_VALUE ? true : false}
            onChange={() => {
              RADIO_STATUS.setRadioStatus('quest', setRadioStatus);
              localStorage.setItem('RADIO_STATUS', RADIO_STATUS.getRadioStatus());
            }}
          /><label className="btn padding-btn-0 text-light form-check-label bg-warning rounded-pill w-75px text-center"
            htmlFor="quest">Квест
          </label>
        </div>

        <br></br>

        <div className="form-check form-switch" onMouseOver={() => setIsMousePressed(true)} onMouseOut={() => setIsMousePressed(false)}>
          <input className="form-check-input " type="radio" name="flexRadioDefault" id="addihelp"
            checked={'addihelp' === RADIO_VALUE ? true : false}
            onChange={() => {
              RADIO_STATUS.setRadioStatus('addihelp', setRadioStatus);
              localStorage.setItem('RADIO_STATUS', RADIO_STATUS.getRadioStatus());
            }}
          /><label className="btn padding-btn-0 text-light form-check-label bg-addihelp rounded-pill w-75px text-center"
            htmlFor="addihelp">В боксе
          </label>
        </div>
        <div className="form-check form-switch" onMouseOver={() => setIsMousePressed(true)} onMouseOut={() => setIsMousePressed(false)}>
          <input className="form-check-input " type="radio" name="flexRadioDefault" id="meeting"
            checked={'meeting' === RADIO_VALUE ? true : false}
            onChange={() => {
              RADIO_STATUS.setRadioStatus('meeting', setRadioStatus);
              localStorage.setItem('RADIO_STATUS', RADIO_STATUS.getRadioStatus());
            }}
          /><label className="btn padding-btn-0 text-light form-check-label bg-meeting rounded-pill w-75px text-center"
            htmlFor="meeting">Встреча
          </label>
        </div>
        <div className="form-check form-switch" onMouseOver={() => setIsMousePressed(true)} onMouseOut={() => setIsMousePressed(false)}>
          <input className="form-check-input " type="radio" name="flexRadioDefault" id="training"
            checked={'training' === RADIO_VALUE ? true : false}
            onChange={() => {
              RADIO_STATUS.setRadioStatus('training', setRadioStatus);
              localStorage.setItem('RADIO_STATUS', RADIO_STATUS.getRadioStatus());
            }}
          /><label className="btn padding-btn-0 text-light form-check-label bg-training rounded-pill w-75px text-center"
            htmlFor="training">Тренинг
          </label>
        </div>
        <br></br>

        <br></br>

        <div className="form-check form-switch" onMouseOver={() => setIsMousePressed(true)} onMouseOut={() => setIsMousePressed(false)}>
          <input className="form-check-input " type="radio" name="flexRadioDefault" id="incBox"
            checked={'incBox' === RADIO_VALUE ? true : false}
            onChange={() => {
              RADIO_STATUS.setRadioStatus('incBox', setRadioStatus);
              localStorage.setItem('RADIO_STATUS', RADIO_STATUS.getRadioStatus());
            }}
          /><label className="btn padding-btn-0 text-light form-check-label bg-incBox rounded-pill w-75px text-center"
            htmlFor="incBox">Задачи
          </label>
        </div>
        <div className="form-check form-switch" onMouseOver={() => setIsMousePressed(true)} onMouseOut={() => setIsMousePressed(false)}>
          <input className="form-check-input " type="radio" name="flexRadioDefault" id="incChats"
            checked={'incChats' === RADIO_VALUE ? true : false}
            onChange={() => {
              RADIO_STATUS.setRadioStatus('incChats', setRadioStatus);
              localStorage.setItem('RADIO_STATUS', RADIO_STATUS.getRadioStatus());
            }}
          /><label className="btn padding-btn-0 text-light form-check-label bg-incChats rounded-pill w-75px text-center"
            htmlFor="incChats">Чаты
          </label>
        </div>
        <div className="form-check form-switch" onMouseOver={() => setIsMousePressed(true)} onMouseOut={() => setIsMousePressed(false)}>
          <input className="form-check-input " type="radio" name="flexRadioDefault" id="incCommon"
            checked={'incCommon' === RADIO_VALUE ? true : false}
            onChange={() => {
              RADIO_STATUS.setRadioStatus('incCommon', setRadioStatus);
              localStorage.setItem('RADIO_STATUS', RADIO_STATUS.getRadioStatus());
            }}
          /><label className="btn padding-btn-0 text-light form-check-label bg-incCommon rounded-pill w-75px text-center"
            htmlFor="incCommon">Скопом
          </label>
        </div>
        
        <br></br>

      </div>
    </div>
  )
}

export { CreateRadioStatus }