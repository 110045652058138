const columns = {
    first: [
        {
            data: 'category'
        },
        {
            data: 'subcategory'
        },
        {
            data: 'author'
        },
        {
            data: 'text'
        },
        {
            data: 'event_id'
        },
        {
            data: 'date'
        },
    ],

}

export { columns }