const columns = {
    first: [
        {
            data: 'weekName'
        },
        {
            data: 'eventAndSlotCnt'
        },
        {
            data: 'percent'
        }
    ],
    two: [
        {
            data: 'userName'
        },
        {
            data: 'assignEventsCnt'
        }
    ],
    three: [
        {
            data: 'userName'
        },
        {
            data: 'assignTime'
        }
    ],
    four: [
        {
            data: 'userName'
        },
        {
            data: 'cnt-assign-in-time-and-late'
        },
        {
            data: 'personal-cnt-assign-in-time-and-late'
        }
    ],
    five: [
        {
            data: 'eventsList'
        },
        {
            data: 'cnt-and-procent'
        },
        {
            data: 'filling-date'
        }
    ],
    six: [
        {
            data: 'id'
        },
        {
            data: 'text'
        },
        {
            data: 'assign_name'
        },
        {
            data: 'time_ivent'
        },
        {
            data: 'time_assign'
        },
        {
            data: 'speed_assign'
        },
        {
            data: 'created_name'
        },
        {
            data: 'created_date'
        }
    ],
    seven: [
        {
            data: 'totalEvent'
        },
        {
            data: 'totalAssignEvents'
        },
        {
            data: 'percent'
        }
    ]

}

export { columns }