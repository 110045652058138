import { api } from '../../../service/api-url';

const ping = async (xhr) => {
    // try {
    //     const url = `${api()}/api/ping/`;
    //     let getResult = await fetch(url, {
    //         "body": null,
    //         "method": "GET",
    //         "credentials": "include"
    //     })
    //     const result = await getResult
    //     return result
    // }
    // catch (e) {
    //     console.error(e.message)
    // }

    xhr.open('GET', 'https://cache-nnov06.cdn.yandex.net/internetometr.download.cdn.yandex.net/bigfile.png?1653844011494');
    // xhr.open('GET', 'https://api.datsy-test.ru/downloads/279276912.png');
    // xhr.open('GET', 'https://api.datsy-test.ru/savefile/279276912.png');
    // xhr.open('GET', 'https://api.datsy-test.ru/savefile/save-file/audio.mp3');
    xhr.send();
}

const sendFile = async (xhr, file) => {
    let formData = new FormData();
    xhr.open("POST", 'https://cache-nnov06.cdn.yandex.net/upload');
    formData.append("image", file, 'image.png');
    // xhr.open("POST", 'https://api.datsy-test.ru/upload');
    xhr.send(formData);

}

export { ping, sendFile }