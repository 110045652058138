function CheckValid(text) {
    let urlEvent = text
    const taskId = urlEvent.split('/task/').splice(1, 1).join('').match(/[0-9]+/);
    const userID = urlEvent.split('/persons/').splice(1, 1).join('').match(/[0-9]+/);

    if (taskId !== null || userID !== null ) {
       return true
    }
    else {
        return false
    }
}

export { CheckValid }