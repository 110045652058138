import $ from "jquery";

import { useState, useEffect, useRef } from 'react';
import { getReports } from './functions/get-reports';

import { getDateSlot, getDateForamteSlot, getBacktDate, setDateSlot, creatNowDateFormate, setDateForamteSlot } from '../../hooks/date-time'

// import { createDatepickerButton } from './functions/button'
import createDatePicker from '../../hooks/date-picker/datapicker.general.conf'

import { CreateReports } from './element/reports-block'
import { updateTable } from './functions/create-table'
// import Test from './element/test'

export default function App() {

    const [REPORTS, setReports] = useState([])
    const ID = useRef()
    const TEXT = useRef()
    const AUTHOR = useRef()
    const START = useRef()
    const END = useRef()
    const REPORTS_BLOCK = useRef()

    const AL_SEARCH_REQUEST = { START, END }

    const updateReports = async (AL_SEARCH_REQUEST) => {
        const resultRep = await getReports({ START, END })
        // setReports(resultRep)
        // updateTable(resultRep)
        return resultRep
    }

    useEffect(() => {
        console.log('updateSchedule')
        $('.datep').datepicker("destroy");
        START.current = getBacktDate()
        END.current = getDateSlot()
        createDatePicker({ START, END })

        const fetchData = async ()=>{
            const result = await updateReports(AL_SEARCH_REQUEST)
            REPORTS_BLOCK.current = <CreateReports REPORTS={result} />
            setReports(result)
        }
        fetchData()
        return () => {
            // console.log('setDateSlot')
            setDateSlot('')
            setDateForamteSlot('')
        }
    }, [])

    return (
        <div className="container  mw-100">

            <div className="d-flex flex-row justify-content-center">

                <div>
                    <legend>Промежуток</legend>
                    <div className="input-group mb-3 w-620px">
                        <input type="text" id="datepStart" form="logs" name="begin" className="form-control"
                            defaultValue={START.current === undefined ? getBacktDate() : START.current}
                            onChange={(e) => {
                                console.log(e.target.value)
                                START.current = e.target.value
                                console.log(START.current)
                            }} />
                        <input type="text" id="datepEnd" form="logs" name="begin" className="form-control"
                            defaultValue={END.current === undefined ? getDateSlot() : END.current}
                            onChange={(e) => {
                                console.log(e.target.value)
                                END.current = e.target.value
                            }} />
                    </div>
                    <button className="btn btn-success" form="logs" name="logsearch" type="sumbit"
                        onClick={async () => {
                            // BUTTON.goDate(AL_SEARCH_REQUEST)
                            // dataTableUpdate()
                            const result = await updateReports(AL_SEARCH_REQUEST)
                            updateTable(result)
                            // console.log(AL_SEARCH_REQUEST)
                        }}>
                        <i className="fa fa-arrow-right"></i> Посмотреть в браузере</button>
                </div>

                <div className="col-lg-4 ps-5">
                    <div className="form-group">
                        <p>Как работает:</p>
                        <div>
                            <ul>
                                <li>при выгрузке по <span className="text-success">Всем</span> сотрудникам, получаем все события, в
                                    том числе невзятые никем</li>
                                <li>при выгрузке по <span className="text-success">конкретному</span> - только взятые им календари и
                                    личные прозвоны</li>
                                <li>если отчет не сформировался, значит у выбранного сотрудника нет взятых ивентов за этот
                                    период</li>
                                <li>отчеты удаляются из списка в полночь</li>
                                <li>статистика работает сразу по всем</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            { REPORTS_BLOCK.current }
        </div>
    );
};