let changeButtonValue = '';

export const radioTimeChangeValue = () => {
    return {
      setRadioStatus: (status, callback)=>{
        // console.log("setRadioStatus")
        changeButtonValue = status
        callback(status)
      },  
      getRadioStatus : ()=> {
        // console.log("getRadioStatus")
        
        return changeButtonValue;
      }
    }
}