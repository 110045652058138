import $ from "jquery";

import { useState, useEffect, useRef } from 'react';
import { getLogs } from './functions/get-logs';

import { getDateSlot, getDateForamteSlot, getBacktDate, setDateSlot, creatNowDateFormate, setDateForamteSlot } from '../../hooks/date-time'

import { createDatepickerButton } from './functions/button'
import createDatePicker from '../../hooks/date-picker/datapicker.general.conf'

import { CreateLogs } from './element/logs-block'
import { updateTable } from './functions/create-table'
// import Test from './element/test'

export default function App() {

    const [LOGS, setLogs] = useState([])
    const ID = useRef()
    const TEXT = useRef()
    const AUTHOR = useRef()
    const START = useRef()
    const END = useRef()
    const LOGS_BLOCK = useRef()

    const AL_SEARCH_REQUEST = { ID, TEXT, AUTHOR, START, END }

    const updateSchedule = async (AL_SEARCH_REQUEST) => {
        const resultSh = await getLogs({ ID, TEXT, AUTHOR, START, END })
        // setLogs(resultSh)
        return resultSh
    }

    const BUTTON = createDatepickerButton(updateSchedule)

    useEffect(() => {
        console.log('updateSchedule')
        $('.datep').datepicker("destroy");
        START.current = getBacktDate()
        END.current = getDateSlot()
        createDatePicker({START, END})
        // updateSchedule(AL_SEARCH_REQUEST)

        const fetchData = async ()=>{
            const result = await updateSchedule(AL_SEARCH_REQUEST)
            LOGS_BLOCK.current = <CreateLogs LOGS={result} />
            setLogs(result)
        }
        fetchData()
        return () => {
            console.log('setDateSlot')
            setDateSlot('')
            setDateForamteSlot('')
        }
    }, [])

    return (
        <div className="container  mw-100" id="react">
            <div className="w-620px">
                <div className="d-flex flex-row">
                    <input className="w-100px form-control" placeholder="ID" type="text" form="logs" name="begin"
                        defaultValue={ID.current}
                        onChange={(e) => {
                            ID.current = e.target.value
                            console.log(ID.current)
                        }}
                    />
                    <h3 className="ms-3">OR</h3>
                    <input className="w-200px form-control ms-3" placeholder="Текст ивента" type="text" form="logs" name="begin"
                        defaultValue={TEXT.current}
                        onChange={(e) => {
                            TEXT.current = e.target.value
                            console.log(e.target.value)
                        }}
                    />
                    <h3 className="ms-3">OR</h3>
                    <input className="w-200px form-control ms-3" placeholder="Автор действия" type="text" form="logs" name="begin"
                        defaultValue={AUTHOR.current}
                        onChange={(e) => {
                            AUTHOR.current = e.target.value
                            console.log(e.target.value)
                        }}
                    />
                </div>
                <div className="float-end">
                    <button className="btn btn-secondary mt-1 d-flex flex-row-reverse bd-highlight" form="logs" name="logsearch" type="sumbit"
                    >Найти</button>
                </div>
            </div>

            <legend>Промежуток</legend>

            <div className="input-group mb-3 w-450px">
                <input type="text" form="logs" name="begin" className="form-control" id="datepStart"
                    defaultValue={START.current === undefined ? getBacktDate() : START.current}
                    onChange={(e) => {
                        console.log(e.target.value)
                        START.current = e.target.value
                        console.log(START.current)
                    }}
                />
                <input type="text" form="logs" name="begin" className="form-control ms-3" id="datepEnd"
                    defaultValue={END.current === undefined ? getDateSlot() : END.current}
                    onChange={(e) => {
                        console.log(e.target.value)
                        END.current = e.target.value
                    }}
                />
            </div>
            <button className="btn btn-success" form="logs" name="logsearch" type="sumbit"
                onClick={async() => {
                    // BUTTON.goDate(AL_SEARCH_REQUEST)
                    const result = await updateSchedule(AL_SEARCH_REQUEST)
                    updateTable(result)
                    console.log(AL_SEARCH_REQUEST)
                }}
            >
                <i className="fa fa-arrow-right"></i> Посмотреть в браузере
            </button>
            <div className="progress h-3em mt-4">
                <div className="progress-bar w-100" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                    <h2>{START.current} - {END.current}</h2>
                </div>

            </div>
            {LOGS_BLOCK.current}
            {/* <CreateLogs LOGS={LOGS} /> */}
        </div>
    );
};