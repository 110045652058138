import { useState, useEffect, useRef, useCallback } from 'react';
import { CONFIG } from '../config';
import { radioStatus } from '../functions/get-radio-status'
import { createSlider, updateSlider } from '../functions/create-slider'
import { sliderInit } from '../functions/slider-init'
import { setColor } from '../functions/set-color';
import { eventListener } from '../functions/event-listener';
import noUiSlider from 'nouislider';

function SliderRange(props) {
    // console.log("Slider Range ")

    const { schedule } = props.propSchedule
    const { weekNameMap } = props
    const { propCallback } = props
    const { propShType } = props
    const { nameTable } = props

    const saveSlider = useRef()
    const tableElementRef = useRef()


    useEffect(() => {
        saveSlider.current = {
            key: '',
            slider: undefined
        }
        console.log(nameTable)
        // console.log('updateEffect')
        tableElementRef.current = document.querySelector(`#${nameTable}`)
        updateSlider(schedule, propCallback, tableElementRef.current)

        const canvas = tableElementRef.current.querySelectorAll("#slider-round")

        // setTimeout(() => {
        canvas.forEach((weekElem, weekKey) => {
            var connect = weekElem.querySelectorAll('.noUi-connect');
            connect.forEach((statusElem, statusKey) => {
                let statusValue = '0'
                if (!!schedule[weekKey].slider.statusValue[statusKey]) {
                    statusValue = schedule[weekKey].slider.statusValue[statusKey].status
                }
                // console.log(CONFIG.class[statusValue])
                statusElem.classList.add(CONFIG.class[statusValue]);
            });
        });
        // }, 1000);
    }, [weekNameMap, JSON.stringify(schedule)]);

    return (
        weekNameMap === null
            ? <></>
            :
            schedule.map(({ slider, tableName, type }, key) => {
                const keySlider = slider.start.join('-')
                if (nameTable === "regular_schedule") {
                    let a = '2'
                }
                // console.log(weekNameMap)
                // console.log(tableName)
                // console.log(weekNameMap[tableName])
                // let test = weekNameMap[tableName].valueTable
                return (
                    <tr key={`${keySlider}-${key}`}>
                        <td
                            className={type === 'temp' ? 'bg-warning' : ''}
                            onClick={() => {
                                if (saveSlider.current.slider === undefined) {
                                    saveSlider.current.slider = schedule[key].slider
                                    saveSlider.current.key = key
                                    console.log(schedule[key].slider)
                                } else if (saveSlider.current.key !== key) {
                                    console.log(schedule[key].slider)
                                    schedule[key].slider = saveSlider.current.slider
                                    saveSlider.current.slider = undefined
                                }


                                const element = tableElementRef.current.querySelectorAll("#slider-round")[key]
                                element.noUiSlider.destroy()
                                noUiSlider.create(element, sliderInit(schedule[key].slider));
                                setColor(element, schedule[key].slider.statusValue)
                                eventListener(element, schedule[key].slider)
                            }}
                        >
                            {weekNameMap[tableName].valueTable}
                        </td>
                        <td
                            className='h-6rem'
                            onClick={(e) => {
                            }}
                        >
                            <div className="container-fluid">
                                <div className="slider-round" id="slider-round"></div>
                            </div>
                        </td>
                    </tr>
                )
            })

    )
}

export { SliderRange }