import { useEffect, useRef, useCallback, useMemo } from "react";
import freeice from "freeice";
import useStateWithCallback from "../../hooks/useStateWithCallback";
import socket from "../../socket";
import ACTIONS from "../../socket/actions";
import STUN_TURN from "../../hooks/STUN_TURN";

export const LOCAL_VIDEO = "LOCAL_VIDEO";

export default function useWebRTC(roomID) {
  function logConsole(text) {
    // console.log(text)
  }
  /* Логировать соккеты */
  useEffect(() => {
    socket.on("*", (event, data) => {
      logConsole(event);
      logConsole(data);
      if (data.iceCandidate !== undefined) {
        logConsole(data.iceCandidate.candidate);
      }
      if (data.sessionDescription !== undefined) {
        logConsole(data.sessionDescription.type);
      }
    });
  }, []);
  /* ------------------------------------- */

  /* Константы */
  const [clients, updateClients] = useStateWithCallback([]);
  const scaleScreenClients = useRef([]);
  const stunTurnRef = useRef({});
  const peerConnections = useRef({});
  const localMediaStream = useRef(null);
  const track = useRef(null);
  const peerMediaElements = useRef({
    [LOCAL_VIDEO]: null,
  });

  /* 
    Добавить новое видео-клиента в комнату
  */
  const addNewClient = useCallback((newClient, cb) => {
    updateClients((list) => {
      if (!list.includes(newClient)) {
        return [...list, newClient];
      }

      return list;
    }, cb);
  }, []);

  const getStunAndJoinRoom = async () => {
    const stunTurn_Result = await STUN_TURN();
    stunTurnRef.current = stunTurn_Result;
    socket.emit(ACTIONS.JOIN_MOBILE, { room: roomID });
  };

  useMemo(() => {
    getStunAndJoinRoom();
  }, []);

  function send(type, to, payload) {
    // console.log("sending " + type + " to " + to);

    socket.emit(ACTIONS.MESSAGE, {
      type: type,
      to: to,
      payload: payload,
    });
  }

  useEffect(() => {
    function addPeer(remoteId) {
      // send("init", remoteId, {});
      peerConnections.current[remoteId] = new Peer(
        {
          iceServers: [
            {
              url: "stun:stun.l.google.com:19302",
              urls: "stun:stun.l.google.com:19302",
              username: null,
              credential: null,
            },
          ],
        },
        stunTurnRef.current.peerConnectionConstraints
      );
      peerConnections.current[remoteId].pc.onicecandidate = function (event) {
        // console.log(event);
        if (event.candidate) {
          // console.log("Отправлено");
          send("candidate", remoteId, {
            label: event.candidate.sdpMLineIndex,
            id: event.candidate.sdpMid,
            candidate: event.candidate.candidate,
          });
        }
      };

      peerConnections.current[remoteId].pc.ontrack = ({
        streams: [remoteStream],
      }) => {
        addNewClient(remoteId, () => {
          if (peerConnections.current[remoteId].remoteVideoEl) {
            peerMediaElements.current[remoteId].srcObject = remoteStream;
          }
        });
      };
      return peerConnections.current[remoteId];
    }
    function answer(remoteId) {
      const pc = peerConnections.current[remoteId].pc;
      pc.createAnswer(function (sessionDescription) {
        pc.setLocalDescription(sessionDescription);
        send("answer", remoteId, sessionDescription);
      }, error);
    }
    async function offer(remoteId) {
      const pc = peerConnections.current[remoteId].pc;
      const mediaConstraints = {
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      };
      const offer = await pc.createOffer(mediaConstraints);

      await pc.setLocalDescription(offer);
      send("offer", remoteId, offer);
    }
    function handleMessage(message) {
      var type = message.type,
        from = message.from,
        pc = (peerConnections.current[from] || addPeer(from)).pc;
      // console.log(peerConnections.current);
      // console.log("received " + type + " from " + from);

      switch (type) {
        case "init":
          // toggleLocalStream(pc);
          offer(from);
          break;
        case "offer":
          pc.setRemoteDescription(
            new RTCSessionDescription(message.payload),
            function () {},
            error
          );
          answer(from);
          break;
        case "answer":
          pc.setRemoteDescription(
            new RTCSessionDescription(message.payload),
            function () {},
            error
          );
          break;
        case "candidate":
          if (pc.remoteDescription) {
            pc.addIceCandidate(
              new RTCIceCandidate({
                sdpMLineIndex: message.payload.label,
                sdpMid: message.payload.id,
                candidate: message.payload.candidate,
              }),
              function () {},
              error
            );
          }
          break;
        case "image":
          /* Тест ивентов через скриншоты в сокете */
          var arrayBuffer = message.image;
          var bytes = new Uint8Array(arrayBuffer);
          var blob = new Blob([bytes.buffer]);

          var image = document.getElementById("streamImage");

          var reader = new FileReader();
          reader.onload = function (e) {
            image.src = e.target.result;
          };
          reader.readAsDataURL(blob);
          break;
        case "client-screen-size":
          /* Тест ивентов через скриншоты в сокете */
          scaleScreenClients.current.push({
            client: from,
            scale: message.payload
          })
          break;
        default:
          // console.log("Что-то не то");
      }
    }
    function toggleLocalStream(pc) {
      console.log(track)
      if (localMediaStream) {
        !!pc.getLocalStreams().length
          ? pc.removeStream(localMediaStream)
          : pc.addTrack(track, localMediaStream);
      }
    }

    function error(err) {
      console.log(err);
    }

    var Peer = function (pcConfig, pcConstraints) {
      this.pc = new RTCPeerConnection(pcConfig);
      this.remoteVideoEl = document.createElement("video");
      this.remoteVideoEl.controls = true;
      this.remoteVideoEl.autoplay = true;
      this.remoteVideoEl.playsInline = true;
      this.remoteVideoEl.muted = "muted";
      this.remoteVideoEl.className = "border border-light";
      this.remoteVideoEl.width = "200";
    };

    socket.on(ACTIONS.SEND_IMAGE, handleMessage);
    socket.on(ACTIONS.ADD_PEER, handleMessage);
    socket.on(ACTIONS.MESSAGE, handleMessage);

    return () => {
      socket.off(ACTIONS.ADD_PEER);
      socket.off(ACTIONS.SHARE_MESSAGE);
    };
  }, []);

  const startCapture = async function startCapture() {
    console.log("startCapwadture");
    localMediaStream.current = await navigator.mediaDevices.getDisplayMedia();
    track.current = localMediaStream.current.getTracks()[0];
    socket.emit(ACTIONS.JOIN, { room: roomID });
    // handleNewPeer()
    addNewClient(LOCAL_VIDEO, () => {
      const localVideoElement = peerMediaElements.current[LOCAL_VIDEO];

      if (localVideoElement) {
        localVideoElement.volume = 0;
        localVideoElement.srcObject = localMediaStream.current;
      }
    });
  };

  const startVideoCall = async ()=> {
    localMediaStream.current = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {
        width: 640,
        height: 480
      }
    });
    track.current = localMediaStream.current.getTracks()[0];
    socket.emit(ACTIONS.JOIN, { room: roomID });
    // handleNewPeer()
    addNewClient(LOCAL_VIDEO, () => {
      const localVideoElement = peerMediaElements.current[LOCAL_VIDEO];

      if (localVideoElement) {
        localVideoElement.volume = 0;
        localVideoElement.srcObject = localMediaStream.current;
      }
    });
  }

  useEffect(() => {
    const handleRemovePeer = ({ peerID }) => {
      logConsole("handleRemovePeer");
      if (peerConnections.current[peerID]) {
        peerConnections.current[peerID].pc.close();
      }

      delete peerConnections.current[peerID];
      delete peerMediaElements.current[peerID];

      updateClients((list) => list.filter((c) => c !== peerID));
    };

    socket.on(ACTIONS.REMOVE_PEER, handleRemovePeer);
    return () => {
      socket.off(ACTIONS.REMOVE_PEER);
    };
  }, []);

  useEffect(() => {
    // socket.emit(ACTIONS.JOIN, { room: roomID })
  }, [roomID]);

  const provideMediaRef = useCallback((id, node) => {
    peerMediaElements.current[id] = node;
  }, []);

  return {
    clients,
    startCapture,
    startVideoCall,
    provideMediaRef,
    scaleScreenClients,
    peerConnections
  };
}
