const CONFIG = {
    defaultClass: 'border-none form-check-input ',
    class: {
        'work': 'bg-primary',
        'lunch': 'bg-success',
        'quest': 'bg-warning',
        '0': 'bg-light',
        'addihelp': 'bg-addihelp',
        'meeting': 'bg-meeting',
        'training': 'bg-training',
        'incBox': 'bg-incBox',
        'incChats': 'bg-incChats',
        'incCommon': 'bg-incCommon',
        'w0rk': 'bg-primary'
    },
    radioNameStatus: 'work', // По умолчанию высталвено на work, па смене радио кнопок сработает setGender который переназначит значение 
    radioClassColor: '',
    time_list: [
        '08:00',
        '08:30',
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:00',
        '18:30',
        '19:00',
        '19:30',
        '20:00',
        '20:30',
        '21:00',
        '21:30',
        '22:00',
        '22:30',
    ],
    radio: {
        'work': true,
        'lunch': false,
        'quest': false,
        'addihelp': false,
        'meeting': false,
        'training': false,
        'incBox': false,
        'incChats': false,
        'incCommon': false,
        'w0rk': false
    },
    cheked: {
        'work': true,
        'lunch': true,
        'quest': true,
        '0': false,
        'addihelp': true,
        'meeting': true,
        'training': true,
        'incBox': true,
        'incChats': true,
        'incCommon': true,
        'w0rk': true
    },
    week: {
        1: {
            number: 1,
            description: 'Понедельник',
            valueTable: 'ПН'
        },
        2: {
            number: 2,
            description: 'Вторник',
            valueTable: 'ВТ'
        },
        3: {
            number: 3,
            description: 'Среда',
            valueTable: 'СР'
        },
        4: {
            number: 4,
            description: 'Четверг',
            valueTable: 'ЧТ'
        },
        5: {
            number: 5,
            description: 'Пятница',
            valueTable: 'ПТ'
        },
        6: {
            number: 6,
            description: 'Суббота',
            valueTable: 'СБ'
        },
        0: {
            number: 7,
            description: 'Воскресенье',
            valueTable: 'ВС'
        },
    },
    weekName: {
        '0': null,
        '5-2': {
            '1': { valueTable: 'ПН' },
            '2': { valueTable: 'ВТ' },
            '3': { valueTable: 'СР' },
            '4': { valueTable: 'ЧТ' },
            '5': { valueTable: 'ПТ' },
            '6': { valueTable: 'СБ' },
            '7': { valueTable: 'ВС' }
        },
        '2-2': {
            '1': { valueTable: 'ПН' },
            '2': { valueTable: 'ВТ' },
            '3': { valueTable: 'СР' },
            '4': { valueTable: 'ЧТ' },
            '5': { valueTable: 'ПТ' },
            '6': { valueTable: 'СБ' },
            '7': { valueTable: 'ВС' },
            '8': { valueTable: 'ПН' },
            '9': { valueTable: 'ВТ' },
            '10': { valueTable: 'СР' },
            '11': { valueTable: 'ЧТ' },
            '12': { valueTable: 'ПТ' },
            '13': { valueTable: 'СБ' },
            '14': { valueTable: 'ВС' },
            '15': { valueTable: 'ПН' },
            '16': { valueTable: 'ВТ' },
            '17': { valueTable: 'СР' },
            '18': { valueTable: 'ЧТ' },
            '19': { valueTable: 'ПТ' },
            '20': { valueTable: 'СБ' },
            '21': { valueTable: 'ВС' },
            '22': { valueTable: 'ПН' },
            '23': { valueTable: 'ВТ' },
            '24': { valueTable: 'СР' },
            '25': { valueTable: 'ЧТ' },
            '26': { valueTable: 'ПТ' },
            '27': { valueTable: 'СБ' },
            '28': { valueTable: 'ВС' },
        }
    }
}
export { CONFIG };