// import { getDateForamteSlot, getDateSlot, getBacktDate } from '../../../hooks/date-time'
// import { setUserPermission } from '../../../hooks/permission'
import $ from "jquery";
// import 'datatables.net'
import "jquery-ui-dist/jquery-ui";
import { columns } from './columns-option'

const tableList = {
    "first": {
        "name": "week-event-list-one",
        "search": false
    },
    "two": {
        "name": "users-event-list-two",
        "search": false
    },
    "three": {
        "name": "users-time-asign-three",
        "search": false
    },
    "four": {
        "name": "users-assign-minute-four",
        "search": false
    },
    "five": {
        "name": "slot-filling-five",
        "search": false
    },
    "six": {
        "name": "ivent-slot-six",
        "search": true
    },
}

const createTable = async (data) => {
    const tableArray = Object.entries(tableList)
    tableArray.forEach(element => {
        const Table = $(`#${element[0]}`)
        // Table.DataTable().destroy();
        const dataElement = data[element[1].name]
        const CreatTable = Table.DataTable({
            "order": [],
            "paging": false,
            "searching": element[1].search,
            "autoWidth": false,
            "data": dataElement,
            "columns": columns[element[0]]
        });
    })
    // Table.DataTable().destroy();
    // CreatTable
    //     .clear()
    //     .draw()
    //     .rows.add(data).draw();
}

const updateTable = (data) => {
    const tableArray = Object.entries(tableList)
    tableArray.forEach(element => {
        const Table = $(`#${element[0]}`)
        // Table.DataTable().destroy();
        const CreatTable = Table.DataTable()
        CreatTable
            .clear()
        CreatTable
            .draw()
        CreatTable
            .rows.add(data[element[1].name]).draw();
    })
}

export { createTable, updateTable }