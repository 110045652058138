import { api, shedulePath } from '../../../service/api-url';

const setRangeTypeDateSlot = async (rangeTypeDateSlot) => {
    try {
      const url = `${api()}/api/settings/set-type-schedule.php`;
      let setResult = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(rangeTypeDateSlot),
          credentials: "include"
      })
      const result = await setResult.json();
      return result;
  
    } catch (e) {
      console.error(e.message);
    }
  };
  
  export { setRangeTypeDateSlot };
  