import { getDateForButton, setDateTimeSlot, setDateSlot, getDateTimeSlot, getDateSlot, creatNowDate } from '../../../../hooks/date-time'
function createDatepickerButton(callBackDateTime, showBlockOption) {
    const Date = getDateForButton()
    return {
        goOverDate: ({ START }) => {
            callBackDateTime()
        },

        previousDate: async ({ START }) => {
            const currentDate = Date.getLessDate(START.current)
            const { pickDate } = currentDate
            START.current = pickDate
            callBackDateTime()
        },
        todayDate: ({ START }) => {
            const currentDate = Date.nowDate()
            const { pickDate } = currentDate
            START.current = pickDate
            callBackDateTime()
            showBlockOption.showBlockOption.current["show-today"] = true
        },
        nextDate: ({ START }) => {
            const currentDate = Date.getNextDate(START.current)
            const { pickDate } = currentDate
            START.current = pickDate
            callBackDateTime()
        }
    }
}
export { createDatepickerButton }