import { useState, useEffect, useRef, useMemo } from "react";
import { CreateSelect } from "./select"
import { getDefaultTimeSlot, updateDefaultTimeSlot } from "../functions/get-and-update-default-time-slot"
import Form from "react-bootstrap/Form";

const DeafaultTimeSlot = (props)=>{
    const [TIME_SLOT_LIST, setTimeSlotList] = useState([])
    const [DEFAULT_TIME_SLOT, setDefaultTimeSlot] = useState()

    const updateTimeSlotList = async ()=>{
        const timeSlot = await getDefaultTimeSlot()
        setTimeSlotList(timeSlot["time-slot-list"])
        setDefaultTimeSlot(timeSlot.default)
    }

    // console.log(DEFAULT_TIME_SLOT)

    useEffect(() => {
        updateTimeSlotList();
      }, []);

    return(
        <div>
            <Form.Label>Время по умолчанию</Form.Label>
            <div>
                <div className="btn-group ">
                    <div className="w-100px">
                        <CreateSelect list={TIME_SLOT_LIST}  defaultValue={DEFAULT_TIME_SLOT} changeDefault={setDefaultTimeSlot} />
                    </div>
                    <div className="w-300px">
                        <button type="button" className="btn btn-success text-light " id="save_temp_shedule"
                            onClick={async () => {
                                await updateDefaultTimeSlot(DEFAULT_TIME_SLOT)
                                updateTimeSlotList()
                                props.updateTimeSlotDate()
                            }}
                        ><i className="fa fa-save me-2"></i>Сохранить</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeafaultTimeSlot