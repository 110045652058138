import { api } from '../../../service/api-url';

export default async function geticeServers(){
    let getResult = await fetch(`${api()}/api/get-stun-turn/`, {
        "method": "GET",
    })
    const result = await getResult.json();
    // console.log(result)
    return result;
}
