import $ from 'jquery'
import {setDateForamteSlot, getDateForamteSlot, getDateSlot, creatNowDate, creatNowDateFormate } from '../../../hooks/date-time'
import { UPDATE_NAV_DATE } from '../functions/date-map'

function createDatepickerButton(callback) {

    return {
        goOverDate: async () => {
            document.onmousemove = null
            let valueDataPicker = $("#datep").val()
            valueDataPicker = valueDataPicker.split('-').reverse().join('-')
            console.log(valueDataPicker)
            setDateForamteSlot(valueDataPicker)
            await callback()
            await UPDATE_NAV_DATE.callbackSchedule()
        },

        previousWeek: async () => {
            document.onmousemove = null
            let valueDataPicker = getDateForamteSlot()
            valueDataPicker = valueDataPicker.split('-').join(', ')
            let date = new Date(valueDataPicker);
            date.setDate(date.getDate() - 7);
            let dateFormate = date.toLocaleDateString('ru-Ru').split('.').join('-')
            date = date.toLocaleDateString('ru-Ru').split('.').reverse().join('-')
            $("#datep").val(dateFormate)
            setDateForamteSlot(date)
            await callback()
            await UPDATE_NAV_DATE.callbackSchedule()
        },
        todayWeek: async () => {
            document.onmousemove = null
            let date = creatNowDateFormate();
            // let dateFormate = date
            date = date.split('-').reverse().join('-')
            $("#datep").val(date)
            // creatNowDateFormate()
            await callback()
            await UPDATE_NAV_DATE.callbackSchedule()
        },
        nextWeek: async () => {
            document.onmousemove = null
            let valueDataPicker = getDateForamteSlot()
            valueDataPicker = valueDataPicker.split('-').join(', ')
            let date = new Date(valueDataPicker);
            date.setDate(date.getDate() + 7);
            let dateFormate = date.toLocaleDateString('ru-Ru').split('.').join('-')
            date = date.toLocaleDateString('ru-Ru').split('.').reverse().join('-')
            $("#datep").val(dateFormate)
            setDateForamteSlot(date)
            await callback()
            await UPDATE_NAV_DATE.callbackSchedule()
        }
    }
}

function createNavDateButton() {
    return {
        getNavDate: async (date) => {
            setDateForamteSlot(date)
        }
    }
}
export { createDatepickerButton, createNavDateButton }