const isOperatorForDuty = (dutyLust, userIdSearch)=>{
    return dutyLust.some((element)=>{
        return element.userId === userIdSearch
    })
}

const findUserForDuty = (dutyLust, userIdSearch)=>{
    return dutyLust.find((element)=>{
        return element.userId === userIdSearch
    })
}

export { isOperatorForDuty, findUserForDuty }