import { useState, useEffect, useRef } from 'react';
import $ from "jquery";
// import 'datatables.net'
import "jquery-ui-dist/jquery-ui";
import { createTable } from '../functions/create-table'

function CreateReports(props) {
    console.log('CreateReports')
    const { REPORTS } = props

    const dataTableCreate = () => {
        createTable(REPORTS)
        const test = $('[aria-controls="six"][type="search"]')
        test.addClass('form-control form-control-sm');
    }


    useEffect(() => {
        dataTableCreate()
    }, [])

    return (
        <div>
            <div className="row row-alt">

                <div className="col-lg-3">

                    <div className="card text-white bg-secondary mb-3">

                        <div className="card-header">Нагруженность слотов по дням</div>

                        <div className="card-body p-0">

                            <div id="first_wrapper" className="dataTables_wrapper no-footer">
                                <table id="first" className="table table-hover dataTable no-footer text-center">

                                    <thead>

                                        <tr>
                                            <th>День недели</th>
                                            <th>Ивентов / Слотов</th>
                                            <th>%</th>
                                        </tr>

                                    </thead>

                                </table>

                            </div>

                        </div>

                    </div>

                </div>

                <div className="col-lg-3">

                    <div className="card text-white bg-secondary mb-3">

                        <div className="card-header">Взятие ивентов</div>

                        <div className="card-body p-0">

                            <div id="two_wrapper" className="dataTables_wrapper no-footer">
                                <table id="two" className="table table-hover dataTable no-footer text-center" role="grid" aria-describedby="third_info">

                                    <thead>

                                        <tr>
                                            <th>Имя</th>
                                            <th>Ивентов</th>
                                        </tr>

                                    </thead>

                                </table>

                            </div>

                        </div>

                    </div>

                </div>

                <div className="col-lg-3">

                    <div className="card text-white bg-secondary mb-3">

                        <div className="card-header">Средняя скорость взятия ивентов (мин)</div>

                        <div className="card-body p-0">

                            <div id="three_wrapper" className="dataTables_wrapper no-footer ">
                                <table id="three" className="table table-hover dataTable no-footer text-center" role="grid" aria-describedby="five_info">

                                    <thead>

                                        <tr>
                                            <th>Имя</th>
                                            <th>Время</th>
                                        </tr>

                                    </thead>


                                </table>

                            </div>

                        </div>

                    </div>

                </div>

                <div className="col-lg-3">

                    <div className="card text-white bg-secondary mb-3 ">

                        <div className="card-header">Взято до минуты (ивенты)</div>

                        <div className="card-body p-0 ">

                            <div id="third_wrapper" className="dataTables_wrapper no-footer ">

                                <table id="four" className="table table-hover dataTable no-footer text-center" role="grid" aria-describedby="third_info">

                                    <thead>

                                        <tr>

                                            <th>Имя</th>

                                            <th>Показатель (факт.)</th>

                                            <th>Личный</th>

                                        </tr>

                                    </thead>
                                    <tbody>
                                        {/* <tr role="row" class="odd">
                                                <td>Сергиенко Антон</td>
                                                <td>0/0 (0%)</td>
                                                <td>0/0 (0%)</td>
                                            </tr> */}
                                        {/* <tr role="row" class="odd">
                                                <td>Сергиенко Антон</td>
                                                <td>0/0 (0%)</td>
                                                <td>0/0 (0%)</td>
                                            </tr><tr role="row" class="even">
                                                <td>Кошевой Николай</td>
                                                <td>0/0 (0%)</td>
                                                <td>0/0 (0%)</td>
                                            </tr><tr role="row" class="odd">
                                                <td>Камышан Артем</td>
                                                <td>0/0 (0%)</td>
                                                <td>0/0 (0%)</td>
                                            </tr>
                                            <tr role="row" class="even">
                                                <td>Дель Александр</td>
                                                <td>0/0 (0%)</td>
                                                <td>0/0 (0%)</td>
                                            </tr><tr role="row" class="odd">
                                                <td>Гусейнов Рахид</td>
                                                <td>0/0 (0%)</td>
                                                <td>0/0 (0%)</td>
                                            </tr><tr role="row" class="even">
                                                <td>Маринич Станислав</td>
                                                <td>0/0 (0%)</td>
                                                <td>0/0 (0%)</td>
                                            </tr><tr role="row" class="odd">
                                                <td>Нестер Александр</td>
                                                <td>0/0 (0%)</td>
                                                <td>0/0 (0%)</td>
                                            </tr>
                                            <tr role="row" class="even">
                                                <td>Султанов Максим</td>
                                                <td>0/0 (0%)</td>
                                                <td>0/0 (0%)</td>
                                            </tr> */}
                                    </tbody>

                                </table>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
            <div className="row row-alt">

                <div className="col-lg-4">

                    <div className="card text-white bg-secondary mb-3">

                        <div className="card-header">Время заполнения слотов(минуты)</div>

                        <div className="card-body p-0">

                            <div id="five_wrapper" className="dataTables_wrapper no-footer">

                                <table id="five" className="table table-hover dataTable no-footer text-center" role="grid" aria-describedby="third_info">

                                    <thead>

                                        <tr>

                                            <th>Время слота</th>

                                            <th>Ивентов/слотов(%)</th>

                                            <th>Время заполнения</th>

                                        </tr>

                                    </thead>

                                </table>

                            </div>

                        </div>

                    </div>

                </div>
                
            </div>
            <div className="row row-alt">

            </div>

            <div className="progress progress h-3em mt-4" >

                <div className="progress-bar w-100 " role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>

            </div>
            <div className="row row-alt p-2">

                <div className="col-lg-12">

                    <div className="card text-white bg-secondary mb-3">

                        <div className="card-body p-0">

                            <div id="six_wrapper" className="dataTables_wrapper no-footer">

                                <table id="six" className="table table-hover dataTable no-footer text-center" role="grid" aria-describedby="third_info">

                                    <thead>

                                        <tr>

                                            <th>ID события</th>

                                            <th className="w-620px">Текст</th>

                                            <th>Обработал</th>

                                            <th>Время ивента</th>

                                            <th>Время взятия</th>

                                            <th>Взято через</th>

                                            <th>Создатель</th>

                                            <th>Когда создал</th>

                                        </tr>

                                    </thead>

                                </table>

                            </div>

                        </div>

                    </div>

                </div>


            </div>
        </div>
    )
}



export { CreateReports }