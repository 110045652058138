import { api, shedulePath } from '../../../service/api-url';

const getDefaultTimeSlot = async () => {
    try {
      const url = `${api()}/api/settings/get-default-time-slot.php`;
      let setResult = await fetch(url, {
          method: 'GET',
          credentials: "include"
      })
      const result = await setResult.json();
      return result;
  
    } catch (e) {
      console.error(e.message);
    }
  };

const updateDefaultTimeSlot = async (timeSlot) => {
    const searchBody = {
        'time-slot': timeSlot
    }
    try {
      const url = `${api()}/api/settings/update-default-time-slot.php`;
      let setResult = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(searchBody),
          credentials: "include"
      })
      const result = await setResult.json();
      return result;
  
    } catch (e) {
      console.error(e.message);
    }
  };
  
  export { getDefaultTimeSlot, updateDefaultTimeSlot };
  