import { getDateSlot, getDateForamteSlot } from '../../../hooks/date-time'
import { api } from '../../../service/api-url';

const getUserInfo = async (userId) => {
    // console.log('getScheduleReg')
    try {
        
        const arrayResult = {
            schedule: {},
            // reservation: {}
        }
        const url = `${api()}/api/users/user-info.php?user=${userId}`;
        let setResult = await fetch(url, {
            method: 'GET',
            // headers: {
            //     "content-type": "application/json; charset=UTF-8"
            // },
            credentials: "include"
        })
        const result = await setResult.json();
        Object.keys(result).map((userBody, key) => {
            arrayResult.schedule[userBody] = result[userBody]
        })
        return result
    }
    catch (e) {
        console.error(e.message)
    }
}

const userUserInfo = async (userId, data) => {
    // console.log('getScheduleReg')
    try {
        const url = `${api()}/api/users/update-user.php?edit=${userId}`;
        let setResult = await fetch(url, {
            method: 'POST',
            // headers: {
            //     "content-type": "application/json; charset=UTF-8"
            // },
            body: JSON.stringify(data),
            credentials: "include"
        })
        const result = await setResult.json();
        return result
    }
    catch (e) {
        console.error(e.message)
    }
}
export { getUserInfo, userUserInfo }