import socket from '../../../socket';
import ACTIONS from '../../../socket/actions';



const sendUA = (roomID) => {
    const myUA = window.navigator.userAgent
    socket.on(ACTIONS.MESSAGE, ({ type, value }) => {
        if (type === ACTIONS.GET_USER_AGENT) {
            socket.emit(ACTIONS.MESSAGE, {
                type: ACTIONS.SEND_USER_AGENT,
                value: myUA
            });
        }
    });
    socket.emit(ACTIONS.JOIN_ROOM_USER, {
        room: roomID,
    });
    socket.emit(ACTIONS.MESSAGE, {
        type: ACTIONS.SEND_USER_AGENT,
        value: myUA
    });
}

export { sendUA }