import { ping, sendFile } from './function/download'
import { useState, useEffect, useRef, useMemo, useCallback, createContext } from 'react';
import { Button, Form, FormCheck, Row, Col, InputGroup, Jumbotron, FormGroup, FormControl } from 'react-bootstrap';
import { calculate } from './function/speed-calculate'

export default function Room() {
  const [SPEED_D, updateDownload] = useState(0)
  const [SPEED_U, updateUpload] = useState(0)
  const speedTestStart = async () => {

    const xhr = new XMLHttpRequest();
    const started = new Date().getTime();
    let textResp;
    xhr.timeout = 3000;

    xhr.ontimeout = function (e) {
      console.log('ontimeout')
      const buffer = createBufferUpload()
      const blob = new Blob([buffer], { type: "image/png" });
      const file = new File([blob], 'image.png')
      startSend(file)
      console.log(file.size)
    };

    xhr.onload = function (event) {
      console.log('onload ')
      let responseObj = xhr.response;
      const file = new File([responseObj], 'image.png')
      console.log(file)
    }

    xhr.onprogress = function (event) {
      console.log('onprogress')
      if (event.lengthComputable) {

        textResp = event
        const { speedVale, milliseconds } = calculate(event, started)
        console.log(`Сколько реально принято: ${event.loaded}`)
        console.log(`До сколько сжато: ${textResp.size} \n\n`)
        updateDownload(speedVale);
      }
    };
    await ping(xhr)
  }

  const startSend = async (fileTest) => {
    const xhr = new XMLHttpRequest();
    const started = new Date().getTime();
    xhr.upload.onprogress = function (event) {
      if (event.lengthComputable) {
        const { speedVale, milliseconds } = calculate(event, started)
        updateUpload(speedVale)
      } else {
        console.log(`Отправлено ${event.loaded} байт`); // если в ответе нет заголовка Content-Length
      }
    }
    await sendFile(xhr, fileTest)
  }


  function createBufferUpload() {
    const valueBite = 209715200
    var buf = new ArrayBuffer(valueBite);
    return buf;

  }

  return (
    <div>
      Ping
      <button className="btn btn-success text-light me-1"
        onClick={speedTestStart}
      >Начать</button>

      <div>{`Download ${SPEED_D} Mbs\\s`}</div>
      <div>{`Upload ${SPEED_U} Mbs\\s`}</div>

      {/* <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Default file input example</Form.Label>
        <Form.Control type="file" />
      </Form.Group>

      <button className="btn btn-success text-light me-1"

      >Отправить</button> */}
    </div>


  );
}