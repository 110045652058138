import { getDateTimeSlot, getDateSlot, getTimeForButton } from '../../../hooks/date-time'
import { useEventButton } from '../function/button'
import { useState, useEffect, useRef, useMemo } from 'react';
import { Button, Form, FormCheck, Col, InputGroup, Jumbotron, FormGroup, FormControl } from 'react-bootstrap';
import { CheckValid } from '../function/check-valid'

export default function CreateSlot(props) {
    // console.log("Render CreateSlot ------------------------------")
    const TEXT = useState();
    const [TEXT_SLOT, setTextSlot] = useState("")
    // const slotCountRef = useRef()
    const testCallback = props.callback
    const BUTTON = useEventButton(testCallback, props.date)

    // useMemo(() => {
    //     setSlotCount(props.text)
    // }, [])

    const [validated, setValidated] = useState(false);
    const [validUrl, setValidUrl] = useState(false);
    // console.log(validUrl)
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        // console.log(form)
        console.log(validated)
        console.log(validUrl)
        if (validUrl === true) {
            console.log(TEXT_SLOT)
            console.log(props.text)
            const status = BUTTON.create(TEXT_SLOT, props.time)
            props.removeSlotValue(props.valueKey, false)
            // userUserInfo(userId, inputRef.current)
            // updateSchedule()
        } else {
            setValidated(true);
        }
        event.preventDefault();
        event.stopPropagation();
    };
    const keyBlock = `${props.time}${props.dateTime}`.split(':').join('')
    return (
        <Form noValidate validated={validated === true
            ? validUrl === true
                ? true
                : false
            : false
        } onSubmit={handleSubmit} className="" key={keyBlock}>
            <div className="input-group" key={keyBlock}>
                <div className="col-md-8">
                    <Form.Group controlId="validationCustom02">
                        <Form.Control
                            // className={`text-light form-control border-secondary block-slot ${!!validUrl ? 'is-invalid' : ''}`} 
                            className={`text-light form-control border-secondary block-slot`}
                            required
                            type="text"
                            value={TEXT_SLOT}
                            isInvalid={validated === true
                                ? validUrl === false
                                    ? true : false
                                : false}

                            onChange={(e) => {
                                // TEXT.current = e.target.value
                                setTextSlot(e.target.value)
                                const validValue = CheckValid(e.target.value)
                                setValidUrl(validValue)
                                props.updateText(props.valueKey, e.target.value)

                            }}
                        />
                        <Form.Control.Feedback >Отлично!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            Пожалуйста, укажите ссылку на пользователя или задачу.
                        </Form.Control.Feedback>

                    </Form.Group>
                </div>
                <div className="col-md-4">
                    <button className="btn btn-info w-100" type="submit" name="slotname"
                    ><i className="fa fa-plus"></i></button>
                </div>
            </div>
        </Form>

    )
    //     })
    // )
}