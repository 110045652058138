import { useParams } from 'react-router';
import { useState, useEffect, useRef } from 'react';
import { CreateSelect } from './element/select'
import { getUserInfo } from './function/get-user-info'
import { createNewUser } from './function/create-new-user'
import { getDateSlot, getDateForamteSlot, getBacktDate, setDateSlot, creatNowDateFormate, setDateForamteSlot } from '../../hooks/date-time'

import createDatePicker from '../../hooks/date-picker/datapicker.general.conf'

import { Button, Form, FormCheck, Row, Col, InputGroup, Jumbotron, FormGroup, FormControl } from 'react-bootstrap';

export default function App() {
    const [USER_INFO, setUserInfo] = useState([])
    const [SCHEDULE_REG, updateScheduleReg] = useState([])
    const START = useRef()
    const END = useRef()
    console.log(USER_INFO)

    const inputRef = useRef()

    useEffect(() => {
        START.current = getDateSlot()
        END.current = getDateSlot()
        inputRef.current = {
            'name': { 'value': '' },
            'login': { 'value': '' },
            'password': { 'value': '' },
            'slackId': { 'value': '' },
            'role': { 'value': '' },
            'group': { 'value': '' },
            'shType': { 'value': '' },
            'alterAnyEvent': { 'value': 'false' },
            'asignEvent': { 'value': 'false' },
            'addEvent': { 'value': 'false' },
            'startWork': { 'value': '' },
        }
        const fetchUserInfo = async () => {
            const result = await getUserInfo()
            setUserInfo(result)
            createDatePicker({ START, END })
        }
        fetchUserInfo()
    }, [])

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        console.log(form)
        if (form.checkValidity() === true) {
            createNewUser(inputRef.current)
        }
        event.preventDefault();
        event.stopPropagation();

        setValidated(true);
    };
    console.log(validated)
    const test = true
    return (
        (USER_INFO.length === 0) ? <div>Загрузка...</div> :
            <div>
                <div className="container-fluid">

                    <Form noValidate validated={validated} onSubmit={handleSubmit} className="row g-3 needs-validation">
                            <div className="col-md-4">
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>Имя</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Имя"
                                        defaultValue=""
                                        onChange={(e) => {
                                            inputRef.current.name.value = e.target.value
                                            console.log(e.target.value)
                                        }}
                                    />
                                    <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Пожалуйста, заполните окно.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="validationCustom02">
                                    <Form.Label>Логин</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Логин"
                                        defaultValue=""
                                        onChange={(e) => {
                                            inputRef.current.login.value = e.target.value
                                            console.log(e.target.value)
                                        }}
                                    />
                                    <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Пожалуйста, заполните окно.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="validationCustom03">
                                    <Form.Label>Пароль? (генератор есть
                                        <a href="https://www.lastpass.com/ru/password-generator" target="_blank" rel="noreferrer">тут</a> , например)</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="*********"
                                        defaultValue=""
                                        onChange={(e) => {
                                            inputRef.current.password.value = e.target.value
                                            console.log(e.target.value)
                                        }}
                                    />
                                    <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Пожалуйста, заполните окно.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="validationCustom04">
                                    <Form.Label>Slack ID</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="slack id"
                                        defaultValue=""
                                        onChange={(e) => {
                                            inputRef.current.slackId.value = e.target.value
                                            console.log(e.target.value)
                                        }}
                                    />
                                    <Form.Control.Feedback>Отлично!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Пожалуйста, заполните окно.
                                    </Form.Control.Feedback>

                                </Form.Group>
                            </div>

                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                            <Form.Label>Роль</Form.Label>
                            {CreateSelect(USER_INFO.roles, inputRef.current.role)}

                            <Form.Label>Группа</Form.Label>
                            {CreateSelect(USER_INFO.groups, inputRef.current.group)}

                            <Form.Label>Тип Календаря</Form.Label>
                            {CreateSelect(USER_INFO["scheduele-type"], inputRef.current.shType)}

                            <Form.Check>
                                <FormCheck.Input

                                    bsPrefix="form-check-input"
                                    onChange={(e) => {
                                        const { alterAnyEvent } = inputRef.current
                                        alterAnyEvent.value === "true" ? alterAnyEvent.value = "false" : alterAnyEvent.value = "true"
                                        console.log(e)
                                    }}
                                ></FormCheck.Input>
                                <FormCheck.Label
                                    bsPrefix="custom-control-label-primary text-light"
                                >Разрешить изменять чужие ивенты?</FormCheck.Label>
                            </Form.Check>
                            <Form.Check>
                                <FormCheck.Input
                                    bsPrefix="form-check-input"
                                    onChange={(e) => {
                                        const { asignEvent } = inputRef.current
                                        asignEvent.value === "true" ? asignEvent.value = "false" : asignEvent.value = "true"
                                        console.log(e.target.value)
                                    }}
                                ></FormCheck.Input>
                                <FormCheck.Label
                                    bsPrefix="custom-control-label text-light"
                                >Может асайнить ивенты?</FormCheck.Label>
                            </Form.Check>
                            <Form.Check>
                                <FormCheck.Input
                                    bsPrefix="form-check-input"
                                    onChange={(e) => {
                                        const { addEvent } = inputRef.current
                                        addEvent.value === "true" ? addEvent.value = "false" : addEvent.value = "true"
                                        console.log(e.target.value)
                                    }}
                                ></FormCheck.Input>
                                <FormCheck.Label
                                    bsPrefix="custom-control-label text-light"
                                >Может добавлять ивенты?</FormCheck.Label>
                            </Form.Check>
                        </Form.Group>



                        <Form.Group as={Col} md="4" controlId="datep" >
                            <Form.Label>Первая дата</Form.Label>
                            <div className="input-group mb-3 w-200px">
                                <Form.Control
                                    type="text"
                                    // id='datep'
                                    // controlid='datep'
                                    aria-describedby="inputGroupPrepend"

                                    defaultValue={START.current}
                                    className="input-group w-50"
                                />
                            </div>

                            <Form.Control.Feedback type="invalid">
                                Please choose a username.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <div className="container-fluid mt-5">
                            <div className="row">
                                <div className="col-4">
                                    <Button type="submit" variant="success">Добавить</Button>
                                    <Button type="button" variant="success"
                                        onClick={() => {
                                            console.log(inputRef.current)
                                        }
                                        }
                                    >Тест</Button>
                                    <Button className="ms-2" type="submit" href="https://app.datsy-test.ru/users" variant="danger">Отмена</Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div >
    );
};