import { useState, useEffect, useRef, useMemo, useCallback, createContext } from 'react';


const WebRTCTest = ()=>{
    const [pc, updatePc] = useState(null)
    const [ws, updateWs] = useState(null)

    const WEBSOCKET_URL = "ws://127.0.0.1:8081/"
    // var pc, ws;
    const STUN_URL = "stun:stun.sipsorcery.com";
    console.log('WebRTCTest')
    
        
    async function start() {
        if (ws != null) await ws.close();
        if (pc != null) await pc.close();

        // pc = new RTCPeerConnection({ iceServers: [{ urls: STUN_URL }] });

        pc.ontrack = evt => document.querySelector('#videoCtl').srcObject = evt.streams[0];
        pc.onicecandidate = evt => evt.candidate && ws.send(JSON.stringify(evt.candidate));

        // Diagnostics.
        pc.onicegatheringstatechange = () => console.log("onicegatheringstatechange: " + pc.iceGatheringState);
        pc.oniceconnectionstatechange = () => console.log("oniceconnectionstatechange: " + pc.iceConnectionState);
        pc.onsignalingstatechange = () => console.log("onsignalingstatechange: " + pc.signalingState);
        pc.onconnectionstatechange = () => console.log("onconnectionstatechange: " + pc.connectionState);

        // ws = new WebSocket(document.querySelector('#websockurl').value, []);
        ws.onmessage = async function (evt) {
            if (/^[\{"'\s]*candidate/.test(evt.data)) {
                pc.addIceCandidate(JSON.parse(evt.data));
            }
            else {
                await pc.setRemoteDescription(new RTCSessionDescription(JSON.parse(evt.data)));
                console.log("remote sdp:\n" + pc.remoteDescription.sdp);
                pc.createAnswer()
                    .then((answer) => pc.setLocalDescription(answer))
                    .then(() => ws.send(JSON.stringify(pc.localDescription)));
            }
        };
    };

    async function closePeer() {
        await pc?.close();
        await ws?.close();
    };

    useEffect(() => {

        updatePc(new RTCPeerConnection({ iceServers: [{ urls: STUN_URL }] }))
        updateWs(new WebSocket(document.querySelector('#websockurl').value, []))

        // document.querySelector('#websockurl').value = WEBSOCKET_URL;
    }, [])
    return(
        <div>
            <video controls autoPlay="autoplay" id="videoCtl" width="640" height="480"></video>

            <div>
                <input type="text" id="websockurl" size="40" defaultValue={WEBSOCKET_URL} />
                <button type="button" className="btn btn-success" onClick={start}>Start</button>
                <button type="button" className="btn btn-success" onClick={closePeer}>Close</button>
            </div>
        </div>
    )
}

export default WebRTCTest