import noUiSlider from 'nouislider';
import { eventListener } from './event-listener';
import { sliderParamCreate_New } from './slider-lib'
import { sliderInit } from './slider-init'
import { setColor } from './set-color';

const createSlidElement = (element, sliderParam, data, updateShedule, keySlider, sliderDelete) => {
    noUiSlider.create(element, sliderInit(sliderParam));
    /* 
     Ивенты нажатия на слайдер  
    */
    eventListener(element, sliderParam)
    // element.noUiSlider.on('update', doSomething);
    /*  
    -----------------------------------------------  
    */
    function doSomething(values, handle, unencoded, tap, positions) {
        console.log(handle)
        if (sliderParam.statusValue.length > 1) {
            sliderParam.statusValue[handle].end = unencoded[handle]
            sliderParam.statusValue[handle + 1].start = unencoded[handle]

            const value = sliderParamCreate_New(sliderParam.statusValue)
            sliderParam.start = value.start
            sliderParam.connect = value.connect
            sliderParam.tooltip = value.tooltip
            sliderParam.statusValue = value.time_List

            element.noUiSlider.destroy()
            noUiSlider.create(element, sliderInit(sliderParam));
            setColor(element, sliderParam.statusValue)
            eventListener(element, sliderParam)
        }
    }

    element.noUiSlider.on('change', doSomething);
    // element.noUiSlider.on('start', doSomething);
    // element.noUiSlider.on('slide', doSomething);
    // element.noUiSlider.on('drag', doSomething);
    // element.noUiSlider.on('update', doSomething);
    // element.noUiSlider.on('change.one', doSomething);
    // element.noUiSlider.on('set', doSomething);
    // element.noUiSlider.on('end', doSomething);
    // element.noUiSlider.on('set.one', doSomething);
    // element.noUiSlider.on('.one', doSomething);


    // 

}


const updateSlider = async (data, updateShedule, tableElement) => {
    // console.log('update Slider ')
    const entryData = Object.entries(data)
    // const [date, body] = entryData
    const sliderDelete = tableElement.querySelectorAll("#slider-round")
    // console.log(sliderDelte)
    data.forEach(({ slider, tableName }, keySlider) => {
        // const [date, body] = entryData[key]
        if (sliderDelete[keySlider].children.length !== 0) {
            sliderDelete[keySlider].noUiSlider.destroy()
        }
        // const sliderParam = sliderParamCreate(body.timeStatus)

        createSlidElement(sliderDelete[keySlider], slider, data, updateShedule, keySlider, sliderDelete)
    });
}

const deleteSlider = async (data) => {
    // console.log('update Slider ')
    const sliderDelte = document.querySelectorAll("#slider-round")
    // console.log(sliderDelte)
    sliderDelte.forEach(slider => {
        // console.log(slider)
        if (slider.children.length !== 0) {
            slider.noUiSlider.destroy()
        }
    });
}

export { updateSlider, deleteSlider }


/* Old logic */

    // const touchUi = element.querySelector('.noUi-touch-area')
    // touchUi.addEventListener('mousemove', (e) => {
    //     console.log(sliderParam.start)
    //     const statusRadio_Pick = radioStatus().getRadioStatus()
    //     const changeValue = radioTimeChangeValue().getRadioStatus()
    //     const clickElement = touchUi
    //     const stepValue = step()
    //     const proposal = calcPointToPercentage(e.clientX, clickElement)
    //     const { handle, handle_Step, start, end } = closest(proposal, stepValue)
    //     // console.log(handle, handle_Step, start, end)
    // })

   // const eventListener = () => {
    //     const canvas = element.querySelector('.noUi-base')
    //     canvas.addEventListener('mousedown', (e) => {
    //         // console.log(sliderParam.start)
    //         const statusRadio_Pick = radioStatus().getRadioStatus()
    //         const changeValue = radioTimeChangeValue().getRadioStatus()
    //         const clickElement = canvas
    //         const stepValue = step()
    //         const proposal = calcPointToPercentage(e.clientX, clickElement)
    //         const { handle, handle_Step, start, end } = closest(proposal, stepValue)
    //         const handleNumber = getClosestHandle(handle, sliderParam.start)
    //         const handleNumberStep = getClosestHandle(handle_Step, sliderParam.start)

    //         if (changeValue === 'add') {

    //             const test = sliderParam
    //             console.log(test.connect)
    //             if (sliderParam.start.length === 1) {
    //                 sliderParam.start = []
    //                 sliderParam.connect = [false]
    //                 sliderParam.tooltip = []
    //             }
    //             const elem_Add = () => {
    //                 const end_Value = handleNumber === sliderParam.statusValue.length - 1
    //                     ? 1440
    //                     : end
    //                 return {
    //                     start: start,
    //                     end: end_Value,
    //                     status: statusRadio_Pick,
    //                 }
    //             }
    //             const elem_Add_Difference = (sliderParam, handleNumber, handleNumberStep) => {
    //                 const status = sliderParam.statusValue[handleNumberStep].status
    //                 const end_Value = handleNumber !== (sliderParam.statusValue.length - 1) ?
    //                     sliderParam.statusValue[handleNumberStep + 1].start
    //                     : 1440
    //                 return {
    //                     start: end,
    //                     end: end_Value,
    //                     status: status,
    //                 }
    //             }
    //             // if((sliderParam.statusValue.length -1) !== handleNumber  ){

    //             sliderParam.statusValue[handleNumber].end = start
    //             // }


    //             if (handleNumber !== handleNumberStep) {
    //                 sliderParam.statusValue[handleNumberStep].start = end
    //                 sliderParam.statusValue.splice(handleNumber + 1, 0, elem_Add())
    //             }

    //             const add_Difference = checkDiggerence(sliderParam, handleNumberStep, end)
    //             if (add_Difference || sliderParam.statusValue.length === 1) {
    //                 sliderParam.statusValue.splice(handleNumberStep + 1, 0, elem_Add_Difference(sliderParam, handleNumber, handleNumberStep))
    //                 sliderParam.statusValue.splice(handleNumber + 1, 0, elem_Add())
    //             }

    //             if (handleNumberStep === sliderParam.statusValue.length - 1) {
    //                 sliderParam.statusValue.splice(handleNumberStep + 1, 0, elem_Add_Difference(sliderParam, handleNumber, handleNumberStep))
    //                 sliderParam.statusValue.splice(handleNumber + 1, 0, elem_Add())
    //             }

    //             if ((handleNumberStep - handleNumber) >= 1) {
    //                 const value_Difference = getArrayDifference(handleNumber, handleNumberStep)
    //                 value_Difference.forEach(element => {
    //                     sliderParam.statusValue.splice(element + 1, 1)
    //                 });
    //                 console.log(value_Difference)
    //             }
    //             const delete_Value = delete_Not_Work(sliderParam.statusValue, statusRadio_Pick)
    //             const value = sliderParamCreate_New(delete_Value)
    //             console.log(value.connect)
    //             console.log(value)
    //             // sliderParam.statusValue.splice(handleNumber + 1, 0, 'work')
    //             sliderParam.start = value.start
    //             sliderParam.connect = value.connect
    //             sliderParam.tooltip = value.tooltip
    //             sliderParam.statusValue = value.time_List
    //             // arrayResult.schedule = data
    //             // updateShedule(arrayResult)

    //             // noUiSlider.create(element, sliderInit(sliderParam));
    //             // console.log(sliderParam.start)

    //             element.noUiSlider.destroy()
    //             noUiSlider.create(element, sliderInit(sliderParam));
    //             setColor(element, sliderParam.statusValue)
    //             eventListener()
    //         }
    //         if (changeValue === 'delete') {
    //             // sliderParam.statusValue.splice(handleNumber, 1)
    //             sliderParam.statusValue[handleNumber].status = '0'
    //             const delete_Value = delete_Not_Work(sliderParam.statusValue, '0')
    //             // sliderParam.statusValue = delete_Value

    //             const value = sliderParamCreate_New(delete_Value)
    //             console.log(value.connect)
    //             console.log(value)
    //             // sliderParam.statusValue.splice(handleNumber + 1, 0, 'work')
    //             sliderParam.start = value.start
    //             sliderParam.connect = value.connect
    //             sliderParam.tooltip = value.tooltip
    //             sliderParam.statusValue = value.time_List

    //             element.noUiSlider.destroy()
    //             noUiSlider.create(element, sliderInit(sliderParam));
    //             setColor(element, sliderParam.statusValue)
    //             eventListener()

    //             // arrayResult.schedule = data
    //             // updateShedule(arrayResult)
    //         }

    //         if (changeValue === 'change' && sliderParam.statusValue[handleNumber].status !== '0') {
    //             // sliderParam.statusValue.splice(handleNumber, 1)
    //             sliderParam.statusValue[handleNumber].status = statusRadio_Pick
    //             const delete_Value = delete_Not_Work(sliderParam.statusValue, statusRadio_Pick)
    //             sliderParam.statusValue = delete_Value

    //             const value = sliderParamCreate_New(sliderParam.statusValue)
    //             console.log(value.connect)
    //             console.log(value)
    //             // sliderParam.statusValue.splice(handleNumber + 1, 0, 'work')
    //             sliderParam.start = value.start
    //             sliderParam.connect = value.connect
    //             sliderParam.tooltip = value.tooltip
    //             sliderParam.statusValue = value.time_List

    //             element.noUiSlider.destroy()
    //             noUiSlider.create(element, sliderInit(sliderParam));
    //             setColor(element, sliderParam.statusValue)
    //             eventListener()

    //             // arrayResult.schedule = data
    //             // updateShedule(arrayResult)
    //         }
    //         const time = fomrmatTime(start)
    //         // console.log("%cLog Message", "color: orange");
    //         // console.log(`%c${time}`, "color: red")
    //         // console.log(handleNumber)
    //         // console.log(changeValue)
    //         // console.log(proposal)
    //         // console.log(start)
    //         // console.log(handle)
    //         // console.log(`\n\n\n`)
    //     });
    //     canvas.addEventListener('mousemove', (e) => {
    //         // console.log(sliderParam.start)
    //         const statusRadio_Pick = radioStatus().getRadioStatus()
    //         const changeValue = radioTimeChangeValue().getRadioStatus()
    //         const clickElement = canvas
    //         const stepValue = step()
    //         const proposal = calcPointToPercentage(e.clientX, clickElement)
    //         const { handle, handle_Step, start, end } = closest(proposal, stepValue)
    //         // console.log(start)
    //     })
    //     // var connect = element.querySelectorAll('.noUi-connect');
    //     // var classes = ['bg-primary', 'c-2-color', 'c-3-color', 'c-4-color', 'c-5-color'];

    //     // connect.forEach((elem, key) => {
    //     //     elem.classList.add(classes[key]);
    //     // });
    //     // element.noUiSlider.on('update', doSomething);
    //     element.noUiSlider.on('change', doSomething);
    // }