import { useEffect, useRef, useCallback, useMemo } from "react";
import freeice from "freeice";
import useStateWithCallback from "../../hooks/useStateWithCallback";
import socket from "../../socket";
import ACTIONS from "../../socket/actions";
import STUN_TURN from "../../hooks/STUN_TURN";

export const LOCAL_VIDEO = "LOCAL_VIDEO";

export default function useWebRTC(roomID) {
  function logConsole(text) {
    // console.log(text)
  }
  /* Логировать соккеты */
  useEffect(() => {
    socket.on("*", (event, data) => {
      logConsole(event);
      logConsole(data);
      if (data.iceCandidate !== undefined) {
        logConsole(data.iceCandidate.candidate);
      }
      if (data.sessionDescription !== undefined) {
        logConsole(data.sessionDescription.type);
      }
    });
  }, []);
  /* ------------------------------------- */

  /* Константы */
  const [clients, updateClients] = useStateWithCallback([]);
  const scaleScreenClients = useRef([]);
  const stunTurnRef = useRef({});
  const peerConnections = useRef({});
  const localMediaStream = useRef(null);
  const track = useRef(null);
  const peerMediaElements = useRef({
    [LOCAL_VIDEO]: null,
  });

  /* 
    Добавить новое видео-клиента в комнату
  */
  const addNewClient = useCallback((newClient, cb) => {
    updateClients((list) => {
      if (!list.includes(newClient)) {
        return [...list, newClient];
      }

      return list;
    }, cb);
  }, []);

  const startStatsPeerConnection = async (peer) => {
    // console.log(peer)
    let sender = await peer.pc.getSenders();
    let receivers = await peer.pc.getReceivers();
    let stats = await peer.pc.getStats();

    stats.forEach((valueIceStats) => {
      // if (valueIceStats.type === "candidate-pair"  && valueIceStats.currentRoundTripTime) {

      //   console.log("currentRoundTripTime: " + valueIceStats.currentRoundTripTime * 1000)
      // }
      // console.log(valueIceStats)
      // console.log("receivers")
      // console.log(valueIceStats);
      // console.log('\n');
      let bytes;
      let headerBytes;
      let packets;
      if (valueIceStats.type === "inbound-rtp") {
        if (valueIceStats.isRemote) {
          return;
        }
        const now = valueIceStats.timestamp;
        bytes = valueIceStats.bytesSent;
        headerBytes = valueIceStats.headerBytesSent;

        packets = valueIceStats.packetsSent;

        // console.log(valueIceStats)
        console.log("packetsLost: " + valueIceStats.packetsLost)
        // console.log("bytes: " + bytes);
        // console.log("headerBytes: " + headerBytes);
        // console.log("packets: " + packets);
        // console.log("bandwidth:  " + report.qualityLimitationDurations.bandwidth);
        // console.log("CPU:  " + report.qualityLimitationDurations.cpu);
        // console.log("none:  " + report.qualityLimitationDurations.none);
        // console.log("other:  " + report.qualityLimitationDurations.other);
        // console.log("qualityLimitationReason:  " + report.qualityLimitationReason);
        // console.log("\n");
      }
      if (valueIceStats.type === "outbound-rtp") {
        if (valueIceStats.isRemote) {
          return;
        }
        const now = valueIceStats.timestamp;
        bytes = valueIceStats.bytesSent;
        headerBytes = valueIceStats.headerBytesSent;

        packets = valueIceStats.packetsSent;

        // console.log(valueIceStats)
        console.log("packetsLost: " + valueIceStats.packetsLost)
        // console.log("bytes: " + bytes);
        // console.log("headerBytes: " + headerBytes);
        // console.log("packets: " + packets);
        // console.log("bandwidth:  " + report.qualityLimitationDurations.bandwidth);
        // console.log("CPU:  " + report.qualityLimitationDurations.cpu);
        // console.log("none:  " + report.qualityLimitationDurations.none);
        // console.log("other:  " + report.qualityLimitationDurations.other);
        // console.log("qualityLimitationReason:  " + report.qualityLimitationReason);
        // console.log("\n");
      }
    });

    sender.forEach((valueIceStats) => {
      if (valueIceStats.track) {
        valueIceStats.getStats().then((res) => {
          console.log(valueIceStats);
          res.forEach((report) => {
            if (report.type === "candidate-pair"  && report.currentRoundTripTime) {

              console.log("sender currentRoundTripTime: " + report.currentRoundTripTime * 1000)
            }
          });
        });
      }
    });

    receivers.forEach((valueIceStats) => {
      if (valueIceStats.track) {
        valueIceStats.getStats(null).then((res) => {
          res.forEach((report) => {
            if (report.type === "candidate-pair"  && report.currentRoundTripTime) {
              console.log("receivers currentRoundTripTime: " + report.currentRoundTripTime * 1000)
            }
          });
        });
      }
    });
  };
  useEffect(() => {
    
    // setInterval(() => {
    //   const arrayPeerConnection = Object.entries(peerConnections.current);
    
    //   arrayPeerConnection.forEach((peer) => {
    //     // console.log(peer)
    //     startStatsPeerConnection(peer[1]);
    //   });
    //   // console.log(peerConnections)
    // }, 1000);
  }, []);

  const getStunAndJoinRoom = async () => {
    const stunTurn_Result = await STUN_TURN();
    stunTurnRef.current = stunTurn_Result;
    socket.emit(ACTIONS.JOIN, { room: roomID });
  };

  useMemo(() => {
    getStunAndJoinRoom();
  }, []);

  function send(type, to, payload) {
    // console.log("sending " + type + " to " + to);

    socket.emit(ACTIONS.SHARE_MESSAGE, {
      type: type,
      to: to,
      payload: payload,
    });
  }

  useEffect(() => {
    function addPeer(remoteId) {
      // console.log(stunTurnRef.current.peerConnectionConfig);
      // console.log(stunTurnRef.current.peerConnectionConstraints);

      if(roomID === 'f10c14d9-7255-47fa-9503-4a869057118f'){
        peerConnections.current[remoteId] = new Peer(
          {
            iceServers: [
              {
                url: "stun:stun.l.google.com:19302",
                urls: "stun:stun.l.google.com:19302",
                username: null,
                credential: null,
              },
            ],
          },
          stunTurnRef.current.peerConnectionConstraints
        );
      } else {
        peerConnections.current[remoteId] = new Peer(
          stunTurnRef.current.peerConnectionConfig,
          stunTurnRef.current.peerConnectionConstraints
        );
      }


      peerConnections.current[remoteId].pc.onicecandidate = function (event) {
        // console.log(event);
        if (event.candidate) {
          // console.log("Отправлено");
          send("candidate", remoteId, {
            label: event.candidate.sdpMLineIndex,
            id: event.candidate.sdpMid,
            candidate: event.candidate.candidate,
          });
        }
      };

      peerConnections.current[remoteId].pc.ontrack = ({
        streams: [remoteStream],
      }) => {
        addNewClient(remoteId, () => {
          if (peerConnections.current[remoteId].remoteVideoEl) {
            peerMediaElements.current[remoteId].srcObject = remoteStream;
          }
        });
      };
      return peerConnections.current[remoteId];
    }
    function answer(remoteId) {
      const pc = peerConnections.current[remoteId].pc;
      pc.createAnswer(function (sessionDescription) {
        pc.setLocalDescription(sessionDescription);
        send("answer", remoteId, sessionDescription);
      }, error);
    }
    async function offer(remoteId) {
      const pc = peerConnections.current[remoteId].pc;
      const mediaConstraints = {
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      };
      const offer = await pc.createOffer(mediaConstraints);

      await pc.setLocalDescription(offer);
      send("offer", remoteId, offer);
    }
    function handleMessage(message) 
    {
      var type = message.type,
        from = message.from,
        pc = (peerConnections.current[from] || addPeer(from)).pc;
      // console.log(peerConnections.current);
      // console.log("received " + type + " from " + from);

      switch (type) {
        case "init":
          // toggleLocalStream(pc);
          offer(from);
          break;
        case "offer":
          pc.setRemoteDescription(
            new RTCSessionDescription(message.payload),
            function () {},
            error
          );
          answer(from);
          break;
        case "answer":
          pc.setRemoteDescription(
            new RTCSessionDescription(message.payload),
            function () {},
            error
          );
          break;
        case "candidate":
          if (pc.remoteDescription) {
            pc.addIceCandidate(
              new RTCIceCandidate({
                sdpMLineIndex: message.payload.label,
                sdpMid: message.payload.id,
                candidate: message.payload.candidate,
              }),
              function () {},
              error
            );
          }
          break;
        case "image":
          /* Тест ивентов через скриншоты в сокете */
          var arrayBuffer = message.image;
          var bytes = new Uint8Array(arrayBuffer);
          var blob = new Blob([bytes.buffer]);

          var image = document.getElementById("streamImage");

          var reader = new FileReader();
          reader.onload = function (e) {
            image.src = e.target.result;
          };
          reader.readAsDataURL(blob);
          break;
          case "client-screen-size":
            /* Тест ивентов через скриншоты в сокете */
            scaleScreenClients.current.push({
              client: from,
              scale: message.payload
            })
            break;
            /*case "get-client-device-info":
              // Девайс-инфо клиента 
              scaleScreenClients.current.push({
                client: from,
                scale: message.payload
              })
              console.log("client device info!!!! received!!!" + message.payload.DeviceCPU +  message.payload.DeviceRAM +  message.payload.DeviceOS);
              break;*/
        default:
          console.log("Что-то не то пришло:   " + type);
      }
    }
    function toggleLocalStream(pc) {
      console.log(track)
      if (localMediaStream) {
        !!pc.getLocalStreams().length
          ? pc.removeStream(localMediaStream)
          : pc.addTrack(track, localMediaStream);
      }
    }

    function error(err) {
      console.log(err);
    }

    var Peer = function (pcConfig, pcConstraints) {
      this.pc = new RTCPeerConnection(pcConfig);
      this.remoteVideoEl = document.createElement("video");
      this.remoteVideoEl.controls = true;
      this.remoteVideoEl.autoplay = true;
      this.remoteVideoEl.playsInline = true;
      this.remoteVideoEl.muted = "muted";
      this.remoteVideoEl.className = "border border-light";
      this.remoteVideoEl.width = "200";
    };

    socket.on(ACTIONS.SEND_IMAGE, handleMessage);
    socket.on(ACTIONS.ADD_PEER, handleMessage);
    socket.on(ACTIONS.SHARE_MESSAGE, handleMessage);

    return () => {
      socket.off(ACTIONS.ADD_PEER);
      socket.off(ACTIONS.SHARE_MESSAGE);
    };
  }, []);

  const startCapture = async function startCapture() {
    console.log("startCapwadture");
    localMediaStream.current = await navigator.mediaDevices.getDisplayMedia();
    track.current = localMediaStream.current.getTracks()[0];
    socket.emit(ACTIONS.JOIN, { room: roomID });
    // handleNewPeer()
    addNewClient(LOCAL_VIDEO, () => {
      const localVideoElement = peerMediaElements.current[LOCAL_VIDEO];

      if (localVideoElement) {
        localVideoElement.volume = 0;
        localVideoElement.srcObject = localMediaStream.current;
      }
    });
  };

  const startVideoCall = async ()=> {
    localMediaStream.current = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {
        width: 640,
        height: 480
      }
    });
    track.current = localMediaStream.current.getTracks()[0];
    socket.emit(ACTIONS.JOIN, { room: roomID });
    // handleNewPeer()
    addNewClient(LOCAL_VIDEO, () => {
      const localVideoElement = peerMediaElements.current[LOCAL_VIDEO];

      if (localVideoElement) {
        localVideoElement.volume = 0;
        localVideoElement.srcObject = localMediaStream.current;
      }
    });
  }

  useEffect(() => {
    const handleRemovePeer = ({ peerID }) => {
      logConsole("handleRemovePeer");
      if (peerConnections.current[peerID]) {
        peerConnections.current[peerID].pc.close();
      }

      delete peerConnections.current[peerID];
      delete peerMediaElements.current[peerID];

      updateClients((list) => list.filter((c) => c !== peerID));
    };

    socket.on(ACTIONS.REMOVE_PEER, handleRemovePeer);
    return () => {
      socket.off(ACTIONS.REMOVE_PEER);
    };
  }, []);

  useEffect(() => {
    // socket.emit(ACTIONS.JOIN, { room: roomID })
  }, [roomID]);

  const provideMediaRef = useCallback((id, node) => {
    peerMediaElements.current[id] = node;
  }, []);

  return {
    clients,
    startCapture,
    startVideoCall,
    provideMediaRef,
    scaleScreenClients,
    peerConnections
  };
}
