import { useEffect } from 'react';
import { getDateForamteSlot } from '../../../hooks/date-time'
import { createNavDateButton } from '../functions/button'

const BUTTON = createNavDateButton()

function CreateWeek({ weekNameMap, propCallback, datePick:{ DATE_PICK } }) {
    // console.log('CreateWeek')

    return (
        <ul className="nav nav-tabs" id="myTab" role="tablist">
            {
                Object.entries(weekNameMap).map(([date, value]) => {
                    // console.log(dateTest, dateTest1)
                    return (
                        <li key={value.valueTable} className="nav-item" role="presentation">
                        <button id={date}
                            className={DATE_PICK.current === date ? 'nav-link bg-info text-light active' : 'nav-link'}
                            data-bs-toggle="tab" data-bs-target="" type="button" role="tab" aria-controls="" aria-selected="true"
                            onClick={() => {
                                console.log('button')
                                DATE_PICK.current = date
                                // BUTTON.getNavDate(value.valueTable.split('-').reverse().join('-'))
                                propCallback([])
                            }}
                        >{value.valueTable}<br />{getDateForamteSlot(date)}</button>
                    </li>
                    )
                })
            }
        </ul>
    )
}

export { CreateWeek }