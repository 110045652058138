import { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router';
import { api } from '../../service/api-url';
const Navbar = ({ authContext }) => {
    // console.log('Render Navbar')
    const menuRef = useRef([])
    const [MENU, updateMenu] = useState([]);;
    const history = useNavigate();
    const auth = useContext(authContext)

    const geMenu = async () => {
        const url = `${api()}/api/nav-bar/get-menus.php`;
        let getResult = await fetch(url, {
            "body": null,
            "method": "GET",

            "credentials": "include"
        })

        const result = await getResult.json();
        // console.log(result)
        menuRef.current = result
        updateMenu(result);
    }

    const logOut = async (cb) => {
        const url = `${api()}/api/auth/logout-submit.php`;
        let getResult = await fetch(url, {
            "body": null,
            "method": "GET",

            "credentials": "include"
        })
        const result = await getResult.json();
        cb(result)
    }

    useEffect(() => {
        geMenu()
    }, [auth.user]);
    if (!auth.user) {
        document.title = 'Авторизация'
        console.log(auth.user)
    }

    return (
        auth.user === false ? null :
            MENU.length === 0 ? null :
                <nav className=" navbar-expand-lg fixed-top navbar navbar-dark bg-border ">
                    <div className="container-fluid">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                {
                                    menuRef.current.list.map((dateInf, key) => {
                                        const pathname = window.location.pathname.split('/')[1];
                                        if (dateInf.address === pathname) {
                                            // console.log('Смена title')
                                            document.title = dateInf.name
                                        }
                                        return (
                                            <li className="nav-item" key={key} to="#">
                                                <a className={`nav-link cursor-point ${dateInf.address === pathname ? 'active' : ''}`}
                                                    onClick={(event) => {
                                                        history(`/${dateInf.address}`);
                                                        event.preventDefault()
                                                        return false
                                                    }}
                                                    href={`/${dateInf.address}`}
                                                >{dateInf.name}</a>
                                            </li>
                                        )
                                    })
                                }
                                {/* <li className="nav-item">
                                    <div className={`nav-link cursor-point`}
                                        onClick={() => {
                                            history(`/speedtest`);
                                        }}
                                    >SpeedTest</div>
                                </li> */}
                                {/* <li className="nav-item">
                                    <div className={`nav-link cursor-point`}
                                        onClick={() => {
                                            history(`/webrtc-test`);
                                        }}
                                    >WebRTC-test</div>
                                </li> */}
                            </ul>
                            <div className="d-flex" >
                                <span className="navbar-brand">{menuRef.current["user-name"]}</span>
                                <button className="btn btn-danger" type="button  submit"
                                    onClick={() => {

                                        logOut((resp) => {
                                            if (resp["key-status"] === 200) {
                                                auth.signout(() => {
                                                    history(`/auth`);
                                                });
                                            }
                                        });
                                    }}
                                >Выйти</button>
                            </div>
                        </div>
                    </div>
                </nav>
    )
}

export default Navbar;
