import { useState, useEffect, useRef, useMemo } from "react";

import { getRangeTypeSchdedule } from "./functions/get-type-schedule";
import { setRangeTypeDateSlot } from "./functions/set-type-schedule"

import { CreateDatePicker } from "./element/datepicker-block";
import { createMoscowTime, getNewDateWeekForButton } from "../../hooks/date-time";

import DeafaultTimeSlot from "./element/default-time-slor"

import Form from "react-bootstrap/Form";

const Setting = () => {
  const [SCHEDULE_TYPE, setScheduleType] = useState([]);

  const START = useRef();
  const END = useRef();

  const updateTimeSlotDate = async () => {
    const scheduleType = await getRangeTypeSchdedule(
      createMoscowTime(START.current),
      createMoscowTime(END.current)
    );
    setScheduleType(scheduleType);
  };

  useMemo(() => {
    const dateWeek = getNewDateWeekForButton().weekButton();
    // console.log(dateWeek);
    START.current = dateWeek.start;
    END.current = dateWeek.end;
  }, []);

  useEffect(() => {
    updateTimeSlotDate();
  }, []);

  // console.log(SCHEDULE_TYPE)

  return (
    <div className="m-4">
      <div className="progress h-3em mt-4 mb-4 ">
        <div
          className="progress-bar w-100"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <h2>Настройки типов слотов по дням</h2>
        </div>
      </div>
      <DeafaultTimeSlot updateTimeSlotDate={updateTimeSlotDate}/>
      <div className="container  mt-4" id="temporary_schedule">
        <CreateDatePicker
          date={{ START, END }}
          updateTempSchedule={updateTimeSlotDate}
        />
        <table className="container-fluid fs-table-10  table-bordered text-checkbox mt-5 text-center">
          <thead>
            <tr>
              <td className="w-10">Дата</td>
              <td className="w-10">
                <div className="d-flex flex-row justify-content-center">
                  <div className="me-3">20</div>
                  <div>|</div>
                  <div className="ms-3">30</div>
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            {SCHEDULE_TYPE.map((element, key) => {
              return (
                <tr key={key}>
                  <td>{element.date}</td>
                  <td className="d-flex flex-row justify-content-center">
                    <Form>
                      <div key={`inline-radio`} className="mb-3">
                        <Form.Check
                          bsPrefix="me-4 d"
                          inline
                          name="group1"
                          type="radio"
                          checked={element["type-value"] === '20' ? true : false}
                          onChange={()=>{
                            const changedType = SCHEDULE_TYPE.map(valueType => {
                              if(valueType.date === element.date){
                                return {
                                  'date': valueType.date,
                                  'type-value': '20'
                                }
                              }
                              return valueType
                            })

                            setScheduleType(changedType)
                          }}
                          id={`inline-radio-1`}
                        />
                        <Form.Check
                          bsPrefix="d"
                          inline
                          name="group1"
                          type="radio"
                          checked={element["type-value"] === '30' ? true : false}
                          onChange={()=>{
                            const changedType = SCHEDULE_TYPE.map(valueType => {
                              if(valueType.date === element.date){
                                return {
                                  'date': valueType.date,
                                  'type-value': '30'
                                }
                              }
                              return valueType
                            })

                            setScheduleType(changedType)
                          }}
                          id={`inline-radio-2`}
                        />
                      </div>
                    </Form>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex flex-row justify-content-center mt-4">
        <button type="button" className="btn btn-success text-light " id="save_temp_shedule"
            onClick={() => {
              setRangeTypeDateSlot(SCHEDULE_TYPE)
            }}
        ><i className="fa fa-save"></i> Сохранить тип слотов</button>
      </div>
    </div>
  );
};

export default Setting;
