import { getDateForamteSlot, getDateSlot, getBacktDate } from '../../../hooks/date-time'
import { api } from '../../../service/api-url';

// import { setUserPermission } from '../../../hooks/permission'

const getLogs = async ({ ID, TEXT, AUTHOR, START, END }) => {
    try {
        console.log('getLogs')
        START = START.current.split('-').reverse().join('-')
        END = END.current.split('-').reverse().join('-')
        ID = ID.current === undefined ? '' : ID.current
        TEXT = TEXT.current === undefined ? '' : TEXT.current
        AUTHOR = AUTHOR.current === undefined ? '' : AUTHOR.current

        // whatSerach = whatSerach ? whatSerach : ''
        // let array = {}
        let body = {}
        body['start'] = START
        body['end'] = END
        body['eventsId'] = ID
        body['eventText'] = TEXT
        body['eventsAuthor'] = AUTHOR


        // const url = window.location.origin + '/logs/include/getter.php' + whatSerach;
        const url = `${api()}/api/logs/getter.php`;
        let getLogRes = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(body)
        })
        let result = await getLogRes.json();

        return result
    }
    catch (e) {
        console.error(e.message)
    }
}

export { getLogs }