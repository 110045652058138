import { useState, useEffect, useRef, useMemo, useCallback, createContext } from 'react';
import CreateBlock from './element/block'
import { getSchedule } from './function/get-schedule'
import { creatNowDate, setDateTimeSlot, getDateSlot, setDateSlot, setDateForamteSlot } from '../../hooks/date-time'
import GetDatePicker from './element/date-picker';
import { Loader } from '../../components/Loader'
import createDatePicker from '../../hooks/date-picker/datapicker.general.conf'
import eventUpdateInterval from './function/event-update'
import { LoadProvide } from '../../context/LoadProvider'
import useLoad from '../../hooks/useLoad'
import Main from './main'

export default function Room() {



  return (
    // <LoadProvide>
      <Main />
    // </LoadProvide>
  );
}