export default function eventUpdateInterval(callback, time) {
    // console.log('eventUpdateInterval')
    // setInterval(() => {
    //     callback();
    // }, 1000);

    let last_event = new Date();
    function MouseMove(event) {
        // console.log('Move')
        let new_event = new Date(); 
        if ((new_event - last_event) > time) {
            last_event = new Date();
            callback();
        }
    }
    document.onmousemove = MouseMove;
    return MouseMove
}