import { useState, useEffect, useRef, useMemo, useCallback, createContext } from 'react';
import socket from '../../socket';
import ACTIONS from '../../socket/actions';

export default function SpeedTest() {
  const [SPEED_D, updateDownload] = useState([])
  const [SPEED_U, updateUpload] = useState([])
  const SPEED_U_REF = useRef([])

  const [SPEED_VALUE, updateSpeed] = useState([])

  useEffect(() => {
    // console.log('useEffect socket event')
    socket.on(ACTIONS.MESSAGE, ({ type, value }) => {
      if (type === 'speed-test-value') {
        if (value.download) {
          const download = [...SPEED_D, ...[value.download]]
          updateDownload(download)
        } else if (value.upload) {
          const upload = [...SPEED_U, ...[value.upload]]
          updateUpload(upload)
        }
        // SPEED_U_REF.current = [...SPEED_U_REF.current, ...[value.upload]]

      }
    });
    return () => {
      console.log('socket close -----------------')
      socket.off(ACTIONS.MESSAGE);
    }
  }, [SPEED_D, SPEED_U])

  return (
    <div className="">
      <div className="">
        <div className="card text-white bg-secondary mb-3 row row-alt ">
          <div className="card-header">Показатели скорости интернета</div>
        </div>
      </div>
      <div className="row row-alt">
        <div className="col-6">
          <div className="card text-white bg-secondary mb-3">
            <div className="card-body p-0">
              <div id="first_wrapper" className="dataTables_wrapper no-footer">
                <table id="first" className="table table-hover dataTable no-footer text-center">
                  <thead>
                    <tr>
                      <th>Загрузка</th>
                    </tr>
                  </thead>
                  <tbody>
                    {SPEED_D.map((value, key) => {
                      return (
                        <tr className="odd" key={key}>
                          <td>{value}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="card text-white bg-secondary mb-3">
            <div className="card-body p-0">
              <div id="first_wrapper" className="dataTables_wrapper no-footer">
                <table id="first" className="table table-hover dataTable no-footer text-center">
                  <thead>
                    <tr>
                      <th>Пердача</th>
                    </tr>
                  </thead>
                  <tbody>
                    {SPEED_U.map((value, key) => {
                      return (
                        <tr className="odd" key={key}>
                          <td>{value}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}