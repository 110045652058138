import { getDateSlot, getDateForamteSlot } from '../../../hooks/date-time'
import { api, shedulePath } from '../../../service/api-url';
import { parseTypeTime } from '../../../hooks/time-type-parse'

const getScheduleReg = async (userId) => {
    // console.log('getScheduleReg')
    try {
        const arrayResult = {
            schedule: [],
            // reservation: {}
        }
        const url = `${api()}${shedulePath()}/get-shedule/regular.php?userId=${userId}`;
        let setResult = await fetch(url, {
            method: 'GET',
            // headers: {
            //     "content-type": "application/json; charset=UTF-8"
            // },
            credentials: "include"
        })
        const result = await setResult.json();

        if(result["key-status"] === 401){
            arrayResult['eror'] = result
            return arrayResult;
        }else{
            const entryData = Object.entries(result)
            entryData.forEach((slider, key) => {
                const [date, body] = entryData[key]
                const sliderParam = sliderParamCreate(body)
                const value = {}
                value[date] = sliderParam
                arrayResult.schedule.push({slider: sliderParam, tableName: date})
            });
    
            return arrayResult
        }
    }
    catch (e) {
        console.error(e.message)
    }
}

const parseTime = (time, week) => {
    const [hourseSt, minuteSt] = time.split(":");
    const weekCurrent = Math.floor(Number(hourseSt) / 25)
    const timeWeek = 24 * weekCurrent;
    const timeResult = (Number(hourseSt) - timeWeek) * 60 + Number(minuteSt)
    return timeResult
}

const sliderParamCreate = (timeCurrent) => {
    // console.log(time)
    const start_Current = [];
    const connect = [];
    const tooltip = [];
    const statusValue = [];

    timeCurrent.forEach((elem, key) => {
        const start_Status = parseTime(elem.start, elem.week)
        const end_Status = parseTime(elem.end, elem.week)
        if(elem.status !== '0'){
            start_Current.push(start_Status)
            start_Current.push(end_Status)
            // tooltip.push(true)
            connect.push(true)
        }else{
            connect.push(true)
        }
        const elem_ParsTime = elem
        elem_ParsTime.start = start_Status
        elem_ParsTime.end = end_Status
        const new_Elem = {
            start: elem.start,
            end:elem.end,
            status: elem.status
        }
        statusValue.push(new_Elem)
    })

    if(!start_Current.length){
        start_Current.push(1440)
        connect.push(true)
    }

    if (statusValue[statusValue.length - 1].status !== '0' ) {
        connect.push(true)
    }

    const start = start_Current.filter(function(item, pos) {
        if(start_Current.indexOf(item) === pos){
            tooltip.push(true)
            return start_Current.indexOf(item) === pos;
        }
    })
    return { start, tooltip, connect, statusValue }
}

const getScheduleTemp = async (userId, firtsDate, lastDate, dateList) => {
    // console.log('getScheduleTemp')
    const arrayResult = {
        schedule: [],
    }
    const serachBody = {}
    serachBody['firtsDate'] = firtsDate
    serachBody['lastDate'] = lastDate
    serachBody['userId'] = userId

    
    try {
        // console.log('getUserInfo')
        const url = `${api()}${shedulePath()}/get-shedule/temprary-new.php`;
        let setResult = await fetch(url, {
            method: 'POST',
            // headers: {
            //     "content-type": "application/json; charset=UTF-8"
            // },
            body: JSON.stringify(serachBody),
            credentials: "include"
        })
        const result = await setResult.json();

        if(result["key-status"] === 401){
            arrayResult['eror'] = result
            return arrayResult;
        }else{
            const entryData = Object.entries(result)
            entryData.forEach((slider, key) => {
                const [date, body] = entryData[key]
                const sliderParam = sliderParamCreate(body.timeStatus)
                const value = {}
                value[date] = sliderParam
                arrayResult.schedule.push({slider: sliderParam, tableName: date, type: body["type-shedule"]})
            });
    
            return arrayResult
        }

    }
    catch (e) {
        console.error(e.message)
    }
}

export { getScheduleReg, getScheduleTemp }