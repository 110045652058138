import { setUserPermission, getUserPermission } from '../../../hooks/permission'
import { getTimeForButton } from '../../../hooks/date-time'
import { isOperatorForDuty } from '../../../hooks/duty'

function showEventButton(time) {
    return {
        skipEvent: (timeSlot, assignedById, autoAsignId, skipEvent, controlStartLesson, eventId) => {
            const timeAllAssign = getTimeForButton(timeSlot, 10)
            
            const PERMISSON = getUserPermission();
            const PERMISSION_ASIGN = PERMISSON.can_assign_events;
            const resultPermission = PERMISSION_ASIGN === 'true' ? true : false;
            const resultTime = timeAllAssign.backDateTimeSlot <= timeAllAssign.nowDateTime;
            const resultAsign = assignedById === '0';

            const resultAutoAsign = (PERMISSON.userid === autoAsignId)
            const checkSkipEvent = skipEvent === 'false' ? true : false;

            return true === (resultAsign && resultTime && resultPermission && resultAutoAsign && checkSkipEvent && controlStartLesson  === false) ? true : false
        },
        asign: (backTimeSlot, timeSlot, nowTimeSlot, assignedById, autoAsignId, autoAssignList, dutyList, skipEvent, controlStartLessonSlot, isControlStartLesson, isAssignAllControlLesson, assignedUserList, eventId) => {
            const timeAllAssign = getTimeForButton(timeSlot, 5)
            
            const PERMISSON = getUserPermission();
            const PERMISSION_ASIGN = PERMISSON.can_assign_events;
            const resultPermission = PERMISSION_ASIGN === 'true' ? true : false;
            const resultTime = backTimeSlot <= nowTimeSlot;
            const checkAllAssign = timeAllAssign.backDateTimeSlot <= nowTimeSlot;
            const resultAsign = assignedById === '0';
            const notOnListAutoAsign = !autoAssignList.includes(PERMISSON.userid);
            const notUserAutoAsignList = !isOperatorForDuty(dutyList, autoAsignId);
            const checkSkipEvent = skipEvent === 'true' ? true : false;
            const autoAssginUserHasAlreadySlot = assignedUserList.includes(autoAsignId);
            const resultAutoAsign = (PERMISSON.userid === autoAsignId) || notOnListAutoAsign || notUserAutoAsignList || checkAllAssign || checkSkipEvent || controlStartLessonSlot || autoAssginUserHasAlreadySlot
            const controlLessonStartCheck = (isControlStartLesson === controlStartLessonSlot) || isAssignAllControlLesson

            // console.log(timeSlot)
            if(eventId === '96102'){
                console.log(isAssignAllControlLesson)
                console.log(resultAsign)
                console.log(controlLessonStartCheck)
            }

            return true === (resultTime && resultAsign && resultPermission && controlLessonStartCheck && resultAutoAsign) ? true : false
        },
        unAsign: (backTimeSlot, timeSlot, nowTimeSlot, checkAssig) => {
            const UserName = getUserPermission().name
            const PERMISSION_ASIGN = getUserPermission().can_assign_events
            const resultPermission = PERMISSION_ASIGN === 'true' ? true : false
            const resultName = UserName === checkAssig
            const resultTime = (backTimeSlot <= nowTimeSlot) && (timeSlot > nowTimeSlot)
            return true === (resultTime && resultName && resultPermission) ? true : false
        },
        save: (backTimeSlot, nowTimeSlot, autorSlot) => {
            const USER_PERMISSION = getUserPermission()

            const PERMISSION_ALTER = USER_PERMISSION.alter_any_event === 'true' ? true : false
            const USER_ROLE = USER_PERMISSION.role < 3 ? true : false
            const USER_NAME = USER_PERMISSION.name === autorSlot ? true : false
            const resultTime = backTimeSlot > nowTimeSlot
            if (PERMISSION_ALTER && resultTime && autorSlot) {
                return true
            }else if(resultTime && USER_NAME){
                return true
            } else if (USER_ROLE) {
                return true
            } else {
                return false
            }
        },
        deleteSlot: (backTimeSlot, nowTimeSlot, autorSlot) => {
            const USER_PERMISSION = getUserPermission()

            const PERMISSION_ALTER = USER_PERMISSION.alter_any_event === 'true' ? true : false
            const USER_ROLE = USER_PERMISSION.role < 3 ? true : false
            const USER_NAME = USER_PERMISSION.name === autorSlot ? true : false
            const resultTime = backTimeSlot > nowTimeSlot
            if (PERMISSION_ALTER && resultTime && autorSlot) {
                return true
            }else if(resultTime && USER_NAME){
                return true
            } else if (USER_ROLE) {
                return true
            } else {
                return false
            }
        }
    }
}
export { showEventButton }