import { useState, useEffect, useRef, useMemo, useCallback, createContext } from 'react';
import socket from '../../socket';
import ACTIONS from '../../socket/actions';
import { UAParser } from 'ua-parser-js'

export default function SpeedTest() {
    const [UA, updateUA] = useState()
    const getUserAgent = () => {
        setTimeout(() => { 
            socket.emit(ACTIONS.MESSAGE, {
                type: ACTIONS.GET_USER_AGENT
            });
        }, 2000);
    }

    useEffect(() => {
        getUserAgent()
        socket.on(ACTIONS.MESSAGE, ({ type, value }) => {
            if (type === ACTIONS.SEND_USER_AGENT) {
                const uaUser = new UAParser(value).getResult()
                console.log(uaUser)
                updateUA(uaUser)
            }
        });
        return () => {
        }
    }, [])

    return (
        UA !== undefined &&
        <div className="">
            <div className="">
                <div className="card text-white bg-secondary mb-3 row row-alt ">
                    <div className="card-header">User agent</div>
                    <div className="card-body p-0">
                        <div id="five_wrapper" className="dataTables_wrapper no-footer"></div>
                        <div id="passwordHelpBlock" className="form-text text-light fs-4">
                            {`Браузер ${UA.browser.name} ${UA.browser.version}`}
                            <br />
                            {`ОС ${UA.os.name} ${UA.os.version}`}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}