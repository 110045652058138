// import { getDateSlot, getDateForamteSlot } from '../../../hooks/date-time'
import { CONFIG } from '../config';
import { api, shedulePath } from '../../../service/api-url';
import { formatTime } from './slider-lib'
const updateScheduleReg = async ({ SCHEDULE_REG: { schedule }, userId }) => {
    const dateList = schedule.map(({ tableName, slider: { statusValue } }) => {
        const status_Current = statusValue.map((elem) => {
            const start_Value = formatTime(elem.start)
            const end_Value = formatTime(elem.end)
            return {
                start: start_Value,
                end: end_Value,
                status: elem.status,
            }
            // console.log(elem)
        })
        console.log(statusValue)
        return {
            day: tableName,
            status: status_Current
        }
    });

    let arrayList = {}
    arrayList['daywork'] = dateList;
    arrayList['userid'] = userId;
    try {
        const url = `${api()}${shedulePath()}/save-shedule/regular.php`;
        let setResult = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(arrayList),
            credentials: "include"
        })
        const result = await setResult.json();
        return result
    }
    catch (e) {
        console.error(e.message)
    }
}

const updateScheduleTemp = async ({ SCHEDULE_TEMP: { schedule }, userId }) => {
    const dateList = schedule.map(({ tableName, slider: { statusValue } }) => {
        const status_Current = statusValue.map((elem) => {
            const start_Value = formatTime(elem.start)
            const end_Value = formatTime(elem.end)
            return {
                start: start_Value,
                end: end_Value,
                status: elem.status,
            }
            // console.log(elem)
        })
        console.log(statusValue)
        return {
            day: tableName,
            status: status_Current
        }
    });

    const arrayList = {}
    arrayList['daywork'] = dateList
    arrayList['userid'] = userId

    try {
        console.log('getUserInfo')
        const url = `${api()}${shedulePath()}/save-shedule/temprary.php`;
        let setResult = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(arrayList),
            credentials: "include"
        })
        const result = await setResult.json();
        return result
    }
    catch (e) {
        console.error(e.message)
    }
}


const deleteTempSchedule = async ({ START, END, userId }) => {
    const arrayList = {}
    arrayList['start'] = START.current
    arrayList['end'] = END.current
    arrayList['userId'] = userId

    try {
        console.log('getUserInfo')
        const url = `${api()}${shedulePath()}/delete-shedule-temprary/`;
        let setResult = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(arrayList),
            credentials: "include"
        })
        const result = await setResult.json();
        return result
    }
    catch (e) {
        console.error(e.message)
    }

}
export { updateScheduleReg, updateScheduleTemp, deleteTempSchedule }