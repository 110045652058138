
function getPageOffset(doc) {
    var supportPageOffset = window.pageXOffset !== undefined;
    var isCSS1Compat = (doc.compatMode || "") === "CSS1Compat";
    var x = supportPageOffset
        ? window.pageXOffset
        : isCSS1Compat
            ? doc.documentElement.scrollLeft
            : doc.body.scrollLeft;
    var y = supportPageOffset
        ? window.pageYOffset
        : isCSS1Compat
            ? doc.documentElement.scrollTop
            : doc.body.scrollTop;
    return {
        x: x,
        y: y,
    };
}

function baseSize(scope_Base, options) {
    // console.log(scope_Base)
    var rect = scope_Base.getBoundingClientRect();
    var alt = ("offset" + ["Width", "Height"][options]);
    // console.log(options)
    // console.log(scope_Base)
    return options === 0 ? rect.width || scope_Base[alt] : rect.height || scope_Base[alt];
}

function limit(a) {
    return Math.max(Math.min(a, 100), 0);
}

function offset(elem, orientation) {
    var rect = elem.getBoundingClientRect();
    var doc = elem.ownerDocument;
    var docElem = doc.documentElement;
    var pageOffset = getPageOffset(doc);
    // getBoundingClientRect contains left scroll in Chrome on Android.
    // I haven't found a feature detection that proves this. Worst case
    // scenario on mis-match: the 'tap' feature on horizontal sliders breaks.
    if (/webkit.*Chrome.*Mobile/i.test(navigator.userAgent)) {
        pageOffset.x = 0;
    }
    return orientation ? rect.top + pageOffset.y - docElem.clientTop : rect.left + pageOffset.x - docElem.clientLeft;
}

/* Высчитать величину степа */
const closest = (value, to) => {
    const handle = Math.round(value / to) * to;
    const start = isPercentage(handle)

    const end = start + 100
    const handle_Step = Math.round(isPercentageEnd(end));
    return { handle, start, handle_Step, end };
}

const step = () => {
    return (20 * 100) / (0 - 1440);
}

const isPercentage = (value) => {
    return (value * 1440) / 100;
}

const isPercentageEnd = (value) => {
    return (value / 1440) * 100;
}

const parseTime = (time, week) => {
    const [hourseSt, minuteSt] = time.split(":");
    const weekCurrent = Math.floor(Number(hourseSt) / 25)
    const timeWeek = 24 * weekCurrent;
    const timeResult = (Number(hourseSt) - timeWeek) * 60 + Number(minuteSt)
    return timeResult
}

/* ----------------------------------------------------------------------------------------- */
function getClosestHandle(clickedPosition, scope_Locations) {
    const scope_Paprse = [0]
    var smallestDifference = 100;
    var handleNumber = false;
    // const scope_Handles = element.querySelectorAll('noUi-origin')
    // scope_Locations.forEach(function (handle, index) {
    //     scope_Paprse.push(handle / 1440 * 100) // = (handle / 1440 * 100);
    // })

    scope_Locations.forEach(function (handle, index) {
        scope_Paprse[index] = (handle / 1440 * 100) // = (handle / 1440 * 100);
    })
    // scope_Paprse[scope_Paprse.length] = 100
    // console.log(scope_Locations)
    scope_Paprse.forEach(function (handle, index) {
        // Disabled handles are ignored
        // if (isHandleDisabled(index)) {
        //     return;
        // }
        var handlePosition = scope_Locations[index];


        var differenceWithThisHandle = Math.abs(handlePosition - clickedPosition);
        // Initial state
        var clickAtEdge = differenceWithThisHandle === 100 && smallestDifference === 100;
        // Difference with this handle is smaller than the previously checked handle
        var isCloser = differenceWithThisHandle < smallestDifference;
        var isCloserAfter = differenceWithThisHandle <= smallestDifference && clickedPosition > handlePosition;

        const prewValue = index === 0 ? 0 : scope_Paprse[index - 1]
        const nextValue = index === scope_Paprse.length - 1 ? scope_Paprse[index] : scope_Paprse[index + 1]
        const isRange = prewValue < clickedPosition && nextValue > clickedPosition

        const isCloserRange = nextValue < clickedPosition && index === scope_Paprse.length - 1


        if (isRange) {
            handleNumber = index;
        }
        if (isCloserRange) {
            handleNumber = index + 1;
        }

        // if (isCloser || isCloserAfter || clickAtEdge) {
        //     handleNumber = index;
        //     smallestDifference = differenceWithThisHandle;
        // }
    });
    return handleNumber;
}


function calcPointToPercentage(calcPoint, elm) {
    // const stepValue = step()
    var location = calcPoint - offset(elm, 0);
    var proposal = (location * 100) / baseSize(elm, 0);
    // Clamp proposal between 0% and 100%
    // Out-of-bound coordinates may occur when .noUi-base pseudo-elements
    // are used (e.g. contained handles feature)
    proposal = limit(proposal);
    return 0 ? 100 - proposal : proposal;
}

const setHandle = (hundlValue) => {
    const start_Up = []
    const connect_Up = []
    const tooltip_Up = []
    // const statusValue_Up = []

    hundlValue.start.forEach(element => {
        if (!start_Up.length) {
            start_Up.push(element)
        } else if (element > start_Up[start_Up.length - 1]) {
            start_Up.push(element)
        }
    });

    start_Up.forEach(element => {
        tooltip_Up.push(true)
    })

    hundlValue.statusValue.forEach(element => {
        if (element !== '0') {
            connect_Up.push(true)
        } else {
            connect_Up.push(false)
        }
    })
    return { start_Up, connect_Up, tooltip_Up }
}

const checkDiggerence = (sliderParam, handleNumberStep, end) => {
    let check = false
    const step = handleNumberStep === sliderParam.statusValue.length - 1
        ? handleNumberStep
        : handleNumberStep + 1
    const start = sliderParam.statusValue[step].start
    if (start > end) {
        check = true
    }
    return check
}

const sliderParamCreate_New = (timeCurrent) => {
    // console.log(time)
    const start_Current = [];
    const connect = [];
    const tooltip = [];
    const statusValue = [];
    const time_List = []

    timeCurrent.forEach((elem, key) => {
        if (elem.start !== elem.end || timeCurrent.length - 1 === key) {
            // delete timeCurrent[key]
            time_List.push(elem)
        }
        if (timeCurrent.length - 1 === key && elem.status !== '0') {
            time_List.push({
                start: 1440,
                end: 1440,
                status: '0'
            })
        }
    })

    time_List.forEach(elem => {
        const start_Status = elem.start
        const end_Status = elem.end
        if (elem.status !== '0') {
            start_Current.push(start_Status)
            start_Current.push(end_Status)
            // tooltip.push(true)
            connect.push(true)
        } else {
            connect.push(true)
        }
        const elem_ParsTime = elem
        elem_ParsTime.start = start_Status
        elem_ParsTime.end = end_Status
        statusValue.push(elem)

    })

    if(!start_Current.length){
        start_Current.push(1440)
        connect.push(true)
    }

    if (statusValue[statusValue.length - 1].status !== '0' ) {
        connect.push(true)
    }

    const start = start_Current.filter(function (item, pos) {
        // if (start_Current.indexOf(item) === pos && item !== 1440) {
        if (start_Current.indexOf(item) === pos) {
            tooltip.push(true)
            return start_Current.indexOf(item) === pos;
        }
    })
    const value = { start, tooltip, connect, statusValue, time_List }
    return value
}


const getArrayDifference = (start, end) => {
    const value = []
    let count = start + 1
    while (count < end) {
        value.push(count)
        count++
    }
    return value
}

const delete_Not_Work = (timeCurrent, status) => {
    const time_List = []

    timeCurrent.forEach((elem, key) => {
        console.log(timeCurrent)
        if (time_List.length === 0) {
            time_List.push(elem)
        } else {
            const lastElem = time_List[time_List.length - 1]
            if (lastElem.status === elem.status) {
                const end = elem
                time_List[time_List.length - 1].end = end.end
            } else {
                time_List.push(elem)
            }
        }
        // if (timeCurrent.length - 1 === key) {
        //     time_List.push({
        //         start: 1440,
        //         end: 1440,
        //         status: '0',
        //     })
        // }
    })

    if (time_List.length === 1 && time_List[time_List.length - 1].status === '0') {
        time_List[time_List.length - 1] = {
            start: 0,
            end: 1440,
            status: '0',
        }
    }
    return time_List
}
const formatTime = (value) => {
    const valueIntg = Math.round(value)
    const hh = Math.floor(valueIntg / 60);
    const mm = (valueIntg % 60);
    const tooltipsValue = ("0" + (hh % 25)).slice(-2) + ':' + ("0" + mm).slice(-2);
    return (tooltipsValue);
}


export {
    calcPointToPercentage,
    step,
    closest,
    getClosestHandle,
    setHandle,
    checkDiggerence,
    sliderParamCreate_New,
    getArrayDifference,
    delete_Not_Work,
    formatTime
}
// var location = calcPoint - offset(scope_Base, options.ort);
// var proposal = (location * 100) / baseSize();