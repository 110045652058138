import { useEffect, useRef, useCallback, useMemo } from "react";
import freeice from "freeice";
import useStateWithCallback from "../../hooks/useStateWithCallback";
import socket from "../../socket";
import ACTIONS from "../../socket/actions";
import STUN_TURN from "../../hooks/STUN_TURN";

export const LOCAL_VIDEO = "LOCAL_VIDEO";

export default function useWebRTC(roomID) {
  function logConsole(text) {
    // console.log(text)
  }
  /* Логировать соккеты */
  useEffect(() => {
    socket.on("*", (event, data) => {
      logConsole(event);
      logConsole(data);
      if (data.iceCandidate !== undefined) {
        logConsole(data.iceCandidate.candidate);
      }
      if (data.sessionDescription !== undefined) {
        logConsole(data.sessionDescription.type);
      }
    });
  }, []);
  /* ------------------------------------- */

  /* Константы */
  const [clients, updateClients] = useStateWithCallback([]);
  const stunTurnRef = useRef({});
  const peerConnections = useRef({});
  const localMediaStream = useRef(null);
  const track = useRef(null);
  const peerMediaElements = useRef({
    [LOCAL_VIDEO]: null,
  });

  /* 
    Добавить новое видео-клиента в комнату
  */
  const addNewClient = useCallback(
    (newClient, cb) => {
      updateClients((list) => {
        if (!list.includes(newClient)) {
          return [...list, newClient];
        }

        return list;
      }, cb);
    },
    [clients]
  );
  useEffect(() => {
    // setInterval(() => {
      const startStatsPeerConnection = async (peer) => {
        let sender = await peer.pc.getSenders();
        let receivers = await peer.pc.getReceivers();
        let stats = await peer.pc.getStats(null, (value)=>{
          console.log(value)
        });

        

        stats.forEach((valueIceStats) => {
          // if (valueIceStats.type === "candidate-pair"  && valueIceStats.currentRoundTripTime) {

          //   console.log("currentRoundTripTime: " + valueIceStats.currentRoundTripTime * 1000)
          // }
        })

        sender.forEach((valueIceStats) => {
          if (valueIceStats.track) {
            valueIceStats.getStats().then((res) => {
              res.forEach((report) => {
                if (report.type === "candidate-pair"  && report.currentRoundTripTime) {
                  console.log("sender currentRoundTripTime: " + report.currentRoundTripTime * 1000)
                }
              });
            });
          }
        });

        receivers.forEach((valueIceStats) => {
          if (valueIceStats.track) {
            valueIceStats.getStats(null).then((res) => {
              res.forEach((report) => {
                if (report.type === "candidate-pair"  && report.currentRoundTripTime) {
                  console.log("receivers currentRoundTripTime: " + report.currentRoundTripTime * 1000)
                }
              });
            });
          }
        });
      };

      const arrayPeerConnection = Object.entries(peerConnections.current);

      arrayPeerConnection.forEach((peer) => {
        startStatsPeerConnection(peer[1]);
      });
      // console.log(peerConnections)
    // }, 10);
  }, []);

  const getStunAndJoinRoom = async () => {
    const stunTurn_Result = await STUN_TURN();
    stunTurnRef.current = stunTurn_Result;
    socket.emit(ACTIONS.JOIN, { room: roomID });
  };

  useMemo(() => {
    getStunAndJoinRoom();
  }, []);

  function send(type, to, payload) {
    // console.log('sending ' + type + ' to ' + to);

    socket.emit(ACTIONS.SHARE_MESSAGE, {
      type: type,
      to: to,
      payload: payload,
    });
  }

  useEffect(() => {
    function addPeer(remoteId) {
      // console.log(stunTurnRef.current.peerConnectionConfig);
      // console.log(stunTurnRef.current.peerConnectionConstraints);
      peerConnections.current[remoteId] = new Peer(
        stunTurnRef.current.peerConnectionConfig,
        stunTurnRef.current.peerConnectionConstraints
      );
      peerConnections.current[remoteId].pc.onicecandidate = function (event) {
        if (event.candidate) {
          send("candidate", remoteId, {
            label: event.candidate.sdpMLineIndex,
            id: event.candidate.sdpMid,
            candidate: event.candidate.candidate,
          });
        }
      };
      peerConnections.current[remoteId].pc.ontrack = function (event) {
        let element = document.querySelector("#remoteVideosContainer");
        element.appendChild(peerConnections.current[remoteId].remoteVideoEl);
        peerConnections.current[remoteId].remoteVideoEl.srcObject =
          event.streams[0];
      };

      // peerConnections.current[remoteId] = peer;

      return peerConnections.current[remoteId];
    }

    function answer(remoteId) {
      var pc = peerConnections.current[remoteId].pc;
      const answer = (sessionDescription) => {
        pc.setLocalDescription(sessionDescription);
        send("answer", remoteId, sessionDescription);
      };
      pc.createAnswer().then(answer);
    }

    function offer(remoteId) {
      var pc = peerConnections.current[remoteId].pc;
      pc.createOffer(function (sessionDescription) {
        pc.setLocalDescription(sessionDescription);
        send("offer", remoteId, sessionDescription);
      }, error);
    }
    function handleMessage(message) {
      var type = message.type,
        from = message.from,
        pc = (peerConnections.current[from] || addPeer(from)).pc;
      switch (type) {
        // case "init":
        //   toggleLocalStream(pc);
        //   offer(from);
        //   break;
        case "offer":
          toggleLocalStream(pc);
          pc.setRemoteDescription(
            new RTCSessionDescription(message.payload),
            function () {},
            error
          );
          answer(from);
          break;
        case "answer":
          pc.setRemoteDescription(
            new RTCSessionDescription(message.payload),
            function () {},
            error
          );
          break;
        case "candidate":
          if (pc.remoteDescription) {
            pc.addIceCandidate(
              new RTCIceCandidate({
                sdpMLineIndex: message.payload.label,
                sdpMid: message.payload.id,
                candidate: message.payload.candidate,
              }),
              function () {},
              error
            );
          }
          break;
        default:
          // console.log("Что-то не то");
      }
    }
    function toggleLocalStream(pc) {
      if (localMediaStream.current) {
        // console.log(localMediaStream);
        // console.log(pc.getSenders());
        pc.addTrack(track.current, localMediaStream.current);
        // (!!pc.getSenders().length) ? pc.removeStream(localMediaStream.current) : pc.addTrack(track.current, localMediaStream.current);
      }
    }

    function offer(remoteId) {
      var pc = peerConnections.current[remoteId].pc;
      pc.createOffer(function (sessionDescription) {
        pc.setLocalDescription(sessionDescription);
        send("offer", remoteId, sessionDescription);
      }, error);
    }

   

    function error(err) {
      console.log(err);
    }

    var Peer = function (pcConfig, pcConstraints) {
      this.pc = new RTCPeerConnection(pcConfig);
      this.remoteVideoEl = document.createElement("video");
      // this.remoteVideoEl.controls = true;
      this.remoteVideoEl.muted = true;
      this.remoteVideoEl.className = "border border-light";
      this.remoteVideoEl.width = "200";
      this.remoteVideoEl.autoplay = true;
    };

    socket.on(ACTIONS.ADD_PEER, handleMessage);
    socket.on(ACTIONS.SHARE_MESSAGE, handleMessage);
   

    return () => {
      socket.off(ACTIONS.ADD_PEER);
    };
  }, []);

  const startCapture = async function startCapture() {
    // console.log("startCapwadture");
    localMediaStream.current = await navigator.mediaDevices.getDisplayMedia({
      video: true,
    });
    track.current = localMediaStream.current.getTracks()[0];
    // socket.emit(ACTIONS.JOIN, { room: roomID });
    // handleNewPeer()
    addNewClient(LOCAL_VIDEO, () => {
      const localVideoElement = peerMediaElements.current[LOCAL_VIDEO];

      if (localVideoElement) {
        localVideoElement.volume = 0;
        localVideoElement.srcObject = localMediaStream.current;
      }
    });

    socket.on(ACTIONS.ADD_PEER, startInit);

    startInit();
  };

  const startInit = ()=>{
    const arrayPeerConnection = Object.entries(peerConnections.current);
    arrayPeerConnection.forEach((peer) => {
      send('init', peer[0])
    });
  }

  useEffect(() => {
    const handleRemovePeer = ({ peerID }) => {
      console.log("handleRemovePeer");
      if (peerConnections.current[peerID]) {
        peerConnections.current[peerID].pc.close();
      }

      delete peerConnections.current[peerID];
      delete peerMediaElements.current[peerID];

      updateClients((list) => list.filter((c) => c !== peerID));
    };

    socket.on(ACTIONS.REMOVE_PEER, handleRemovePeer);

    return () => {
      socket.off(ACTIONS.REMOVE_PEER);
    };
  }, []);

  const provideMediaRef = useCallback((id, node) => {
    peerMediaElements.current[id] = node;
  }, []);

  return {
    clients,
    startCapture,
    provideMediaRef,
    peerConnections
  };
}
