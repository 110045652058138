import $ from 'jquery'
import {setDateForamteSlot, getDateForamteSlot, getDateSlot, creatNowDate, creatNowDateFormate } from '../../../hooks/date-time'
// import { UPDATE_NAV_DATE } from '../function/date-map'

function createDatepickerButton(callback) {

    return {
        goDate: async (All) => { 
            callback(All)
        },
    }
}

function createNavDateButton() {
    return {
        getNavDate: async (date) => {
            setDateForamteSlot(date)
        }
    }
}
export { createDatepickerButton, createNavDateButton }