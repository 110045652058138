function CreateSelect(selectArray, ref) {
    // console.log('CreateSelect')
    // console.log(current)
    // console.log(selectArray[1].id)
    ref.value = selectArray[1].id
    return (
        <select defaultValue={selectArray[1].id} className="form-control" name="new_role"
        onChange={(e) => {
            console.log('onChange')
            ref.value = e.target.value

            console.log(e.target.value)
            
        }}
        >
            {Object.entries(selectArray).map(body => {
                const name = body[1].name;
                const key = body[1].id;
                return (
                    <option
                        key={key}
                        value={key}
                        
                        // onChange={(e) => {
                        //     console.log('onChange')
                        //     current = e.target.value

                        //     console.log(e.target.value)
                        // }}
                    >{name}</option>
                )
            })}
        </select>
    )
}

export { CreateSelect }