function CreateReservation(props) {
    // console.log("CreateReservation")
    const { reservation } = props.propSchedule
    const callback = props.propCallback

    return (
        (reservation === undefined) ? <></> :
            Object.entries(reservation).map((element, key) => {
                const time = element[0]
                const value = element[1]
                return (
                    time < '08:00' ? null :
                        time > '22:30' ? null :
                            <td key={key}>
                                <input
                                    className={value > 0 ? 'w-50 text-light form-label text-center fw-bolder bg-success' : 'w-50 form-label text-center fw-bolder'}
                                    value={value}
                                    onChange={(event) => {
                                        const scheduleUpdate = {}
                                        scheduleUpdate['schedule'] = props.propSchedule.schedule
                                        scheduleUpdate['reservation'] = props.propSchedule.reservation
                                        scheduleUpdate.reservation[time] = event.target.value
                                        console.log(reservation[time])
                                        callback(scheduleUpdate)
                                    }}
                                // onChange={this.handleInputChange.bind(this, k)}
                                // max="1" type="" id="gridCheck" 
                                />
                            </td>
                );
            })
    )
}

export { CreateReservation }