import { ping, sendFile } from './function/download'
import { useState, useEffect, useRef, useMemo, useCallback, createContext } from 'react';
import { Button, Form, FormCheck, Row, Col, InputGroup, Jumbotron, FormGroup, FormControl } from 'react-bootstrap';
import { calculate } from './function/speed-calculate'
import socket from '../../socket';
import ACTIONS from '../../socket/actions';

export default function SpeedTest() {
  const [SPEED_D, updateDownload] = useState(0)
  const [SPEED_U, updateUpload] = useState(0)
  const intervalSpeedRef = useRef()

  const SpeedValueRef = useRef({
    download: 0,
    upload : 0
  })

  const intervaleCallback = useCallback((nameSpeed) => {
    const speed_Current = {};
    speed_Current[nameSpeed] = SpeedValueRef.current[nameSpeed]
    
    socket.emit(ACTIONS.MESSAGE, {
      type: ACTIONS.SPEED_TEST_VALUE,
      value: speed_Current
    });

  }, [])

  useEffect(() => {
    // console.log('useEffect socket event')
    socket.on(ACTIONS.MESSAGE, ({ type }) => {
      if (type === 'start-speed') {
        speedTestStart()
      }
    });
  }, [])



  const speedTestStart = async () => {

    const xhr = new XMLHttpRequest();
    const started = new Date().getTime();
    let textResp;
    xhr.timeout = 10000;

    xhr.ontimeout = function (e) {
      console.log('ontimeout')
      const buffer = createBufferUpload()
      const blob = new Blob([buffer], { type: "image/png" });
      const file = new File([blob], 'image.png')
      clearInterval(intervalSpeedRef.current)
      startSend(file)
      console.log(file.size)
    };

    xhr.onload = function (event) {
      console.log('onload ')
      let responseObj = xhr.response;
      const file = new File([responseObj], 'image.png')
      console.log(file)
      clearInterval(intervalSpeedRef.current)
    }

    xhr.onprogress = function (event) {
      console.log('onprogress')
      if (event.lengthComputable) {

        textResp = event
        const { speedVale, milliseconds } = calculate(event, started)
        // console.log(`Сколько реально принято: ${event.loaded}`)
        // console.log(`До сколько сжато: ${textResp.size} \n\n`)
        SpeedValueRef.current.download = speedVale
        updateDownload(speedVale);
      }
    };
    await ping(xhr)
    intervalSpeedRef.current = setInterval(intervaleCallback, 1000, 'download');
  }

  const startSend = async (fileTest) => {
    const xhr = new XMLHttpRequest();
    const started = new Date().getTime();
    xhr.timeout = 10000;
    xhr.upload.onprogress = function (event) {
      if (event.lengthComputable) {
        const { speedVale, milliseconds } = calculate(event, started)
        SpeedValueRef.current.upload = speedVale
        updateUpload(speedVale)
      } else {
        console.log(`Отправлено ${event.loaded} байт`); // если в ответе нет заголовка Content-Length
      }
    }

    xhr.upload.onload = function () {
      clearInterval(intervalSpeedRef.current)
      console.log(`Данные успешно отправлены.`);
    };

    xhr.ontimeout = function (e) {
      clearInterval(intervalSpeedRef.current)
      console.log(`отправка данных остановлена.`);
    };
    await sendFile(xhr, fileTest)
    intervalSpeedRef.current = setInterval(intervaleCallback, 1000, 'upload');
  }


  function createBufferUpload() {
    const valueBite = 209715200
    var buf = new ArrayBuffer(valueBite);
    return buf;

  }

  return (
    <div className="col-lg-3">
      <div className="card text-white bg-secondary mb-3">
        <div className="card-header">Показатели скорости интернета</div>
        <div className="card-body p-0">
          <div id="first_wrapper" className="dataTables_wrapper no-footer">
            <table id="first" className="table table-hover dataTable no-footer text-center">
              <thead>
              </thead>
              <tbody>
                <tr className="odd">
                  <td>Загрузка</td>
                  <td>{`${SPEED_D} Mbs\\s`}</td>
                </tr>
                <tr className="even">
                  <td>Передача</td>
                  <td>{`${SPEED_U} Mbs\\s`}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}