import { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from 'react-router';

const Main = ()=>{
    const history = useNavigate();

    return (
        <div className="d-grid container">
            <button type="button" className="btn btn-success mt-2 mb-2"
                onClick={()=>{
                    history(`timetable-logs`);
                }}
            >Логи расписания</button>
            <button type="button" className="btn btn-success mb-2"
                onClick={()=>{
                    history(`web-and-mobile-logs`);
                }}
            >Действия пользователя на платформе (Web\приложение)
            </button>
            <button type="button" className="btn btn-success mb-2"
                onClick={()=>{
                    history(`tech-summary`);
                }}
            >Старые техссамори</button>
            <button type="button" className="btn btn-success mb-2"
                onClick={()=>{
                    history(`who-create-lesson`);
                }}
            >Для кого создан урок</button>
        </div>
    )
}

export default Main