const calculate = (event, started) => {
    const loaded = Math.floor(event.loaded / 1048576)
    const total = Math.floor(event.total / 1048576)
    
    // console.log(`Получено ${event.loaded} из ${event.total} Мбайт`);
    console.log(`Получено ${loaded} из ${total} Мбайт`);
    const ended = new Date().getTime();
    const milliseconds = ended - started;
    const secondValue = milliseconds / 1000
    const mbsValue = event.loaded * 0.0000076
    const speedVale = Math.floor(mbsValue / secondValue)

    return {speedVale, milliseconds}
}

export { calculate }