import { useState, useEffect, useRef } from 'react';
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import 'datatables.net'

import { createTable } from '../functions/create-table'

function CreateLogs(props) {
    console.log(props)
    const { LOGS } = props

    const dataTableCreate = () => {
        createTable(LOGS)
        const test = $('[aria-controls="first"][type="search"]')
        // console.log(test)
        test.addClass('form-control form-control-sm');
    }

    useEffect(() => {
        dataTableCreate()
    }, [])
    return (
        <>
            <div className="card text-white bg-secondary mb-3 table-hover">

                <table key={''} id='first'
                    className="table table-hover dataTable no-footer text-center" role="grid" aria-describedby="third_info">

                    <thead>

                        <tr>

                            <th>Категория</th>

                            <th>Подкатегория</th>

                            <th>Автор</th>

                            <th>Запись</th>

                            <th>ID ивента</th>

                            <th>Дата и время</th>

                        </tr>

                    </thead>

                    {/* <tbody>
                        {
                            LOGS.map((body, count) => {
                                let subcategory = body['subcategory']
                                return (
                                    <tr key={count}>
                                        <td>
                                            {body['category']}
                                        </td>
                                        <td>
                                            {body['subcategory']}
                                        </td>
                                        <td>
                                            {body['author']}
                                        </td>
                                        <td className={subcategory === "deleted" ? "text-danger" : ""}>
                                            {body['text']}
                                        </td>
                                        <td >
                                            <div >
                                                <p>{body['event_id']}</p>
                                                {body['event_id'] > 0 ? <button type="submit" className="btn btn-primary nav-link d-inline" form="direct_event_search" name="directid" tooltip="Все логи по ивенту" value={body['event_id']}>Найти</button> : null}
                                            </div>
                                        </td>
                                        <td>
                                            {body['date']}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody> */}

                </table>
            </div>
        </>
    )
}

export { CreateLogs }