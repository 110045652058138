import { api } from '../../../service/api-url';
const createNewUser = async (data) => {
    try {
        console.log('getUserInfo')
        const url = `${api()}/api/users/add-user/create-user.php`;
        const test = JSON.stringify(data)
        let setResult = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            credentials: "include"
        })
        const result = await setResult.json();
        return result
    }
    catch (e) {
        console.error(e.message)
    }

}

export { createNewUser }